

export default {
  props: ['show', 'onClose', 'equipId', 'myWidth'],
  mounted() {
    this.resetProperty()
  },
  data() {
    return {
      properties: {
      },
      deletePropDialogShow: false,
      search: '',
      addPropDialogActive: false
    }
  },
  watch: {
    equipId() {
      this.resetProperty()
    },
    cInformations(newVal, oldVal) {
      if (newVal && oldVal) {
        if (Object.keys(newVal).length !== Object.keys(oldVal).length) {
          this.resetProperty()
        }
      }
    }
  },
  computed: {
    cName() {
      return this.cInformations.name;
    },
    cInformations() {
      if (!this.equipId) {
        return {}
      }
      let informations = {}
      if (this.cDevice) {
        for (const prop of this.cDevice.Properties) {
          informations[prop.name] = prop.value;
        }
        informations['name'] = this.cDevice.name
      }
      return informations
    },
    cPropertyChange() {
      let res = {}
      Object.keys(this.properties).forEach(key => {
        if (this.cInformations[key] != this.properties[key]) {
          res[key] = this.properties[key]
        }
      })
      return res;
    },
    cSortKeys() {
      return Object.keys(this.properties)
        .filter(prop => prop.toString().toLowerCase().includes(this.search.toLowerCase()))
        .sort((a) => {
          if (a === 'name' || a === 'manufacture') {
            return -1;
          }
        })
    },
  },
  methods: {
    resetProperty() {
      let res = {}
      Object.keys(this.cInformations).forEach(key => {
        res[key] = this.cInformations[key]
      })
      this.properties = res
    },
    save() {
      console.log(this.cPropertyChange);
      let propertyChange = { ...this.cPropertyChange }
      let _properties = this.cDevice.Properties;
      let obj = {}
      if (propertyChange.name) {
        obj['name'] = propertyChange.name
      }
      if (Object.keys(propertyChange).indexOf('id_parent') >= 0) {
        obj['id_parent'] = propertyChange['id_parent']
      }
      if (Object.keys(obj).length) {
        this.$store.dispatch('editEquip', {
          app: this.cDevice.id_equipment_type === 3 ? "BHA" : "WBS",
          equipId: this.equipId,
          ...obj
        })
      }
      delete propertyChange.name
      delete propertyChange['id_parent']
      let res = []
      Object.keys(propertyChange).forEach(key => {
        let id = _properties.filter(prop => prop.name === key)[0].id
        res.push({
          id,
          value: propertyChange[key],
          name: key
        })
      })
      res.length > 0 && this.$store.dispatch('editProperties', {
        equipId: this.equipId,
        properties: res,
        app: this.cDevice.id_equipment_type === 3 ? "BHA" : "WBS",
      });
      if (res.length > 0 && this.cDevice.id_equipment_type === 3) {
        if (Object.keys((this.cPropertyChange)).indexOf("length") >= 0) {
          let oldLength = this.cDevice.Properties.find(prop => prop.name === 'length')
          if (oldLength) {
            this.onBhaLengthChange && this.onBhaLengthChange({
              offsetLength: Number(this.cPropertyChange['length']) - Number(oldLength.value)
            })
          }
        }
      }
      // setTimeout(() => {
      //   this.resetProperty()
      // }, 500)
    },
    getPropertyUnit(key) {
      let item = this.cDevice.Properties.filter(item => item.name === key)[0];
      if (item && item.unit) {
        return `(${item.unit})`;
      }
      return '';
    },
    closeDeletePropDialog() {
      this.deletePropDialogShow = false
    },
    closeAddPropDialog() {
      this.addPropDialogActive = false;
    },
  }
}
