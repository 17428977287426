var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
  position: 'absolute',
  top: _vm.cOffsetTop + 'px',
  left: _vm.cOffsetLeft + 'px',
  height: _vm.cHeight + 'px',
  width: 'calc(100% - ' + 2*_vm.cOffsetLeft+ 'px)',
  display: 'flex',
  justifyContent: 'space-between'
}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id})}}},[_c('svg',{class:{Nipple: true},style:({
      stroke: _vm.cColor,
    }),attrs:{"height":_vm.cHeight,"width":_vm.cWidth,"version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":_vm.cColor}})]),_c('svg',{class:{Nipple: true},style:({
      stroke: _vm.cColor,
    }),attrs:{"height":_vm.cHeight,"width":_vm.cWidth,"version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":_vm.cColor}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }