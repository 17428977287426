<template>
  <div class="bha-device-page">
    <div class="bha-device">
      <md-table
        v-model="searched"
        md-sort="id"
        md-sort-order="asc"
        md-height="100%"
        @md-selected="selectDevice"
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">BHA Device</h1>
          </div>
          <md-field md-clearable class="md-toolbar-section-end">
            <md-input
              placeholder="Search by name..."
              v-model="search"
              @input="searchOnTable"
            />
          </md-field>
        </md-table-toolbar>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
          :class="
            deviceActive && deviceActive.id === item.id ? 'row-active' : ''
          "
          style="cursor: pointer"
        >
          <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{
            item.id
          }}</md-table-cell>
          <md-table-cell md-label="Name" md-sort-by="name">{{
            item.device_name
          }}</md-table-cell>
          <md-table-cell md-label="Group" md-sort-by="group">{{
            item.group
          }}</md-table-cell>
          <md-table-cell>
            <md-menu
              md-direction="bottom-start"
              md-size="small"
              md-align-trigger
            >
              <md-button class="md-icon-button" md-menu-trigger>
                <md-icon
                  :style="{
                    color:
                      deviceActive && deviceActive.id === item.id ? '#fff' : '',
                  }"
                >
                  more_horiz
                </md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item @click="editDevice(item)">
                  <span>Edit</span>
                  <md-icon>edit</md-icon>
                </md-menu-item>
                <md-menu-item @click="deleteDevie(item.id)">
                  <span style="color: red">Delete</span>
                  <md-icon style="color: red">delete</md-icon>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="device-active" v-if="deviceActive">
        <img
          width="120"
          height="120"
          :src="getImageSrc(deviceActive.image_path)"
        />
      </div>
    </div>
    <md-button style="position:absolute;top: 48px;right: 0;" class="md-primary" @click="createDevice">
      <md-icon>add</md-icon>
      New device
    </md-button>
    <md-dialog-confirm
      :md-active.sync="deviceDelete.isDelete"
      md-title="Do you want to delete?"
      md-content="This action will permanently delete this device in database and cannot be restored. 
      Do you want to continue?"
      style="max-width: 720px; margin: auto"
      md-confirm-text="Delete"
      md-cancel-text="Cancel"
      @md-confirm="confirmDelete"
    />
    <md-dialog :md-active.sync="deviceEdit.isEdit">
      <md-dialog-title>Edit Device</md-dialog-title>
      <md-dialog-content>
        <div class="dialog-content">
          <div>
            <md-field>
              <label>Name</label>
              <md-input v-model="deviceEdit.name"></md-input>
            </md-field>
            <md-field>
              <label>Group</label>
              <md-input v-model="deviceEdit.group"></md-input>
            </md-field>
            <md-field>
              <label>Icon</label>
              <md-file
                v-model="deviceEdit.iconName"
                @change="onIconEdit"
                accept="image/*"
              />
            </md-field>
          </div>
          <div>
            <md-field>
              <label>Image</label>
              <md-file
                v-model="deviceEdit.imageName"
                @change="onImageEdit"
                accept="image/*"
              />
            </md-field>
            <md-field>
              <label>Scale</label>
              <md-input type="number" v-model="deviceEdit.scale"></md-input>
            </md-field>
            <div>
              <img
                width="80"
                height="80"
                v-if="deviceEdit.iconUrl"
                :src="deviceEdit.iconUrl"
              />
            </div>
          </div>
        </div>
        <div class="preview-image">
          <img
            width="140"
            height="140"
            v-if="deviceEdit.imageUrl"
            :src="deviceEdit.imageUrl"
          />
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="deviceEdit.isEdit = false"
          >Close</md-button
        >
        <md-button
          class="md-primary"
          @click="confirmEdit"
          :disabled="!this.deviceEdit.name"
        >
          Edit
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="newDevice.isCreate"
      :md-click-outside-to-close="false"
    >
      <md-dialog-title>Create New Device</md-dialog-title>
      <md-dialog-content>
        <div class="dialog-content">
          <div>
            <md-field>
              <label>Name <span style="color: red">*</span></label>
              <md-input v-model="newDevice.name"></md-input>
            </md-field>
            <md-field>
              <label>Group</label>
              <md-input v-model="newDevice.group"></md-input>
            </md-field>
            <md-field>
              <label>Icon <span style="color: red">*</span></label>
              <md-file
                v-model="newDevice.iconName"
                @change="onIconCreate"
                accept="image/*"
              />
            </md-field>
          </div>
          <div>
            <md-field>
              <label>Image <span style="color: red">*</span></label>
              <md-file
                v-model="newDevice.imageName"
                @change="onImageCreate"
                accept="image/*"
              />
            </md-field>
            <md-field>
              <label>Scale</label>
              <md-input type="number" v-model="newDevice.scale"></md-input>
            </md-field>
            <div>
              <img
                width="80"
                height="80"
                v-if="newDevice.iconUrl"
                :src="newDevice.iconUrl"
              />
            </div>
          </div>
        </div>
        <div class="preview-image">
          <img
            width="140"
            height="140"
            v-if="newDevice.imageUrl"
            :src="newDevice.imageUrl"
          />
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="newDevice.isCreate = false"
          >Close</md-button
        >
        <md-button
          class="md-primary"
          :disabled="!(this.newDevice.name && this.newDevice.image)"
          @click="confirmCreate"
        >
          Create
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "../utils/config";
export default {
  name: "BhaDevicePage",
  data() {
    return {
      search: null,
      searched: [],
      deviceActive: null,
      deviceDelete: {
        id: null,
        isDelete: false,
      },
      deviceEdit: {
        id: null,
        name: null,
        group: null,
        scale: "1.00",
        image: null,
        imageUrl: null,
        icon: null,
        iconUrl: null,
        imageName: null,
        iconName: null,
        isEdit: false,
      },
      newDevice: {
        name: null,
        group: null,
        image: null,
        imageUrl: null,
        icon: null,
        iconUrl: null,
        scale: "1.00",
        imageName: null,
        iconName: null,
        isCreate: false,
      },
    };
  },
  created() {
    this.$store.dispatch("getBhaDevices");
  },
  computed: {
    ...mapState({
      bhaDevices: (state) => state.bha.bhaDevices,
    }),
  },
  watch: {
    bhaDevices(newBhaDevices) {
      this.searched = newBhaDevices;
    },
  },
  methods: {
    searchOnTable() {
      this.searched = this.bhaDevices.filter((device) => {
        return device.device_name
          .toString()
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    selectDevice(item) {
      this.deviceActive = item;
    },
    editDevice(item) {
      console.log(item);
      this.deviceEdit.isEdit = true;
      this.deviceEdit.id = item.id;
      this.deviceEdit.name = item.device_name;
      this.deviceEdit.group = item.group;
      this.deviceEdit.scale = item.scale_factor;
      this.deviceEdit.imageName = "Change Image";
      this.deviceEdit.iconName = "Change Icon";
      this.deviceEdit.imageUrl = this.getImageSrc(item.image_path);
      this.deviceEdit.iconUrl = item.icon_path ? this.getImageSrc(item.icon_path) : 'https://icons.veryicon.com/png/o/miscellaneous/8atour/extra-bed-16x16.png';
    },
    confirmEdit() {
      this.$store.dispatch("editBhaDevice", {
        id: this.deviceEdit.id,
        name: this.deviceEdit.name,
        group: this.deviceEdit.group,
        scale: this.deviceEdit.scale,
        image: this.deviceEdit.image,
        icon: this.deviceEdit.icon,
      });

      for (let k in this.newDevice) {
        this.deviceEdit[k] = null;
      }

      this.deviceEdit.isEdit = false;
    },
    deleteDevie(deviceId) {
      this.deviceDelete.isDelete = true;
      this.deviceDelete.id = deviceId;
    },
    confirmDelete() {
      this.$store.dispatch("deleteBhaDevice", this.deviceDelete.id);
      this.deviceActive = null;
    },
    createDevice() {
      this.newDevice.isCreate = true;
    },
    confirmCreate() {
      this.$store.dispatch("addBhaDevice", {
        name: this.newDevice.name,
        group: this.newDevice.group,
        scale: this.newDevice.scale,
        image: this.newDevice.image,
        icon: this.newDevice.icon,
      });

      for (let k in this.newDevice) {
        this.newDevice[k] = null;
      }
      this.newDevice.scale = "1.00";
      this.newDevice.isCreate = false;
    },
    onImageCreate(e) {
      const file = e.target.files[0];
      this.newDevice.image = file;
      this.newDevice.imageUrl = URL.createObjectURL(file);
    },
    onImageEdit(e) {
      const file = e.target.files[0];
      this.deviceEdit.image = file;
      this.deviceEdit.imageUrl = URL.createObjectURL(file);
    },
    getImageSrc(imgPath) {
      return config.serviceUrl + "/bhaDevice" + imgPath;
    },
    onIconCreate(e) {
      const file = e.target.files[0];
      this.newDevice.icon = file;
      this.newDevice.iconUrl = URL.createObjectURL(file);
    },
    onIconEdit(e) {
      const file = e.target.files[0];
      this.deviceEdit.icon = file;
      this.deviceEdit.iconUrl = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.bha-device-page {
  .bha-device {
    height: 100%;
    display: flex;

    .md-table {
      width: 40%;
      .row-active {
        background: rgba(86, 139, 115, 0.829);
        color: #fff;
      }
    }

    .device-active {
      margin: auto;
    }
  }
  height: calc(100vh - 48px);
}

.md-dialog {
  .md-dialog-content {
    width: 620px;
    height: 400px;

    .dialog-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: 46%;
      }
    }
  }

  .preview-image {
    margin-top: 30px;
    > img {
      margin: auto;
      display: block;
    }
  } 

  .md-dialog-actions {
    padding-right: 24px;
  }
}


</style>
