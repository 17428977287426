<template>
  <div :class="{'outlined': options.id === selectedDeviceId || isSelected}" :style="{
    width: 'calc(100% - ' + 2*_offsetLeft+ 'px)',
    height: cHeight + 'px',
    position: 'absolute',
    left: _offsetLeft + 'px',
    top: offsetTop + 'px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: cNumOfParent,
  }" @click="$emit('select', {id: options.id})">
      <svg :height="cGapWidth/1.5" :width="_gapWidth"
        :style="{
          stroke: cColor
        }" :class="{Hanger: true}">
        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
        <line x1="0" y1="0%" x2="100%" y2="100%" />
        <line x1="100%" y1="0%" x2="0%" y2="100%" />
      </svg>
      <svg :height="cGapWidth/1.5" :width="_gapWidth"
        :style="{
          stroke: cColor
        }" :class="{Hanger: true}">
        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
        <line x1="0" y1="0%" x2="100%" y2="100%" />
        <line x1="100%" y1="0%" x2="0%" y2="100%" />
      </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import top from '../mixins/top';
import {mapGetters} from 'vuex';

export default {
  name: 'Hanger',
  props: ['options', 'color', 'selectedDeviceId',
    'handleClick', 'isSelected', 'offsetLeft', 'gapWidth'],
  computed: {
    ...mapGetters({
      getDeviceById: 'getDeviceById',
      getHangerTop: 'getHangerTop',
      convertLength: 'convertLength',
      getNumOfParent: 'getNumOfParent',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return this.cGapWidth/1.5;
    },
    cColor() {
      // return this.color || (this.options || {}).color || 'black';
      return '#000';
    },
    offsetTop() {
      let top = this.options.Properties.find(prop => prop.name === 'top')
      if (!top) {
        return this.getHangerTop(this.options.id)      
      }
      return this.cOffsetTop;
    },
    _gapWidth() {
      let myGapWidth = this.cGapWidth
      let parent = this.getDeviceById("WBS", this.options.id_parent)
      if (!parent) {
          return 0;
      }
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd')
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top')
      }
      if (top) {
        top = Number(top.value)
        let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
        let crossOverPos = parent.Properties.find(prop => prop.name === 'crossOverPos')

        if (hasCrossOver && Number(hasCrossOver.value)) {
          let parentTop, parentBottom
          if (this.mdMode) {
            parentTop = Number(parent.Properties.find(prop => prop.name === 'topMd').value)
            parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottomMd').value)
          } else {
            parentTop = Number(parent.Properties.find(prop => prop.name === 'top').value)
            parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottom').value)
          }
          crossOverPos = Math.round((Number(crossOverPos.value)/ 100 * (parentBottom - parentTop)) + parentTop)
          if (top > crossOverPos) {
            myGapWidth += this.cGapWidth
          }
        }

        let grandParent = this.getDeviceById('WBS', parent.id_parent)
        if (grandParent) {
          hasCrossOver = grandParent.Properties.find(prop => prop.name === 'hasCrossOver')
          crossOverPos = grandParent.Properties.find(prop => prop.name === 'crossOverPos')
          if (hasCrossOver && Number(hasCrossOver.value)) {
             let grandParentTop, grandParentBottom
            if (this.mdMode) {
              grandParentTop = Number(grandParent.Properties.find(prop => prop.name === 'topMd').value)
              grandParentBottom = Number(grandParent.Properties.find(prop => prop.name === 'bottomMd').value)
            } else {
              grandParentTop = Number(grandParent.Properties.find(prop => prop.name === 'top').value)
              grandParentBottom = Number(grandParent.Properties.find(prop => prop.name === 'bottom').value)
            }
            crossOverPos = Math.round((Number(crossOverPos.value)/ 100 * (grandParentBottom - grandParentTop)) + grandParentTop)
            if (top < crossOverPos) {
              myGapWidth += this.cGapWidth
            }
          }
        }
      }
      return myGapWidth
    },
    cNumOfParent() {
      return this.getNumOfParent(this.options.id)
    },
    _offsetLeft() {
      let offsetLeft = this.offsetLeft
      let parent = this.getDeviceById("WBS", this.options.id_parent)
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      if (parent) {
        let grandParent = this.getDeviceById("WBS", parent.id_parent)
        if (grandParent) {
          let hasCrossOver = grandParent.Properties.find(prop => prop.name === 'hasCrossOver')
          let crossOverPos = grandParent.Properties.find(prop => prop.name === 'crossOverPos')
          if (hasCrossOver && Number(hasCrossOver.value)) {
            let grandParentTop, grandParentBottom
            if (this.mdMode) {
              grandParentTop = Number(grandParent.Properties.find(prop => prop.name === 'topMd').value)
              grandParentBottom = Number(grandParent.Properties.find(prop => prop.name === 'bottomMd').value)
            } else {
              grandParentTop = Number(grandParent.Properties.find(prop => prop.name === 'top').value)
              grandParentBottom = Number(grandParent.Properties.find(prop => prop.name === 'bottom').value)
            }
            crossOverPos = Math.round((Number(crossOverPos.value)/ 100 * (grandParentBottom - grandParentTop)) + grandParentTop)
            if (top < crossOverPos) {
              return offsetLeft - this.cGapWidth
            }
          }
        }
      }
      return offsetLeft
    }
  },
  mixins: [gapWidth, top]
}
</script>

<style scoped lang='scss'>
  .Hanger {
    z-index: 10;
  }
</style>
