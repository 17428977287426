import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getDeviceOffsetLeft: 'getDeviceOffsetLeft'
    }),
    cOffsetLeft() {
      return this.getDeviceOffsetLeft(this.options.id);
    },
  }
}