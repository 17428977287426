import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      GAP_WIDTH: state => state.well.GAP_WIDTH
    }),
    cGapWidth() {
      return this.GAP_WIDTH;
    }
  }
}