<template>
  <div :style="{
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 0.5 + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*(cOffsetLeft + 0.5) + 'px)',
  }" @click="$emit('select', {id: options.id});" :class="{Plug: true, 'outlined': selectedDeviceId === options.id || isSelected}" >
			<svg height="100%" width="100%"
				:style="{
						stroke: '#000'
				}">
				<rect x="0" y="0" width="100%" height="100%" fill="#fff"/>
				<line x1="0" y1="0%" x2="100%" y2="100%" />
				<line x1="100%" y1="0%" x2="0%" y2="100%" />
			</svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "Plug",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
      let top, bottom
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
        bottom = this.options.Properties.find(prop => prop.name === 'bottomMd')
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
        bottom = this.options.Properties.find(prop => prop.name === 'bottom')        
      }
      if (!bottom) {
        bottom = +top + 10
      } else {
        bottom = bottom.value
      }
      return this.convertLength(Math.abs(bottom - top));
    },
    cColor() {
      return '#4058b8';
    },
  }
}
</script>

<style scoped lang='scss'>
  .Plug {
    position: absolute;
    z-index: 10;
		display: flex;
    cursor: pointer;
  }
</style>
