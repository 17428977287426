<template>
  <div :class="{'outlined': selectedDeviceId === options.id || isSelected}" :style="{
    position: 'absolute',
    top: cOffsetTop + 'px',
    left: _offsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*_offsetLeft+ 'px)',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: cNumOfParent + 10,
  }" @click="$emit('select', {id: options.id});">
    <svg :height="cGapWidth/1.5" :width="iconWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
      :style="{
        stroke: cColor,
      }" :class="{Parker: true}">
      <rect x="0" y="0" width="100%" height="100%" fill="red"/>
      <line x1="0" y1="0%" x2="100%" y2="100%" />
      <line x1="100%" y1="0%" x2="0%" y2="100%" />
    </svg>
    <svg :height="cGapWidth/1.5" :width="iconWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
      :style="{
        stroke: cColor,
      }" :class="{Parker: true}">
      <rect x="0" y="0" width="100%" height="100%" fill="red"/>
      <line x1="0" y1="0%" x2="100%" y2="100%" />
      <line x1="100%" y1="0%" x2="0%" y2="100%" />
    </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: 'Parker',
  props: ['options', 'selectedDeviceId'],
  computed: {
    ...mapGetters({
      getChildren: 'getChildren',
      convertLength: 'convertLength',
      getDeviceById: 'getDeviceById',
      getNumOfParent: 'getNumOfParent',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return this.cGapWidth/1.5;
    },
    cColor() {
      return '#000';
    },
    iconWidth() {
      let res = this.cGapWidth
      let top
      if (this.mdMode) {
        top = Number(this.options.Properties.find(prop => prop.name === 'topMd').value)
      } else {
        top = Number(this.options.Properties.find(prop => prop.name === 'top').value)
      }
      let children = this.getChildren('WBS', this.cId)
      if (children && children.length) {
        let casing = children.find(child => child.id_equipment_type === 1)
        if (casing) {
          let hasCrossOver = casing.Properties.find(prop => prop.name === 'hasCrossOver')
          if (hasCrossOver && Number(hasCrossOver.value)) {
            let crossOverPos = casing.Properties.find(prop => prop.name === 'crossOverPos')
            if (crossOverPos) {
              let childrenTop, childrenBottom
              if (this.mdMode) {
                childrenTop = Number(casing.Properties.find(prop => prop.name === 'topMd').value)
                childrenBottom = Number(casing.Properties.find(prop => prop.name === 'bottomMd').value)
              } else {
                childrenTop = Number(casing.Properties.find(prop => prop.name === 'top').value)
                childrenBottom = Number(casing.Properties.find(prop => prop.name === 'bottom').value)
              }
              crossOverPos = Math.round(crossOverPos.value/ 100 * (childrenBottom - childrenTop) + childrenTop)
              if (top > crossOverPos) {
                res += this.cGapWidth
              }
            }
          }
        }
      }
      let parent = this.getDeviceById('WBS', this.options.id_parent)
      if (parent) {
        let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
        if (hasCrossOver && Number(hasCrossOver.value)) {
            let crossOverPos = parent.Properties.find(prop => prop.name === 'crossOverPos')
            if (crossOverPos) {
              let parentTop, parentBottom
              if (this.mdMode) {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'topMd').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottomMd').value)
              } else {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'top').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottom').value)
              }
              let crossOverPosNumber = Math.round((+crossOverPos.value/ 100) * (parentBottom - parentTop) + parentTop)
              if (top < crossOverPosNumber) {
                res += this.cGapWidth
              }
            }
          }
      }
      return res;
    },
    _offsetLeft() {
      let parent = this.getDeviceById('WBS', this.options.id_parent)
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      if (parent) {
        let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
        if (hasCrossOver && Number(hasCrossOver.value)) {
            let crossOverPos = parent.Properties.find(prop => prop.name === 'crossOverPos')
            if (crossOverPos) {
              let parentTop, parentBottom
              if (this.mdMode) {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'topMd').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottomMd').value)
              } else {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'top').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottom').value)
              }
              crossOverPos = Math.round((Number(crossOverPos.value)/ 100 * (parentBottom - parentTop)) + parentTop)
              if (Number(top) < crossOverPos) {
                return this.cOffsetLeft - this.cGapWidth
              }
            }
          }
      }
      return this.cOffsetLeft;
    },
    cNumOfParent() {
      return this.getNumOfParent(this.options.id)
    }
  },
  mixins: [gapWidth, offsetLeft, selection, top]
}
</script>

<style scoped lang='scss'>
  .Parker {
    z-index: 10;
  }
</style>
