import { doLogin } from '../../utils/api';
import jwt_decode from "jwt-decode";

const auth = {
    state: {
        token: null,
        tmpToken: false,
        error: null,
    },
    getters: {
        cUser: state => {
            if (!state.token) return null;
            let tokenData = jwt_decode(state.token);
            return tokenData;
        }
    },
    mutations: {
        clearToken(state) {
            state.token = null;
        },
        setToken(state, value) {
            state.token = value;
        },
        authenticate(state) {
            let token = new URLSearchParams(location.search).get('token')
            if (token) {
                state.tmpToken = true;
            } else {
                token = localStorage.getItem('token');
            }
            state.token = token;
        },
        logout(state) {
            state.token = null;
            localStorage.removeItem('token');
            location.reload();
        },

    },
    actions: {
        async login(context, { username, password }) {
            try {
                const session = await doLogin('/login', { username, password });
                console.log(session);
                const token = session.token;
                context.commit('setToken', token);
                localStorage.setItem('token', token);
            } catch (error) {
                context.state.error = "User name or password is incorrect";
                setTimeout(() => {
                    context.state.error = null;
                }, 3000)
            }
        },
    }
}
export default auth;
