<template>
  <div :style="{
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 0.5 + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*(cOffsetLeft + 0.5) + 'px)',
  }" @click="$emit('select', {id: options.id});" :class="{CementPlug: true, 'outlined': selectedDeviceId === options.id || isSelected}" >
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "CementPlug",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
      let top, bottom
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
        bottom = this.options.Properties.find(prop => prop.name === 'bottomMd')
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
        bottom = this.options.Properties.find(prop => prop.name === 'bottom')        
      }
      if (!bottom) {
        bottom = +top + 10
      } else {
        bottom = bottom.value
      }
      return this.convertLength(Math.abs(bottom - top));
    },
    cColor() {
      return '#4058b8';
    },
  }
}
</script>

<style scoped lang='scss'>
  .CementPlug {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    background-color: #f0d681;
  }
  .selected {
    // box-shadow: 0 0px 6px 0 #7caaf8, 
    // 0 0px 6px 0 #7caaf8, 
    // 0 2px 6px 2px #7caaf8, 
    // 0 2px 6px 0 #7caaf8;
    filter: drop-shadow(0 0 8px #0958e0);
  }
</style>
