<template>
  <md-dialog :md-active.sync="isShow" :md-click-outside-to-close="false">
    <md-dialog-title>
      Add New Equipment
      <md-button
        class="md-primary"
        @click="resetProperty"
        :style="{
          height: '30px',
          padding: 0,
          margin: 0,
          marginBottom: '5px',
        }"
      >
        Reset</md-button
      >
    </md-dialog-title>

    <md-dialog-content class="dialog">
      <div class="dialog-layout">
        <md-field>
          <custom-dropdown
            name="group-device"
            :key="resetGroup"
            :options="bhaGroups"
            :label="'Group'"
            :handleSelect="groupSelect"
            :placeholder="newEquip.group.name"
            :isRequired="true"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="bha_Device_dropdown"
            :key="resetDevice"
            :options="bhaGroupDevices"
            :label="'Device'"
            :handleSelect="deviceSelect"
            :placeholder="newEquip.bhaDevice.name"
            :isRequired="true"
            :handlePreview="handlePreview"
          />
        </md-field>

        <!-- <md-field>
          <label for="group">Group</label>
          <span class="isRequired">*</span>
          <md-select v-model="newEquip.group" name="group"
            id="group" placeholder="Group">
            <md-option v-for="(gr, id) in bhaGroups"
              :value="gr.group"
              :key="id"
            >{{ gr.group }}</md-option>
          </md-select>
        </md-field>

        <md-field>
          <label for="name">BHA Device</label>
          <span class="isRequired">*</span>
          <md-select v-model="newEquip.id_bha_device" name="device"
              id="device" placeholder="BHA Device">
              <md-option v-for="(dv, id) in bhaGroupDevices" :value="dv.id" :key="id">{{ dv.device_name }}</md-option>
            </md-select>
        </md-field> -->

        <md-field>
          <label for="name"
            >Equipment name <span class="isRequired">*</span></label
          >
          <md-input v-model="newEquip.name" name="name" id="name"></md-input>
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-manufacture"
            :key="resetDevice"
            :options="cManufactureDropList"
            :label="'Manufacture'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.manufacture"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-OD"
            :key="resetDevice"
            :options="cOdDropList"
            :label="'OD (in)'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.OD"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-ID"
            :key="resetDevice"
            :options="cIdDropList"
            :label="'ID (in)'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.ID"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-maxOd"
            :key="resetDevice"
            :options="cMaxOdDroplist"
            :label="'MaxOD (in)'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.maxOd"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-topSize"
            :key="resetDevice"
            :options="cTopSizeDropList"
            :label="'Top Size (in)'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.topSize"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-bottomSize"
            :key="resetDevice"
            :options="cBottomSizeDroplist"
            :label="'Bottom Size (in)'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.bottomSize"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-topType"
            :key="resetDevice"
            :options="cTopTypeDropList"
            :label="'Top Type'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.topType"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-bottomType"
            :key="resetDevice"
            :options="cBottomTypeDropList"
            :label="'Bottom Type'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.bottomType"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-topGender"
            :key="resetDevice"
            :options="cTopGenderDropList"
            :label="'Top Gender'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.topGender"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <custom-dropdown
            name="new-bottomGender"
            :key="resetDevice"
            :options="cBottomGenderDropList"
            :label="'Bottom Gender'"
            :handleSelect="handleSelect"
            :placeholder="newEquip.properties.bottomGender"
            :isRequired="false"
          />
        </md-field>

        <md-field>
          <label for="length">Length (m)</label>
          <md-input
            v-model="newEquip.properties.length"
            type="number"
            name="length"
            id="length"
          ></md-input>
        </md-field>

        <md-field>
          <label>Description</label>
          <md-input
            v-model="newEquip.properties.description"
            name="description"
            id="description"
          ></md-input>
        </md-field>

        <md-field>
          <label for="serialNumber">Serial Number</label>
          <md-input
            v-model="newEquip.properties.serialNumber"
            name="serialNumber"
            id="serialNumber"
          ></md-input>
        </md-field>

        <md-field>
          <label for="cumLength">Cum Length (m)</label>
          <md-input
            :disabled="true"
            v-model="cumLength"
            type="number"
            name="cumLength"
            id="cumLength"
          ></md-input>
        </md-field>

        <md-field>
          <label for="weight">Weight (Lb)</label>
          <md-input
            v-model="newEquip.properties.weight"
            name="weight"
            id="weight"
          ></md-input>
        </md-field>
        <md-field>
          <label for="scaleFactor">Scale Factor</label>
          <md-input
            v-model="newEquip.properties.scaleFactor"
            name="scaleFactor"
            id="scaleFactor"
          ></md-input>
        </md-field>
      </div>

      <img
        class="preview_image"
        v-if="bha_image_src"
        :src="bha_image_src"
        alt="Preview image"
      />
    </md-dialog-content>

    <md-dialog-actions>
      <md-button
        class="md-primary"
        :disabled="isUnableAddNewEquip"
        @click="handleAddEquip"
        >Add</md-button
      >
      <md-button class="md-primary" @click="handleClose">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapState } from "vuex";
import config from "../utils/config";
import DropdownInput from "./DropdownInput.vue";
import bhaDefaultValue from "../utils/bhaDefaultValue.json";
import bhaDroplist from "../mixins/bhaDroplist";

export default {
  components: {
    "custom-dropdown": DropdownInput,
  },
  name: "NewBhaEquipmentDialog",
  mixins: [bhaDroplist],
  data() {
    return {
      cumLength: 0,
      newEquip: {
        id_parent: null,
        id_equipment_type: 3,
        name: "",
        group: {},
        bhaDevice: {},
        id_bha_device: null,
        properties: {
          description: "",
          manufacture: "",
          serialNumber: "",
          OD: null,
          ID: null,
          maxOd: null,
          topSize: null,
          bottomSize: null,
          topType: "",
          bottomType: "",
          topGender: "",
          bottomGender: "",
          length: null,
          weight: null,
          scaleFactor: null,
        },
      },
      bha_image_src: null,
      resetGroup: false,
      resetDevice: false,
      hover_src: null,
    };
  },
  props: ["isShow", "handleClose"],
  computed: {
    isUnableAddNewEquip() {
      if (
        !this.newEquip.name ||
        !this.newEquip.group ||
        !this.newEquip.id_bha_device
      ) {
        return true;
      }
      return false;
    },
    ...mapState({
      bhaGroups: (state) => {
        return state.bha.bhaGroups.map((x) => ({
          ...x,
          name: x.group,
          icon_path: x.icon_path
            ? config.serviceUrl + "/bhaDevice/" + x.icon_path
            : "https://icons.veryicon.com/png/o/miscellaneous/8atour/extra-bed-16x16.png",
        }));
      },
      bhaGroupDevices: (state) => {
        return state.bha.bhaGroupDevices.map((x) => ({
          ...x,
          name: x.device_name,
          icon_path: x.icon_path
            ? config.serviceUrl + "/bhaDevice" + x.icon_path
            : "https://icons.veryicon.com/png/o/miscellaneous/8atour/extra-bed-16x16.png",
        }));
      },
      bhaDevices: (state) => {
        return state.well.bhaDevices;
      },
    }),
    cDefaultValue() {
      return bhaDefaultValue;
    },
    cCumLength() {
      let res = 0;
      let length;
      for (const device of this.bhaDevices) {
        length = device.Properties.find((prop) => prop.name === "length");
        if (length && length.value) {
          res += Number(length.value);
        }
      }
      return res;
    },
  },
  watch: {
    "newEquip.group"(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$store.dispatch("getBhaGroupDevices", newVal.group);
      }
    },
    "newEquip.id_bha_device"(newVal) {
      let image_path = (
        this.bhaGroupDevices.filter((d) => d.id === newVal)[0] || {}
      ).image_path;
      this.bha_image_src = `${config.serviceUrl}/bhaDevice/${image_path}` || "";
    },
    "bhaDevices.length"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.close();
      }
    },
    "newEquip.bhaDevice.device_name"(newVal) {
      // console.log(newVal)
      if (this.cDefaultValue[newVal]) {
        let defaultValue = this.cDefaultValue[newVal];
        for (const key of Object.keys(defaultValue)) {
          if (key === "name") {
            this.newEquip.name = defaultValue.name;
          } else {
            this.newEquip.properties[key] = defaultValue[key];
          }
        }
        this.cumLength = this.cCumLength + Number(defaultValue["length"]);
      }
    },
    "newEquip.properties.length"(newVal) {
      this.cumLength = Number(this.cCumLength) + Number(newVal);
    },
  },
  methods: {
    handleAddEquip() {
      this.addEquipModalShow = false;
      let data = this.newEquip;
      this.$store.dispatch("addBhaEquip", { newEquip: data });
    },
    groupSelect(item) {
      this.newEquip.group = item;
      this.newEquip.bhaDevice = {};
      this.newEquip.id_bha_device = null;
      // console.log(item)
    },
    deviceSelect(item) {
      this.newEquip.bhaDevice = item;
      this.newEquip.id_bha_device = item.id;
    },
    resetProperty() {
      this.newEquip = {
        id_parent: null,
        id_equipment_type: 3,
        name: "",
        group: {},
        bhaDevice: {},
        id_bha_device: null,
        properties: {
          description: "",
          manufacture: "",
          serialNumber: "",
          OD: null,
          ID: null,
          maxOd: null,
          topSize: null,
          bottomSize: null,
          topType: "",
          bottomType: "",
          topGender: "",
          bottomGender: "",
          length: 0,
          weight: null,
          scaleFactor: null,
        },
      };
      this.bha_image_src = "";
      this.resetGroup = !this.resetGroup;
      this.resetDevice = !this.resetDevice;
    },
    close() {
      this.handleClose();
      this.resetProperty();
    },
    handleSelect(value, name) {
      name = name.split("-")[1];
      this.newEquip.properties[name] = value.name;
    },
    handlePreview(imagePath) {
      this.bha_image_src = `${config.serviceUrl}/bhaDevice/${imagePath}` || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog ::v-deep.md-dialog-container {
  width: 999px;
  .dialog {
    display: flex;
    justify-content: center;

    .preview_image {
      width: 10%;
      height: 10%;
      margin-left: 5%;
      margin-top: 5%;
    }
  }
  .md-dialog-actions {
    padding-right: 24px;
  }
}

.dialog-layout {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  width: 100%;
  > div {
    // width: 45%;
    margin: 15px 0 !important;
  }
}
.clear-parent-btn {
  height: auto;
  min-width: auto;
  margin: 0;
  > .md-ripple {
    padding: 0;
  }
}
.isRequired {
  color: red;
}
</style>
