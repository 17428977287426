<template>
  <div :style="{
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2 * cOffsetLeft + 'px)',
  }" @click="$emit('select', {id: options.id});" :class="{WhipstockRight: true, 'outlined': selectedDeviceId === options.id || isSelected}" >
			<svg height="100%" width="100%" viewBox="0 0 100 100" 
				preserveAspectRatio="none">
				<polygon points="0,0 0,100 100,100" fill="#000"/>
			</svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "WhipstockRight",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
			return 30;
    },
    cColor() {
      return '#4058b8';
    },
  }
}
</script>

<style scoped lang='scss'>
  .WhipstockRight {
    position: absolute;
    z-index: 10;
		display: flex;
    cursor: pointer;
  }
</style>
