<template>
  <div class="EditableDeviceTable">
    <div class="info-header">
      <h3>Equipments</h3>
      <h3
        class="legend-button"
        :class="{ bordered: showLegend }"
        @click="showLegend = !showLegend"
      >
        <md-icon>expand_more</md-icon>
      </h3>
      <div v-show="showLegend">
        <device-legend :full="true" :type="type" />
      </div>
      <md-button
        style="margin: 0; float: right; height: 30px"
        class="md-primary"
        @click="handleExport"
        >Export
      </md-button>
    </div>
    <div class="info-body">
      <table>
        <thead>
          <tr>
            <th :class="{ 'order-sticky': !showLegend }">No.</th>
            <th :class="{'equipment-name-sticky': !showLegend}">Equipment name</th>
            <th v-for="(f, idx) in cFields" :key="idx">
              {{ f.label || f.name || f }}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{
              selected: dev.id === selectedEquipmentId,
              faded: !isVisible(dev),
            }"
            v-for="(dev, idx) in cDevices"
            :key="`${idx}-${fakeKey}`"
            @click="$emit('select', { id: dev.id })"
          >
            <td :class="{ 'order-sticky': !showLegend }">{{ idx + 1 }}</td>
            <td :class="{'equipment-name-sticky': !showLegend}">
              <Legend
                x-style="display:inline-block;margin-right:0.5em;"
                :type="type"
                :signature="
                  reverseLegendTable[dev.activityType] ||
                  reverseLegendTable[dev.device_name]
                "
              />{{ dev.name }}
            </td>
            <td v-for="(f, idx1) in cFields" :key="`${idx}-${idx1}`">
              <component
                v-if="f.type == 'md-field'"
                :is="f.type"
                style="width: 140px"
              >
                <md-input v-model="dev[f.key || f]"> </md-input>
              </component>
              <component
                v-else
                :is="f.type"
                v-model="dev[f.key || f]"
                :md-immediately="true"
                class="small-text"
                style="width: 140px"
              />
            </td>
            <td>
              <div
                class="inline-block pointer mr-10"
                @click="
                  () =>
                    save(dev.id, dev.name, getPropertiesChange(dev, cFields))
                "
              >
                <md-icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"
                    />
                  </svg>
                </md-icon>
              </div>
              <div
                v-if="type === 'WBS'"
                class="inline-block pointer"
                @click="hide(dev.id, dev.stopTime)"
              >
                <md-icon v-if="!dev.stopTime">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <path
                      d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
                      fill="none"
                    />
                    <path
                      d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53z"
                    />
                  </svg>
                </md-icon>
                <md-icon v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
                    />
                  </svg>
                </md-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Legend from "./Legend";
import DeviceLegend from "./DeviceLegend";
import {
  LegendTable,
  reverseLegendTable,
  reverseBhaLegendTable,
} from "../utils/legend";
import eventManager from "../eventManager";
import { v4 } from "uuid";
import { mapState } from "vuex";
import XLSX from "xlsx";

export default {
  name: "EditableDeviceTable",
  props: ["devices", "fields", "selectedEquipmentId", "type", "checkDate"],
  components: {
    Legend,
    DeviceLegend,
  },
  data() {
    return {
      LegendTable: LegendTable,
      showLegend: false,
      fakeKey: v4(),
    };
  },
  computed: {
    ...mapState({
      well: (state) => state.well.well,
    }),
    cFields() {
      return (
        this.fields || [
          {
            key: "startTime",
            label: "Start time",
            type: "md-datepicker",
          },
          {
            key: "stopTime",
            label: "Stop time",
            type: "md-datepicker",
          },
        ]
      );
    },
    cDevices: {
      get() {
        const props = [];
        let value = null;
        for (let equip of this.devices) {
          let tmpProp = {};
          for (let prop of equip["Properties"]) {
            if (prop.name === "startTime" || prop.name === "stopTime") {
              value = prop.value ? new Date(prop.value) : null;
            } else {
              value = prop.value;
            }
            tmpProp[prop.name] = value;
          }
          tmpProp.name = equip.name;
          tmpProp.id = equip.id;
          tmpProp.id_equipment_type = equip.id_equipment_type;
          if (this.type === "BHA") {
            tmpProp.device_name = equip.bha_device.device_name;
          }
          props.push(tmpProp);
        }
        return props;
      },
      set() {},
    },
    reverseLegendTable() {
      if (this.type === "BHA") {
        return reverseBhaLegendTable;
      }
      return reverseLegendTable;
    },
    cHeaderProperty() {
      if (!this.devices.length) {
        return [];
      }
      if (this.type === "WBS") {
        return [
          "STT",
          "Name",
          "Activity Type",
          "OD (m)",
          "ID (m)",
          "Top (TVD)",
          "Bottom (TVD)",
          "Top (MD)",
          "Bottom (MD)",
          "Length (m)",
          // "Has Bending",
          // "Bending Position",
          // "Deviation Angle",
          // "Azimuth Angle",
          // "Has CrossOver",
          // "CrossOver Position",
          "Start Time",
          "Stop Time",
          "Note",
          "Comment",
        ];
      }
      return [
        "STT",
        "Name",
        "Description",
        "Manuufacture",
        "Serial Number",
        "OD (in)",
        "ID (in)",
        "Max OD (in)",
        "Bottom Size",
        "Top Size",
        "Bottom Type",
        "Top Type",
        "Bottom Gender",
        "Top Gender",
        "FN OD (in)",
        "FN Length",
        "Length (m)",
        "Cum.Length (m)",
        "Weight (t)",
      ];
    },
    cHeaderInfo() {
      let header = [];
      if (this.type === "WBS") {
        header = [
          "Well Name",
          "WBS Name",
          "Top",
          "Bottom",
          "Sea Level",
          "Rig Floor",
          "Rig",
          "Created Date",
          "Author",
        ];
      } else if (this.type === "BHA") {
        header = [
          "Well Name",
          "Field Name",
          "Borehold Name",
          "Hole Size (in)",
          "Structure Name",
          "BHA Name",
          "Depth In (m)",
          "Depth Out (m)",
          "Created Date",
          "Author",
        ];
      }
      return header;
    },
    cExportFileName() {
      if (this.type === "WBS") {
        return this.well.well_data.wbs.name || "WBS";
      }
      return this.well.well_data.bha.name || "BHA";
    },
  },
  methods: {
    v4,
    save(id, name, properiesChange) {
      console.log("save", id, name, properiesChange);
      let device = this.devices.find((d) => d.id === id);
      let _properties = device.Properties;
      let obj = {};
      if (properiesChange.name) {
        obj["name"] = properiesChange.name;
      }
      if (Object.keys(properiesChange).indexOf("id_parent") >= 0) {
        obj["id_parent"] = properiesChange["id_parent"];
      }
      if (Object.keys(obj).length) {
        this.$store.dispatch("editEquip", {
          app: device.id_equipment_type === 3 ? "BHA" : "WBS",
          equipId: id,
          ...obj,
        });
      }
      delete properiesChange.name;
      delete properiesChange["id_parent"];
      let res = [];
      Object.keys(properiesChange).forEach((key) => {
        let id = _properties.filter((prop) => prop.name === key)[0].id;
        res.push({
          id,
          value: properiesChange[key],
          name: key,
        });
      });
      res.length > 0 &&
        this.$store.dispatch("editProperties", {
          equipId: id,
          properties: res,
          app: device.id_equipment_type === 3 ? "BHA" : "WBS",
        });
    },
    reset(equipId) {
      let idx = this.cDevices.findIndex((d) => d.id == equipId);
      if (idx >= 0) {
        let name, value;
        let tmpProp = {};
        let equip = this.devices[idx];
        for (let prop of equip["Properties"]) {
          name = prop.name;
          value = prop.value;
          tmpProp[name] = value;
        }
        tmpProp.name = equip.name;
        tmpProp.id = equip.id;
        tmpProp.id_equipment_type = equip.id_equipment_type;
        this.cDevices.splice(idx, 1, { ...tmpProp });
        this.fakeKey = v4();
        // this.$forceUpdate()
      }
    },
    getPropertiesChange(device, fields) {
      let res = {};
      for (const f of fields) {
        res[f.key] = device[f.key];
      }
      return res;
    },
    hide(equipId, _stopTime) {
      let device = this.devices.find((d) => d.id == equipId);
      if (device) {
        let stopTime = device.Properties.find(
          (prop) => prop.name === "stopTime"
        );
        if (!_stopTime) {
          stopTime.value = new Date();
        } else {
          stopTime.value = null;
        }
        this.$store.dispatch("editProperties", {
          equipId,
          properties: [stopTime],
          app: device.id_equipment_type === 3 ? "BHA" : "WBS",
        });
        this.$nextTick(() => {
          eventManager.emit("reset-time");
        });
      }
    },
    getRowKey(idx) {
      let res = "";
      for (const field of this.cFields) {
        res += this.cDevices[idx][field.key];
      }
      console.log(res);
      return res;
    },
    handleExport() {
      let data = [];
      data.push(this.cHeaderInfo);
      if (this.type === "WBS") {
        let wbs = this.well.well_data.wbs;
        data.push([
          this.well.name,
          wbs.name,
          wbs.top,
          wbs.bottom,
          wbs.seaLevel,
          wbs.rigFloor,
          wbs.rigName,
          this.well.createdDate,
          this.well.author,
        ]);
        data.push([]);
        data.push(this.cHeaderProperty);
        let device;
        for (let idx = 0; idx < this.cDevices.length; idx++) {
          device = this.cDevices[idx];
          data.push([
            Number(idx) + 1,
            device.name,
            device.activityType,
            device.OD,
            device.ID,
            device.top,
            device.bottom,
            device.topMd,
            device.bottomMd,
            device.length,
            // device.hasBending,
            // device.bendingPos,
            // device.deviationAngle,
            // device.azimuthAngle,
            // device.hasCrossOver,
            // device.crossOverPos,
            device.startTime,
            device.stopTime,
            device.note,
            device.comment,
          ]);
        }
      } else if (this.type === "BHA") {
        let bha = this.well.well_data.bha;
        data.push([
          this.well.name,
          "",
          "",
          "",
          "",
          bha.name,
          "",
          "",
          bha.createdDate,
          bha.author,
        ]);
        data.push([]);
        data.push(this.cHeaderProperty);
        let device,
          cumLength = 0;
        for (let i = 0; i < this.cDevices.length; i++) {
          device = this.cDevices[i];
          cumLength += Number(device.length);
          data.push([
            Number.parseInt(i) + 1,
            device.name,
            device.description,
            device.manufacture,
            device.serialNumber,
            device.OD,
            device.ID,
            device.maxOd,
            device.bottomSize,
            device.topSize,
            device.bottomType,
            device.topType,
            device.bottomGender,
            device.topGender,
            device.fnOd,
            device.fnLength,
            device.length,
            cumLength,
            device.cumWeight,
          ]);
        }
      }
      let worksheet = XLSX.utils.aoa_to_sheet(data);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, `${this.cExportFileName}.xlsx`);
    },
    isVisible(dev) {
      let currentTs = new Date(this.checkDate).getTime();

      if (dev.stopTime & dev.startTime) {
        let stopTs = dev.stopTime.getTime();
        let startTs = dev.startTime.getTime();
        return (currentTs - startTs) * (currentTs - stopTs) < 0;
      }
      if (dev.startTime) {
        let startTs = dev.startTime.getTime();
        return currentTs > startTs;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.EditableDeviceTable {
  background-color: #fff;
  margin: 0px 5px 0px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 35px);
  table {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    margin-bottom: 15px;
    thead tr {
      text-align: center;
    }
    th {
      color: navy;
      text-transform: capitalize;
    }
    th,
    td {
      text-align: left;
      padding: 0.5em 1em;
      border: 1px solid #eee;
      white-space: nowrap;
      /*min-width: 70px;*/
    }
    .order-sticky { 
      position: sticky;
      left: 0px;
      z-index: 30;
      background-color: white;
      width: 45px;
      border: none;
      border-bottom: 1px solid #eee;
      &:after { 
        content: "";
        background-color: #eee;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        z-index: 30;
      }
    }
    .equipment-name-sticky { 
      position: sticky;
      left: 45px;
      z-index: 20;
      background-color: white;
      width: 45px;
      border: none;
      border-bottom: 1px solid #eee;
      &:after { 
        content: "";
        background-color: #eee;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        z-index: 20;
      }
    }
    tr.selected {
      color: #1962a7;
      background-color: #fbf5e9;
      .md-field.md-theme-default.md-has-value .md-input {
        -webkit-text-fill-color: #1962a7;
        color: #1962a7;
      }
      .order-sticky {
        background-color: #fbf5e9;
      }
      .equipment-name-sticky {
        background-color: #fbf5e9;
      }
    }
  }
  .md-datepicker,
  .md-field {
    padding: 0;
    margin: 0;
    min-height: unset;
    button.md-clear,
    button.md-clear:before {
      position: static;
      top: unset;
      right: unset;
    }
    input {
      width: 100%;
    }
  }
  .md-datepicker:after,
  .md-field:after {
    height: 0px;
  }
  .md-icon svg {
    fill: #ccc !important;
  }
  .md-icon:hover svg {
    fill: #1962a7 !important;
  }
  .md-icon {
    color: #ccc;
  }
  /*.md-icon:hover.md-theme-default.md-icon-font {
    color: #1962a7 !important;
  }*/
  .info-header {
    padding: 20px 0;
    position: relative;
    & > h3 {
      margin: 0;
      color: #2c3e50;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      display: inline-block;
    }
    & > h3.legend-button {
      position: relative;
      padding: 0px 10px;
      margin: 0 5px;
      z-index: 7;
      background-color: #fff;
    }
    & > h3.legend-button.bordered {
      border: 1px solid #ccc;
      border-bottom: none;
    }
    span {
      cursor: pointer;
    }
    & > div {
      position: absolute;
      left: -10px;
      right: 20px;
      top: 42px;
      z-index: 6;
    }
  }
  .info-body {
    border: 1px solid #f0f0f0;
    border-bottom: none;
    overflow: auto;
    flex: 1;
  }
  tbody tr {
    cursor: pointer;
  }
  tr.selected {
    color: #1962a7;
    background-color: #fbf5e9;
    .md-field.md-theme-default.md-has-value .md-input {
      -webkit-text-fill-color: #1962a7;
      color: #1962a7;
    }
  }
  .small-text.md-field.md-has-value .md-input {
    font-size: 1em;
    padding: 0;
  }
  .md-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    font-size: 20px !important;
  }
  tr.faded,
  tr.faded * {
    color: #ccc !important;
    -webkit-text-fill-color: unset !important;
  }
}
</style>
