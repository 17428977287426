import eventManager from '../../eventManager';
import {
    addBhaDevice,
    addWbsDevice,
    cloneWell,
    createWell,
    deleteDevice,
    deleteWell, editWell,
    getBhaDevices,
    getWbsDevices,
    getWells,
    service
} from "../../utils/api";

const getProperties = device => {
    for (const prop of device.Properties) {
        if (prop.name === 'type' || prop.name === 'terminatorType') {
            device[prop.name] = prop.value;
        }
    }
    // delete device.Properties;
}

const well = {
    state: {
        wells: null,
        well: null,
        bhaDevices: [],
        wbsDevices: [],
        message: {
            success: null,
            error: null
        },
        ULENGTH: 10,
        BHA_IMAGE_SIZE: 80,
        GAP_WIDTH: 20
    },
    getters: {
        cWbsDiagramWidth: state => {
            let wbsDevices = state.wbsDevices;
            if (wbsDevices) {
                let casingList = wbsDevices
                    .filter(device => device.type === 'Casing');
                let res = 0;
                let hasCrossOver;
                for (const casing of casingList) {
                    hasCrossOver = casing.Properties.find(prop => prop.name === 'hasCrossOver')
                    if (hasCrossOver && Number(hasCrossOver.value)) {
                        res += 4 * state.GAP_WIDTH
                    } else {
                        res += 2 * state.GAP_WIDTH;
                    }
                }
                return res + 2 * state.GAP_WIDTH;
            } else {
                return 0;
            }
        },
        numOfWbsDevice(state) {
            return state.wbsDevices.length
        },
        getChildren: (state) => (app, equipId) => {
            if (app === 'WBS') {
                return state.wbsDevices.filter(device => device.id_parent === equipId);
            }
        },
        getDeviceById: (state) => (app, equipId) => {
            if (app === 'WBS') {
                return state.wbsDevices.find(device => device.id === equipId)
            }
            if (app === 'BHA') {
                return state.bhaDevices.find(device => device.id === equipId)
            }
        },
        getHangerTop: (state, getters) => (equipId) => {
            let hanger = state.wbsDevices.find(device => device.id === equipId)
            if (hanger) {
                let casing = state.wbsDevices.find(device => device.id === hanger.id_parent
                    && device.id_equipment_type === 1);
                if (!casing) {
                    return 0;
                }
                let stickToTop = casing.Properties.find(prop => prop.name === 'stickToTop').value
                if (Number(stickToTop)) {
                    return 0;
                }
                let top = casing.Properties.find(prop => prop.name === 'top').value
                return Number(getters.convertLength(top));
            }
            return 0
        },
        getShoeTop: (state, getters) => (equipId) => {
            let shoe = state.wbsDevices.find(device => device.id === equipId)
            if (shoe) {
                let casing = state.wbsDevices.find(device => device.id === shoe.id_parent
                    && device.id_equipment_type === 1);
                if (!casing) {
                    return 0;
                }
                let bottom = casing.Properties.find(prop => prop.name === 'bottom').value
                let top = casing.Properties.find(prop => prop.name === 'top').value
                let height = getters.convertLength(bottom - top);
                let _top = getters.convertLength(top);
                let stickToTop = casing.Properties.find(prop => prop.name === 'stickToTop').value
                if (Number(stickToTop)) {
                    _top = 0;
                }
                return Number(_top + height);
            }
            return 0
        },
        convertLength: (state, getters) => (length) => Math.ceil(length * (getters.cZoomWBS || 1) / state.ULENGTH) * state.ULENGTH,
        cZoomFactor(state) {
            return ((state.well || {}).well_data || {}).zoomFactor || 1.2;
        },
        cZoom(state) {
            return ((state.well || {}).well_data || {}).zoom || 1;
        },
        cZoomWBS(state) {
            return ((state.well || {}).well_data || {}).zoomWBS || ((state.well || {}).well_data || {}).zoom || 1;
        },
        cZoomBHA(state) {
            return ((state.well || {}).well_data || {}).zoomBHA || ((state.well || {}).well_data || {}).zoom || 1;
        },
        cBhaImageSize(state, getters) {
            return getters.cZoomBHA * state.BHA_IMAGE_SIZE;
        },
        cWbsInfo(state) {
            let wbs = ((state.well || {}).well_data || {}).wbs || {};
            wbs.name = wbs.name || null;
            wbs.rigFloor = wbs.rigFloor || null;
            wbs.rigName = wbs.rigName || null;
            wbs.top = wbs.top || null;
            wbs.bottom = wbs.bottom || null;
            wbs.seaLevel = wbs.seaLevel || null;
            return wbs;
        },
        cBhaInfo(state) {
            return ((state.well || {}).well_data || {}).bha;
        },
        getNumOfParent: (state) => (equipId) => {
            let countParent = (equipId) => {
                let res = 0;
                if (!equipId) {
                    return 0;
                }
                let device = state.wbsDevices.find(device => device.id === equipId)
                res += 1 + countParent(device.id_parent);
                return res;
            }
            return countParent(equipId) - 1;
        },
        getDeviceOffsetLeft: (state, getters) => (equipId) => {
            let device = state.wbsDevices.find(device => device.id === equipId)
            if (!device || !device.id_parent) {
                return 0;
            }
            const getOffsetLeft = (equipId) => {
                let res = 0;
                if (!equipId) {
                    return 0;
                }
                let device = state.wbsDevices.find(device => device.id === equipId)
                let hasCrossOver = device.Properties.find(prop => prop.name === 'hasCrossOver')
                if (hasCrossOver && Number(hasCrossOver.value) && device.id_equipment_type === 1) {
                    res += state.GAP_WIDTH + 2
                }
                if (device.id_equipment_type === 1) {
                    res += state.GAP_WIDTH + 2
                }
                res += getOffsetLeft(device.id_parent)
                return res;
            }
            // Thiết bị nằm trên xi măng
            if (device.id_equipment_type === 2 || device.id_equipment_type === 12) {
                let parent = state.wbsDevices.find(d => d.id === device.id_parent)
                if (!parent) {
                    return 0;
                }
                let offsetLeft = getOffsetLeft(parent.id_parent);
                return offsetLeft
            }

            // Thiết bị lòng giếng
            if (device.id_equipment_type === 6 || device.id_equipment_type === 5
                || device.id_equipment_type === 7 || device.id_equipment_type === 10
                || device.id_equipment_type === 11) {
                let offsetLeft = getOffsetLeft(device.id_parent)
                let mdMode = getters.showInMd
                let top
                if (mdMode) {
                    top = Number(device.Properties.find(prop => prop.name === 'topMd').value)
                } else {
                    top = Number(device.Properties.find(prop => prop.name === 'top').value)
                }
                if (device.id_equipment_type === 10) {
                    offsetLeft -= 1 / 2 * state.GAP_WIDTH
                }
                if (getters.isAboveCrossOver(device.id_parent, top)) {
                    return offsetLeft - state.GAP_WIDTH - 2
                }
                return offsetLeft - 1
            }
            // Trường hợp default (casing)
            return getOffsetLeft(device.id_parent);
        },
        isAboveCrossOver: (state, getters) => (id_parent, top) => {
            let mdMode = getters.showInMd
            let parent = state.wbsDevices.find(device => device.id === id_parent)
            let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
            if (!hasCrossOver || !Number(hasCrossOver.value) || parent.id_equipment_type !== 1) {
                return false
            }
            let parentTop, parentBottom
            if (mdMode) {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'topMd').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottomMd').value)
            } else {
                parentTop = Number(parent.Properties.find(prop => prop.name === 'top').value)
                parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottom').value)
            }
            let crossOverPos = parent.Properties.find(prop => prop.name === 'crossOverPos')
            if (crossOverPos && crossOverPos.value) {
                crossOverPos = Number(crossOverPos.value) / 100 * (parentBottom - parentTop)
                if (top < crossOverPos) {
                    return true
                }
                return false;
            }
            let middle = (parentBottom - parentTop) / 2
            if (top < (parentTop + middle)) {
                return true;
            }
        },
        scaleFactorProp: (state) => (id) => {
            let bha = state.bhaDevices.find(bha => bha.id === id);
            return bha.Properties.find(prop => (prop.name === 'scaleFactor' && prop.isRequired)).value;
        },
        getCumLength: (state) => id => {
            let res = 0;
            let idx = state.bhaDevices.findIndex(device => device.id == id)
            if (idx >= 0) {
                let length
                for (let i = 0; i <= idx; i++) {
                    length = state.bhaDevices[i].Properties.find(prop => prop.name === 'length')
                    if (length) {
                        res += Number(length.value)
                    }
                }
            }
            return res;
        },
        getBendingPoints: (state, getters) => {
            let maxBottom = 0;
            let bendingPoints = [];
            bendingPoints.push({
                deviationAngle: 0,
                azimuthAngle: 0,
                deep: 0,
            })
            let mdMode = getters.showInMd
            state.wbsDevices.forEach(device => {
                if (device.id_equipment_type !== 1) return;
                let hasBending = (device.Properties.find(prop => prop.name === 'hasBending') || {}).value || 0;
                let deviationAngle = (device.Properties.find(prop => prop.name === 'deviationAngle') || {}).value || 0;
                let azimuthAngle = (device.Properties.find(prop => prop.name === 'azimuthAngle') || {}).value || 0;
                let bendingPos = (device.Properties.find(prop => prop.name === 'bendingPos') || {}).value || 0;
                let bottom;
                if (mdMode) {
                    bottom = (device.Properties.find(prop => prop.name === 'bottomMd') || {}).value || 0;
                } else {
                    bottom = (device.Properties.find(prop => prop.name === 'bottom') || {}).value || 0;
                }
                bottom = Number(bottom);
                if (maxBottom < bottom) {
                    maxBottom = bottom;
                }
                if (Number(hasBending) > 0 && Number(deviationAngle) > 0) {
                    bendingPoints.push({
                        deviationAngle: Number(deviationAngle),
                        azimuthAngle: Number(azimuthAngle),
                        deep: Number(bendingPos)
                    });
                }
            });

            bendingPoints.push({
                deviationAngle: 0,
                azimuthAngle: 0,
                deep: maxBottom,
            })

            bendingPoints.sort((obj1, obj2) => {
                if (obj1.deep < obj2.deep) return -1;
            })
            console.log(bendingPoints);

            /*bendingPoints = bendingPoints.filter((point, idx) => {
                if (idx + 1 == bendingPoints.length) return true;
                if (point.deep == bendingPoints[idx + 1].deep &&
                    point.deviationAngle == bendingPoints[idx + 1].deviationAngle &&
                    point.azimuthAngle == bendingPoints[idx + 1].azimuthAngle) return false;

                return true;
            })*/


            return bendingPoints;
        },
        cSeaLevel: (state) => {
            let wbs = ((state.well || {}).well_data || {}).wbs || {};
            return Number(wbs.seaLevel || 0);
        },
        getDecendant: (state, getters) => (equipId) => {
            let res = []
            let _getDecendant = (_equipId) => {
                let arr = getters.getChildren('WBS', _equipId)
                if (arr && arr.length) {
                    for (const equip of arr) {
                        _getDecendant(equip.id)
                    }
                    res.push(...arr)
                }
            }
            _getDecendant(equipId)
            return res
        },
        showInMd: (state) => {
            let well = state.well
            if (well && well.well_data) {
                if (well.well_data.wbs) {
                    return well.well_data.wbs.mdMode;
                }
            }
            return false;
        }
    },
    mutations: {
        setWells(state, wells) {
            state.wells = wells;
        },
        setWell(state, { data }) {
            const { wellId, wbsDevices, bhaDevices } = data
            const well = state.wells.find(well => well.id === wellId);
            state.well = well;
            state.bhaDevices = bhaDevices;
            wbsDevices.forEach(device => getProperties(device))
            state.wbsDevices = wbsDevices;
            // state.ULENGTH = 50;
        },
        setMessage(state, { message, type }) {
            if (type === 'success') {
                state.message.success = message;
            } else {
                state.message.error = message;
            }
        },
        handleDeleteWbsEquip(state, { ids }) {
            const deleteEquip = (idList) => {
                let res = [];
                state.wbsDevices = state.wbsDevices.filter(device => {
                    if (idList.indexOf(device.id) >= 0) {
                        res.push(device.id);
                    }
                    return idList.indexOf(device.id) < 0;
                })
                state.wbsDevices = state.wbsDevices.filter(device => {
                    if (idList.indexOf(device.id_parent) >= 0) {
                        res.push(device.id)
                    }
                    return idList.indexOf(device.id_parent) < 0;
                })
                if (res.length > 0) {
                    deleteEquip(res)
                }
            }
            deleteEquip(ids)
        },
        handleDeleteBhaEquip(state, { ids }) {
            state.bhaDevices = state.bhaDevices.filter(device => {
                return ids.indexOf(device.id) < 0
            })
        },
        handleAddWbsEquip(state, newDevice) {
            let id_parent = newDevice.id_parent;
            let idx = state.wbsDevices.map(device => device.id_parent).lastIndexOf(id_parent)
            getProperties(newDevice)
            if (idx < 0) {
                idx = state.wbsDevices.map(device => device.id).lastIndexOf(id_parent)
                if (idx >= 0) {
                    let subIdx = state.wbsDevices.map(device => device.id_parent).lastIndexOf(state.wbsDevices[idx].id_parent)
                    if (subIdx >= 0) {
                        state.wbsDevices.splice(subIdx, 0, newDevice)
                    } else {
                        state.wbsDevices.unshift(newDevice);
                    }
                } else {
                    state.wbsDevices.unshift(newDevice);
                }
            } else {
                state.wbsDevices.splice(idx, 0, newDevice);
            }
        },
        handleAddBhaEquip(state, newEquip) {
            state.bhaDevices.push(newEquip)
        },
        updateDevice(state, { equipId, app, ...args }) {
            if (app === 'WBS') {
                let idx = state.wbsDevices.map(device => device.id).indexOf(equipId)
                let updateDevice = { ...state.wbsDevices[idx] }
                updateDevice = Object.assign(updateDevice, args)
                state.wbsDevices.splice(idx, 1, updateDevice)
                if (Object.keys(args).indexOf('id_parent') >= 0) {
                    eventManager.emit('relayout')
                }
            } else if (app === 'BHA') {
                let idx = state.bhaDevices.map(device => device.id).indexOf(equipId)
                let updateDevice = { ...state.bhaDevices[idx] }
                updateDevice = Object.assign(updateDevice, args)
                state.bhaDevices.splice(idx, 1, updateDevice)
            }
        },
        handleEditProperties(state, { app, devices }) {
            if (app === 'WBS') {
                state.wbsDevices = state.wbsDevices.map(device => {
                    let idx = devices.map(d => d.id).indexOf(device.id)
                    if (idx >= 0) {
                        device.Properties = devices[idx].Properties
                        if (device.Properties.find(prop => prop.name === 'top')) {
                            eventManager.emit('relayout')
                        }
                    }
                    return device;
                })
            } else if (app === 'BHA') {
                state.bhaDevices = state.bhaDevices.map(device => {
                    let idx = devices.map(d => d.id).indexOf(device.id)
                    if (idx >= 0) {
                        device.Properties = devices[idx].Properties
                    }
                    return device;
                })
            }
        },
        handleAddProperty(state, { equipId, property, app }) {
            if (app === 'WBS') {
                let idx = state.wbsDevices.map(device => device.id).indexOf(equipId)
                // let updateDevice = Object.create(state.wbsDevices[idx])
                let updateDevice = { ...state.wbsDevices[idx] }
                updateDevice.Properties.push(property)
                state.wbsDevices.splice(idx, 1, updateDevice)
            } else if (app === 'BHA') {
                let idx = state.bhaDevices.map(device => device.id).indexOf(equipId)
                let updateDevice = { ...state.bhaDevices[idx] }
                updateDevice.Properties.push(property)
                state.bhaDevices.splice(idx, 1, updateDevice)
            }
        },
        handleDeleteProperties(state, { listPropId, equipId, app }) {
            if (app === 'WBS') {
                let idx = state.wbsDevices.map(device => device.id).indexOf(equipId)
                let updateDevice = { ...state.wbsDevices[idx] }
                updateDevice.Properties = updateDevice.Properties.filter(prop => {
                    return listPropId.indexOf(prop.id) < 0;
                })
                state.wbsDevices.splice(idx, 1, updateDevice)
            } else if (app === 'BHA') {
                let idx = state.bhaDevices.map(device => device.id).indexOf(equipId)
                let updateDevice = { ...state.bhaDevices[idx] }
                updateDevice.Properties = updateDevice.Properties.filter(prop => {
                    return listPropId.indexOf(prop.id) < 0;
                })
                state.bhaDevices.splice(idx, 1, updateDevice)
            }
        },
        setZoomFactor(state, { value }) {
            state.well.well_data.zoomFactor = Number(value);
            state.well.well_data.zoom = 1;
        },
        handleZoom(state, args) {
            state.well.well_data.zoomBHA = args.zoomBHA || state.well.well_data.zoomBHA || 1;
            state.well.well_data.zoomWBS = args.zoomWBS || state.well.well_data.zoomWBS || 1;
            state.well.well_data.zoomFactor = args.zoomFactor || 1.2;
        },
        handleMove(state, bhaEquipments) {
            state.bhaDevices = bhaEquipments
        },
        updateWellInfo(state, { well }) {
            state.well.well_data = well.well_data;
            // state.well = well
        },
        editWell(state, { well }) {
            state.well = well
        }
    },
    actions: {
        async getWells(context) {
            try {
                const response = await getWells('/');
                context.commit('setWells', response.data);
            } catch (error) {
                console.error(error);
            }

        },
        async deleteWell(context, wellId) {
            let message = null;
            let type = null;
            try {
                await deleteWell(`/well/${wellId}`);
                const idx = context.state.wells.map(well => well.id).indexOf(wellId);
                if (idx >= 0) {
                    const well = context.state.wells[idx];
                    if (context.state.well && well.id === context.state.well.id) {
                        context.state.well = null;
                    }
                    context.state.wells.splice(idx, 1);
                    type = "success"
                }
            } catch (error) {
                console.error(error);
                type = "error"
            } finally {
                if (type === 'success') {
                    message = "Delete done!";
                } else {
                    message = "Error: delete error!";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000)
            }

        },
        async cloneWell(context, { name, id_well }) {
            let message = null;
            let type = null;
            try {
                await cloneWell(`/well/clone`, { name, id_well })
            } catch (error) {
                console.log(error)
                message = error.response.data.message;
                type = "error"
            } finally {
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000)
            }
        },
        async editWell(context, { id, name, well_data }) {
            let message = null;
            let type = null;

            try {
                const well = context.state.wells.filter(well => well.id === id)[0];
                const response = await editWell(`/well/${id}`, { name, well_data });
                const wellEdited = response.data;
                well.name = wellEdited.name;
                well.well_data = wellEdited.well_data;
                type = "success"
            } catch (error) {
                console.error(error);
                type = "error";
            } finally {
                if (type === 'success') {
                    message = "Edit done!";
                } else {
                    let check = context.state.wells.find(well => well.name === name);
                    message = check ? `Error: ${name} already exists!` : "Error: edit error!";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000)
            }
        },
        async editWellOptions(context, { optionName, wbsShowOptions, bhaShowOptions }) {
            let message = null;
            let type = null;
            try {
                let wellId = context.state.well.id
                let response
                if (optionName === 'WBS') {
                    response = await service.put(`/well/${wellId}`, { wbsShowOptions })
                } else {
                    response = await service.put(`/well/${wellId}`, { bhaShowOptions })
                }
                let well = response.data
                context.commit('editWell', { well })
                type = "success"
            } catch (error) {
                console.error(error);
                type = "error";
            } finally {
                if (type === 'success') {
                    message = "Edit done!";
                } else {
                    let check = context.state.wells.find(well => well.name === name);
                    message = check ? `Error: ${name} already exists!` : "Error: edit error!";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000)
            }
        },
        async createWell(context, { name, well_data, wbsShowOptions, bhaShowOptions }) {
            let message = null;
            let type = null;
            try {
                const response = await createWell('/well', { name, well_data, wbsShowOptions, bhaShowOptions });
                context.state.wells.push(response.data);
                type = 'success';
            } catch (error) {
                console.log(error);
                type = 'error';
            } finally {
                if (type === 'success') {
                    message = "Create successfully " + name;
                } else {
                    let check = context.state.wells.find(well => well.name === name);
                    message = check ? `Error: ${name} already exists!` : "Error: create error!";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000)
            }
        },
        async upsertWell(context, { name, well_data, wbsShowOptions, bhaShowOptions }) {
            let message = null;
            let type = null;
            try {
                await createWell('/well/upsert', { name, well_data, wbsShowOptions, bhaShowOptions });
                await context.dispatch('getWells');
                type = 'success';
            } catch (error) {
                console.log(error);
                type = 'error';
            } finally {
                if (type === 'error') {
                    message = "Error: create error!";
                    context.commit('setMessage', { message, type });
                    setTimeout(() => {
                        message = null;
                        context.commit('setMessage', { message, type });
                    }, 4000)
                }
            }
        },
        async getWell(context, wellId) {
            let message = null;
            let type = null;
            try {
                let response = await getWbsDevices(`/wbs/${wellId}`);
                let wbsDevices = response.data;
                for (let d of wbsDevices) {
                    for (let p of d.Properties) {
                        if (p.name === "startTime" || p.name === "stopTime") {
                            p.value = p.value ? new Date(p.value) : null;
                        }
                    }
                }
                response = await getBhaDevices(`bha/${wellId}`)
                let bhaDevices = response.data
                context.commit('setWell', {
                    data: {
                        wellId,
                        wbsDevices,
                        bhaDevices
                    }
                })
            } catch (error) {
                console.error(error);
                type = 'error';
            } finally {
                if (type === 'error') {
                    message = "Error: Fail to get well info"
                    context.commit('setMessage', { message, type });
                    setTimeout(() => {
                        message = null;
                        context.commit('setMessage', { message, type });
                    }, 4000);
                }
            }
        },
        async addWbsEquip(context, { newEquip }) {
            const data = { ...newEquip }
            data['app'] = "WBS"
            data['id_well'] = context.state.well.id;
            let casing = context.state.wbsDevices
                .filter(device => device.id_parent === data.id_parent && device.type === 'Casing')[0]
            if (casing) {
                let hasCrossOver = casing.Properties.filter(item => {
                    return item.name === 'hasCrossOver'
                })[0]
                if (hasCrossOver && hasCrossOver.value == 1) {
                    data.properties.hasCrossOver = true
                }
            }
            let message = null;
            let type = null;
            try {
                const response = await addWbsDevice('/equip', data)
                console.log(response.data);
                context.commit('handleAddWbsEquip', response.data);
                type = 'success'
            } catch (error) {
                let data = error.response && error.response.data
                console.log(error)
                if (data) {
                    message = data.message
                }
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to add Wbs equipment";
                    }
                } else {
                    message = "Add new Wbs device successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async addBhaEquip(context, { newEquip }) {
            let data = { ...newEquip }
            data['app'] = "BHA"
            data['id_well'] = context.state.well.id;
            let message = null;
            let type = null;
            try {
                const response = await addBhaDevice('/equip', data)
                console.log(response.data);
                context.commit('handleAddBhaEquip', response.data)
                type = 'success'
            } catch (error) {
                let data = error.response && error.response.data
                if (data) {
                    message = data.message
                }
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to add Bha equipment";
                    }
                } else {
                    message = "Add new Bha device successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async deleteWbsEquip(context, { equipId }) {
            let message = null;
            let type = null;
            try {
                let response = await deleteDevice(`/equip/${equipId}`);
                console.log(response.data);
                type = 'success'
                let { ids } = response.data
                context.commit('handleDeleteWbsEquip', { ids })
                eventManager.emit('delete-device', { id: equipId })
            } catch (error) {
                console.error(error.response.data);
                type = 'error';
            } finally {
                if (type === 'error') {
                    message = "Error: Fail to delete Wbs equipment";
                } else {
                    message = 'Delete successfully';
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async deleteBhaEquip(context, { equipId }) {
            let message = null;
            let type = null;
            try {
                let response = await deleteDevice(`/equip/${equipId}`);
                console.log(response.data);
                type = 'success'
                let { ids } = response.data
                context.commit('handleDeleteBhaEquip', { ids })
                eventManager.emit('delete-device', { id: equipId })
            } catch (error) {
                console.error(error.response.data);
                type = 'error';
            } finally {
                if (type === 'error') {
                    message = "Error: Fail to delete Bha equipment";
                } else {
                    message = 'Delete successfully';
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async editEquip(context, { equipId, app, ...args }) {
            let message = null;
            let type = null;
            try {
                console.log("args", args)
                let response = await service.put(`/equip/${equipId}`, {
                    ...args
                });
                console.log('response', response.data);
                type = 'success'
                context.commit('updateDevice', { equipId, app, ...args })
            } catch (error) {
                console.log(error.response.data)
                message = error.response.data && error.response.data.message
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to edit equipment";
                    }
                } else {
                    message = 'Edit equipment successfully';
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async editProperties(context, { app, properties }) {
            let message = null;
            let type = null;
            try {
                const response = await service.put(`/prop/list`, {
                    properties: properties
                });
                let devices = response.data
                type = 'success';
                context.commit('handleEditProperties', { app, devices });
            } catch (error) {
                console.log(error)
                console.error(error.response.data);
                message = error.response.data.message;
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to edit equipment";
                    }
                } else {
                    message = "Edit equipment's properties successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async addProperty(context, { equipId, name, value, unit, app }) {
            let message = null;
            let type = null;
            try {
                const response = await service.post(`/prop`, {
                    id_equipment: equipId,
                    name,
                    value,
                    unit
                });
                console.log(response.data)
                type = 'success';
                context.commit('handleAddProperty', {
                    property: response.data,
                    equipId,
                    app
                });
            } catch (error) {
                console.error(error.response.data);
                message = error.response.data.message;
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to add property";
                    }
                } else {
                    message = "Add new property successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async deleteProperties(context, { equipId, app, properties }) {
            let message = null;
            let type = null;
            let device, listPropId = []
            if (app == "WBS") {
                device = context.state.wbsDevices.find(device => device.id === equipId)
                for (const prop of properties) {
                    listPropId.push(device.Properties.filter(p => p.name === prop)[0].id)
                }
            } else if (app === "BHA") {
                device = context.state.bhaDevices.find(device => device.id === equipId)
                for (const prop of properties) {
                    listPropId.push(device.Properties.filter(p => p.name === prop)[0].id)
                }
            }
            try {
                const response = await service.post(`/prop/deleteList`, { listPropId });
                console.log(response.data)
                type = 'success';
                context.commit('handleDeleteProperties', {
                    listPropId,
                    equipId,
                    app
                });
            } catch (error) {
                console.error(error.response.data);
                message = error.response.data.message;
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to delete properties";
                    }
                } else {
                    message = "Delete properties successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },
        async zoomWbs(context, { flag, app }) {
            let zoom, zoomFactor;
            let key = 'zoom' + app;

            switch (flag) {
                case "zoomIn":
                    zoom = context.state.well.well_data[key] * context.state.well.well_data.zoomFactor;
                    break;
                case "zoomOut":
                    zoom = context.state.well.well_data[key] / context.state.well.well_data.zoomFactor;
                    break;
                case 'reset':
                    zoom = 1;
                    zoomFactor = 1.2;
            }
            if (!zoom) {
                zoom = 1;
            }
            if (!zoomFactor) {
                zoomFactor = 1.2;
            }

            try {
                await service.put(`/well/${context.state.well.id}`, {
                    well_data: {
                        ...context.state.well.well_data,
                        [key]: zoom,
                        zoomFactor
                    }
                })
                context.commit('handleZoom', {
                    [key]: zoom, zoomFactor
                })
            } catch (error) {
                console.error(error);
            }
        },
        async moveBhaEquip(context, { idEquip, move }) {
            try {
                await service.put(`/equip/${idEquip}`, {
                    move: move
                })

                let idWell = context.state.well.id
                let response = await service.get(`bha/${idWell}`)
                let bhaEquipments = response.data
                context.commit('handleMove', bhaEquipments)
            } catch (err) {
                console.log(err)
            }
        },
        async editWellInfo(context, { wbs, bha }) {
            let message = null;
            let type = null;
            try {
                let well_data = {
                    ...context.state.well.well_data
                }
                if (wbs) well_data.wbs = wbs;
                if (bha) well_data.bha = bha;
                const response = await service.put(`/well/${context.state.well.id}`, {
                    well_data
                })
                let well = response.data
                type = 'success'
                context.commit('updateWellInfo', { well })
            } catch (error) {
                console.error(error.response.data);
                message = error.response.data.message;
                type = 'error';
            } finally {
                if (type === 'error') {
                    if (!message) {
                        message = "Error: Fail to edit information";
                    }
                } else {
                    message = "Edit information successfully";
                }
                context.commit('setMessage', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessage', { message, type });
                }, 4000);
            }
        },

    }
}

export default well;
