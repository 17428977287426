<template>
  <md-dialog :md-active.sync="open" :md-click-outside-to-close="false" :md-close-on-esc="false">
      <md-dialog-title>Diagram Options</md-dialog-title>
      <md-dialog-content>
        <md-checkbox v-model="selectAll">
          <label style="font-size: 18px;">Select all</label>
        </md-checkbox>
        <fieldset>
          <legend>Select properties for show</legend>
          <div class="option-list">
            <md-checkbox v-for="key in Object.keys(showOptions)" :key="key"
              v-model="showOptions[key]">
              <label>{{getLabel(key)}}</label>
            </md-checkbox>
          </div>
        </fieldset>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose">Cancel</md-button>
        <md-button class="md-primary" @click="handleSave">Ok</md-button>
      </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { wbsDefaultPriorityShowOptions } from '../utils/defaultPriority'
import getLabel from "../mixins/getLabel";

export default {
  name: 'WbsOptionDialog',
  props: ['open', 'onClose'],
  data() {
    return {
      selectAll: false,
      showOptions: {
        annotationShow: false,
        depthAxisShow: false,
        startTime: false,
        stopTime: false,
        OD: false,
        ID: false,
        length: false,
        weight: false,
        top: false,
        bottom: false,
        topMd: false,
        bottomMd: false,
        note: false ,
        comment: false,
        grade: false,
        connection: false,
        PPFG: false,
        MW: false,
        mudType: false,
        drillingRotation:false,
        flowRate: false,
        ROP: false,
        holeSelection: false
      }
    }
  },
  computed: {
    ...mapState({
      wbsShowOptions: (state) => JSON.parse((state.well.well || {}).wbsShowOptions || '{}') || wbsDefaultPriorityShowOptions
    }),
    defaultPriority() {
      return wbsDefaultPriorityShowOptions
    }
  },
  mounted() {
    for (const prop of Object.keys(this.wbsShowOptions)) {
      this.showOptions[prop] = this.wbsShowOptions[prop] > -1
    }
    this.selectAll = this.isSelectAll();
  },
  methods: {
    handleSave() {
      let obj = {}
      for (const key in this.showOptions) {
        if (this.showOptions[key]) {
          obj[key] = this.defaultPriority[key]
        }
      }
      this.$store.dispatch("editWellOptions", {optionName: 'WBS', wbsShowOptions: JSON.stringify(obj)})
      this.onClose()
    },
    isSelectAll() {
      for (const key in this.showOptions) {
        if (!this.showOptions[key]) return false;
      }
      return true;
    }
  },
  watch: {
    selectAll(val) {
      if (val) {
        for (const key in this.showOptions) {
          this.showOptions[key] = true;
        }
      }
      else {
        for (const prop in this.showOptions) {
          this.showOptions[prop] = false;
        }
      }
    }
  },
  mixins: [getLabel]
}
</script>

<style lang="scss" scoped>
  .md-dialog ::v-deep .md-dialog-container {
    width: 600px;
  }
  .md-dialog-title {
    margin-bottom: 0;
  }
  fieldset {
    max-width: 560px;
  }
  legend {
    font-size: 16px;
  }
  .option-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    max-height: 370px;
    > div {
      width: 45%
    }
  }
  label {
    text-transform: capitalize;
  }
</style>
