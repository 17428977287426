var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
  position: 'absolute',
  top: _vm.cOffsetTop + 'px',
  left: _vm._offsetLeft + 'px',
  height: _vm.cHeight + 'px',
  width: 'calc(100% - ' + 2*_vm._offsetLeft+ 'px)',
  display: 'flex',
  justifyContent: 'space-between'
}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id})}}},[_c('svg',{class:{Centralizer: true},attrs:{"height":_vm.cHeight,"width":_vm.cWidth,"version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":_vm.cWidth * 3/2,"cy":_vm.cWidth,"r":_vm.cWidth}})]),_c('svg',{class:{Centralizer: true},attrs:{"height":_vm.cHeight,"width":_vm.cWidth,"version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":-_vm.cWidth/2,"cy":_vm.cWidth,"r":_vm.cWidth}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }