<template>
	<div class="container" 
		@click="$emit('select', {id: options.id});"
		:style="{
			top: cOffsetTop + 'px',
			left: offsetLeft + 'px',
			width: 'calc(100% - ' + 2*offsetLeft +  'px)',
			zIndex: 20,
		}">
		<div :class="{'outlined': selectedDeviceId === options.id || isSelected}"> 
			<div class="shape triangle">
			</div>
			<div class="shape circle">
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import top from '../mixins/top';
import offsetLeft from '../mixins/offsetLeft';
import selection from '../mixins/selection';
import {mapGetters} from 'vuex';

	export default {
		name: "ControlLine",
		props: ['options', 'selectedDeviceId'],
		computed: {
			...mapGetters({
				convertLength: 'convertLength',
				isAboveCrossOver: 'isAboveCrossOver', 
				getNumOfParent: 'getNumOfParent',
				mdMode: 'showInMd'
			}),
			cHeight() {
				return 50;
			},
			offsetLeft() {
				let top
				if (this.mdMode) {
					top = this.options.Properties.find(prop => prop.name === 'topMd').value
				} else {
					top = this.options.Properties.find(prop => prop.name === 'top').value
				}
				if (this.isAboveCrossOver(this.options.id_parent, top)) {
					return this.cOffsetLeft - this.cGapWidth;
				}
				return this.cOffsetLeft  - this.cGapWidth;
			},
			cNumOfParent() {
				return this.getNumOfParent(this.options.id)
			}
		},
		mixins: [gapWidth, offsetLeft, selection, top],
	}
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	justify-content: flex-start;
	position: absolute;
	cursor: pointer;
}
.container > div {
	min-width: 45px;
	transform: translateX(90%)
}
.shape {
	display: inline-block;
}
.circle {
	width: 20px;
	height: 20px;
	background-color: #ff8;
	border: 1px solid black;
	border-radius: 50%;
	position: relative;
	right: 5px;
}
.circle > span {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 400%;
	transform: translate(-50%, -50%);
}
.triangle {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 20px solid #888;
	border-left: 0px solid transparent;
	box-sizing: content-box;
}
</style>