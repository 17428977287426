import { mapGetters } from "vuex"

export default {
	computed: {
		...mapGetters({
			mdMode: 'showInMd'
		}),
		cOffsetBottom() {
			if (this.mdMode) {
				let bottomMd = this.options.Properties.find(prop => prop.name === 'bottomMd')
				if (bottomMd) {
					return this.convertLength(bottomMd.value)
				}
			}
			let top = this.options.Properties.find(prop => prop.name === '').value
      return this.convertLength(top)
		},
		cBottom() {
			if (this.mdMode) {
				let bottomMd = this.options.Properties.find(prop => prop.name === 'bottomMd')
				if (bottomMd) {
					return bottomMd.value
				}
			}
			let bottom = this.options.Properties.find(prop => prop.name === 'bottom')
			if (bottom) {
				return bottom.value
			}
			return this.options.bottom
		}
	}
}