var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"casingRef",staticClass:"Casing"},[(!_vm.hasCrossOver)?_c('tubing-internal',{attrs:{"height":_vm.cHeight,"options":_vm.options,"border-spec":_vm.cBorderSpec,"id":_vm.cId},on:{"select":function($event){return _vm.$emit('select', $event);}}},[_vm._t("default"),(_vm.cHasBending)?_c('div',{staticClass:"Bending",style:({
        top: _vm.cOffsetBending + 'px',
        zIndex: 1000
      })},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.cBendingPos)+"(m)")]),_c('div'),_c('span',[_vm._v(_vm._s(_vm.cDeviationAngle)+"°,"+_vm._s(_vm.cAzimuthAngle)+"°")])])]):_vm._e()],2):_vm._e(),(_vm.hasCrossOver)?_c('div',[(_vm.crossOverPos)?_c('tubing-internal',{attrs:{"height":_vm.crossOverPos,"options":_vm.options,"id":_vm.cId,"border-spec":_vm.cUpperBorderSpec},on:{"select":function($event){return _vm.$emit('select', $event);}}},[(_vm.cHasBending && _vm.cBendingPos <= _vm.crossOverPos)?_c('div',{staticClass:"Bending",style:({
        top: _vm.cOffsetBending  + 'px',
        zIndex: 1000
      })},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.cBendingPos)+"(m)")]),_c('div'),_c('span',[_vm._v(_vm._s(_vm.cDeviationAngle)+"°,"+_vm._s(_vm.cAzimuthAngle)+"°")])])]):_vm._e()]):_vm._e(),_c('Crossover',{attrs:{"fill":_vm.cCrossOverFill,"id":_vm.cId,"casing-type":_vm.cActivityType}}),_c('tubing-internal',{attrs:{"height":_vm.cHeight - _vm.crossOverPos - 10,"width":(_vm.crossOverCasingWidth) * 100,"options":_vm.options,"is-below-cross-over":true,"id":_vm.cId,"border-spec":_vm.cBelowBorderSpec},on:{"select":function($event){return _vm.$emit('select', $event);}}},[_vm._t("default"),(_vm.cHasBending && _vm.cBendingPos > _vm.crossOverPos)?_c('div',{staticClass:"Bending",style:({
        top: _vm.cOffsetBending - _vm.crossOverPos  + 'px',
        zIndex: 1000
      })},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.cBendingPos)+"(m)")]),_c('div'),_c('span',[_vm._v(_vm._s(_vm.cDeviationAngle)+"°,"+_vm._s(_vm.cAzimuthAngle)+"°")])])]):_vm._e()],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }