<template>
  <div
    class="bha-device"
    ref="bhaDevice"
    v-resize:throttle="onResize"
    @mousedown="handleRightClick"
  >
    <img
      :src="this.imageSrc"
      :width="cBhaImageSize * scale"
      @click="$emit('select', { id })"
    />
    <span
      v-if="isSelected || selectedDeviceId === id"
      class="border outlined"
      :style="{
        left: posOutlined + 'px',
        right: posOutlined + 'px',
        cursor: 'pointer',
      }"
      @click="$emit('select', { id })"
    ></span>
    <div
      ref="title"
      :style="
        idx % 2
          ? {
              position: 'absolute',
              top: hAlign - hTitle / 2 + 'px',
              right: posAlign + 'px',
              zIndex: '10',
            }
          : {
              position: 'absolute',
              top: hAlign - hTitle / 2 + 'px',
              left: posAlign + 'px',
              zIndex: '10',
            }
      "
    >
      <div style="display: flex;">
        <div
          :style="{
            width: wTitle + 'px',
            textAlign: 'left',
            fontSize: calcFontSize + 'px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }"
        >
          <span
            :style="{
              zIndex: 100,
              backgroundColor: '#FFF',
            }"
          >
            <strong>{{ idx + 1 }}.</strong> {{ name }}
          </span>
          <!-- <div style="margin-top: 15px;">{{getDeviceById('BHA', id).bha_device.device_name}}</div> -->
          <!--<div style="margin-top: 15px;">Scale Factor: {{ scale }}</div>-->
        </div>
        <!-- <md-button class="md-dense md-icon-button" @click="deleteActive = true">
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="top">Delete</md-tooltip>
        </md-button> -->
      </div>
    </div>

    <div
      :class="{ outlined: isSelected || selectedDeviceId === id }"
      :style="
        idx % 2
          ? {
              position: 'absolute',
              borderBottom: '1px solid gray',
              width: wLine + 'px',
              top: hAlign + 'px',
              right: posAlign + 'px',
              zIndex: '-1',
            }
          : {
              position: 'absolute',
              borderBottom: '1px solid gray',
              width: wLine + 'px',
              top: hAlign + 'px',
              left: posAlign + 'px',
              zIndex: '-1',
            }
      "
    ></div>

    <md-menu
      md-direction="top-start"
      md-size="small"
      :md-active.sync="menuActive"
    >
      <md-menu-content>
        <md-menu-item @click="$emit('detail', { id })">
          <span>Detail</span>
        </md-menu-item>
        <md-menu-item @click="$emit('copy', { id })">
          <span>Copy</span>
        </md-menu-item>
        <md-menu-item @click="deleteActive = true">
          <span style="color: red">Delete</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>

    <!-- end -->
    <md-dialog-confirm
      :md-active.sync="deleteActive"
      md-content="<strong>Do you want to delete this equipment ?</strong>"
      md-confirm-text="Delete"
      md-cancel-text="Cancel"
      @md-cancel="onCancelDelete"
      @md-confirm="onConfirmDelete"
    />
  </div>
</template>

<script>
import selection from "../mixins/selection";
import { mapGetters } from "vuex";
import resize from "vue-resize-directive";

export default {
  name: "BhaDevice",
  props: ["imageSrc", "id", "name", "idx", "scale", "selectedDeviceId"],
  mixins: [selection],
  directives: {
    resize,
  },
  data() {
    return {
      menuActive: false,
      deleteActive: false,
      posAlign: 0,
      wLine: 0,
      hAlign: 0,
      wTitle: (this.$refs.title || {}).clientHeight || 200,
      hTitle: 0,
      posOutlined: 0,
    };
  },
  computed: {
    ...mapGetters({
      cBhaImageSize: "cBhaImageSize",
      getDeviceById: "getDeviceById",
    }),
    calcFontSize() {
      let fontSize = this.cBhaImageSize / 5.7;

      if (fontSize <= 12) fontSize = 12;
      if (fontSize >= 24) fontSize = 24;
      return fontSize;
    },
  },
  methods: {
    onCancelDelete() {
      this.deleteActive = false;
    },
    onConfirmDelete() {
      this.deleteActive = false;
      this.$store.dispatch("deleteBhaEquip", { equipId: this.id });
    },
    onResize() {
      let wImg = this.cBhaImageSize * this.scale;
      let hImg = (this.$refs.bhaDevice || {}).clientHeight || 0;
      let calcResize =
        ((this.$refs.bhaDevice || {}).clientWidth || 1000 - wImg) / 2;
      this.hTitle = (this.$refs.title || {}).clientHeight || 100;
      this.wTitle = calcResize * 0.3;
      this.posAlign = calcResize * 0.5;
      this.wLine = calcResize * 0.5 - wImg / 2;
      this.hAlign = hImg * 0.5;
      this.posOutlined = calcResize - wImg / 2;
    },
    handleRightClick(event) {
      // event.preventDefault();
      if (event.which === 3) {
        this.menuActive = !this.menuActive;
      } else {
        this.menuActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bha-device {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    cursor: pointer;
  }
  .border {
    position: absolute;
    top: 0;
    bottom: 0;
    background: none;
  }

  .outlined {
    z-index: 0 !important;
  }
}
</style>
