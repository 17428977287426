export default {
  methods: {
    getLabel(property) {
      switch (property) {
        case "activityType":
          return "Activity Type";
        case "startTime":
          return "Start time";
        case "stopTime":
          return "Stop time";
        case "hasCrossOver":
          return "Has CrossOver";
        case "stickToTop":
          return "Stick To Top";
        case "hasBending":
          return "Has Bending";
        case "bendingPos":
          return "Bending Position (md)";
        case "deviationAngle":
          return "Inclination Angle";
        case "azimuthAngle":
          return "Azimuth Angle";
        case "crossOverPos":
          return "CrossOver Position (md)";
        case "cumWeight":
          return "Weight";
        case "annotationShow":
          return "Annotation";
        case "depthAxisShow":
          return "Depth Axis";
        case "top":
          return "Top TVD";
        case "bottom":
          return "Bottom TVD";
        case "topMd":
          return "Top MD";
        case "bottomMd":
          return "Bottom MD";
        case "mudType":
          return "Mud Type";
        case "PPFG":
          return "PPFG";
        case "MW":
          return "MW ";
        case "drillingRotation":
          return "Drilling Rotation";
        case "flowRate":
          return "Flow Rate";
        case "ROP":
          return "ROP";
        case "holeSelection":
          return "Hole Selection";
        default:
          return property;
      }
    },
  },
};
