var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BorderSpecAdjustment"},[_c('div',{staticClass:"header"},[_c('p',{attrs:{"for":""}},[_vm._v("Cement Intervals")]),_c('md-button',{staticClass:"md-icon-button",on:{"click":_vm.handleAdd}},[_c('md-icon',[_vm._v("add")])],1)],1),_c('div',{staticClass:"container"},_vm._l((_vm.cFill),function(spec,idx){return _c('div',{key:idx,staticClass:"body"},[_c('md-field',[_c('label',[_vm._v("From(md)")]),_c('md-input',{staticStyle:{"overflow":"hidden","margin-right":"6px"},attrs:{"disabled":""},model:{value:(spec.from),callback:function ($$v) {_vm.$set(spec, "from", $$v)},expression:"spec.from"}})],1),_c('md-field',{key:_vm.mdField_key,class:{
          'md-invalid':
            spec.to < spec.from || spec.to > _vm.bottom ? true : false,
        }},[_c('label',[_vm._v("To(md)")]),_c('md-input',{staticStyle:{"overflow":"hidden","margin-right":"6px"},on:{"change":(e) => {
              _vm.handleChange();
              _vm.handleInterval(spec.from, e, idx, _vm.cFill);
            }},model:{value:(spec.to),callback:function ($$v) {_vm.$set(spec, "to", $$v)},expression:"spec.to"}}),_c('span',{staticClass:"md-error"},[_vm._v("Value must be in range ["+_vm._s(spec.from)+", "+_vm._s(_vm.bottom)+"]")])],1),_c('md-field',[_c('label',[_vm._v("Cement Quality")]),_c('md-select',{staticClass:"cement",class:{
            'cement-none': (spec || {}).fill === 'none',
            'cement-good': (spec || {}).fill === 'good',
            'cement-normal': (spec || {}).fill === 'normal',
            'cement-bad': (spec || {}).fill === 'bad',
            'cement-lead': (spec || {}).fill === 'lead',
            'cement-tail': (spec || {}).fill === 'tail',
            'cement-squeeze': (spec || {}).fill === 'squeeze',
          },attrs:{"name":"borderSpec"},on:{"md-selected":(val) => _vm.handleFill(val, idx)},model:{value:(spec.fill),callback:function ($$v) {_vm.$set(spec, "fill", $$v)},expression:"spec.fill"}},[_c('md-option',{attrs:{"value":'none'}},[_c('div',{staticClass:"cement-none"},[_vm._v("None")])]),_c('md-option',{attrs:{"value":'good'}},[_c('div',{staticClass:"cement-good"},[_vm._v("Good")])]),_c('md-option',{attrs:{"value":'normal'}},[_c('div',{staticClass:"cement-normal"},[_vm._v("Normal")])]),_c('md-option',{attrs:{"value":'bad'}},[_c('div',{staticClass:"cement-bad"},[_vm._v("Bad")])]),_c('md-option',{attrs:{"value":'lead'}},[_c('div',{staticClass:"cement-lead"},[_vm._v("Lead")])]),_c('md-option',{attrs:{"value":'tail'}},[_c('div',{staticClass:"cement-tail"},[_vm._v("Tail")])]),_c('md-option',{attrs:{"value":'squeeze'}},[_c('div',{staticClass:"cement-squeeze"},[_vm._v("Squeeze")])])],1)],1),_c('a',{staticClass:"icon",on:{"click":function($event){return _vm.handleDelete(idx)}}},[_vm._v(" Remove ")])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }