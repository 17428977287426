<template>
  <div :style="{ width: myWidth }" class="WbsDeviceInfo shadow-border">
    <md-empty-state v-if="!equipId" md-icon="code_off"> </md-empty-state>
    <div v-show="equipId" class="WbsDeviceInfoMain">
      <div class="info-header">
        <h3 @click="handleClick">
          {{ cName || "Properties" }}
        </h3>
        <div class="v-toolbar">
          <md-button
            class="md-dense md-icon-button"
            @click="resetProperty"
            :disabled="Object.keys(cPropertyChange).length === 0"
          >
            <md-tooltip md-direction="top">Reset properties</md-tooltip>
            <md-icon>autorenew</md-icon>
          </md-button>
          <md-button @click="hide" class="md-dense md-icon-button">
            <md-tooltip md-direction="top">Hide</md-tooltip>
            <md-icon>visibility_off</md-icon>
          </md-button>
          <!-- <md-button
            class="md-dense md-icon-button"
            @click="addPropDialogActive = true"
          >
            <md-icon>add</md-icon>
            <md-tooltip md-direction="top">Add new property</md-tooltip>
          </md-button> -->
          <md-button
            class="md-dense md-icon-button"
            @click="deleteActive = true"
          >
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top">Delete</md-tooltip>
          </md-button>
          <!-- <md-button
            class="md-dense md-icon-button"
            @click="deletePropDialogShow = true"
          >
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top"
              >Choose to delete properties</md-tooltip
            >
          </md-button> -->
          <md-button
            class="md-dense md-primary"
            @click="save"
            :disabled="Object.keys(cPropertyChange).length <= 0 
              || (cDevice.id_equipment_type === 1 && (crossOverPosError.length > 0 || bendingPosError.length > 0))"
            >OK</md-button
          >
        </div>
      </div>
      <div class="wbs-info-body">
        <div class="wbs-info-layout">
          <md-field class="parent-selection" v-if="cParentList.length">
            <dropdown-input
              name="parent"
              :key="reset"
              :options="cParentList"
              :label="'Parent'"
              :handleSelect="handleParent"
              :placeholder="cParentNameOfEquip"
              :isRequired="true"
            />
            <md-button
              class="clear-parent-btn"
              @click="
                () => {
                  properties.id_parent = null;
                }
              "
            >
              <md-icon>close</md-icon>
              <md-tooltip md-direction="top">Set parent to null</md-tooltip>
            </md-button>
          </md-field>
          <div v-for="(key, idx) in cSortKeys" :key="idx">
            <md-field v-if="key === 'activityType'">
              <label
                for="activityType"
                class="required-field"
                v-if="cDevice.id_equipment_type !== 1 && cDevice.id_equipment_type !== 7"
                >Activity Type</label
              >
              <dropdown-input
                v-if="cDevice.id_equipment_type === 1"
                :key="reset"
                name="activity_type"
                :options="cCasingList"
                :label="'Activity Type'"
                :handleSelect="handleActivityType"
                :placeholder="properties.activityType"
                :isRequired="true"
              />
              <dropdown-input
                v-else-if="cDevice.id_equipment_type === 7"
                :key="reset"
                name="activity_type"
                :options="cValveList"
                :label="'Activity Type'"
                :handleSelect="handleActivityType"
                :placeholder="properties.activityType"
                :isRequired="true"
              />
              <md-select
                v-model="properties.activityType"
                name="activityType"
                v-else
                id="activityType"
                :placeholder="properties.activityType"
                disabled
                required
              >
                <md-option :value="properties.activityType">{{
                  properties.activityType
                }}</md-option>
              </md-select>
            </md-field>
            <md-datepicker
              :md-immediately="true"
              v-else-if="key === 'stopTime' || key === 'startTime'"
              v-model="properties[key]"
              :id="key"
            >
              <label
                :for="key"
                :class="{ 'required-field': checkInRequireList(key) }"
              >
                {{ getLabel(key) }}{{ getPropertyUnit(key) }}
              </label>
            </md-datepicker>
            <md-checkbox
              v-else-if="
                key === 'hasCrossOver' ||
                  key === 'stickToTop' ||
                  key === 'hasBending'
              "
              v-model="properties[key]"
            >
              <label :class="{ 'required-field': checkInRequireList(key) }">{{
                getLabel(key)
              }}</label>
            </md-checkbox>
            <div v-else-if="key === 'crossOverPos'">
              <md-field>
                <label for="crossOverPos">{{ getLabel(key) }}</label>
                <md-input v-model="properties[key]" type="number"
                  id="crossOverPos"></md-input>
              </md-field>
              <p class="error-text" v-if="crossOverPosError.length > 0">{{crossOverPosError}}</p>
            </div>
            <div v-else-if="key === 'bendingPos'">
              <md-field>
                <label for="bendingPos">{{ getLabel(key) }}</label>
                <md-input v-model="properties[key]" type="number"
                  id="bendingPos"></md-input>
              </md-field>
              <p class="error-text" v-if="bendingPosError.length > 0">{{bendingPosError}}</p>
            </div>
            <md-field v-else-if="key === 'OD'">
              <dropdown-input
                name="OD"
                :key="reset"
                :options="cOdDropList"
                :label="'OD(in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.OD"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key === 'ID'">
              <dropdown-input
                name="ID"
                :key="reset"
                :options="cIdDropList"
                :label="'ID(in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.ID"
                :isRequired="false"
              />
            </md-field>
            <div style="margin: 4px 0 24px;" v-else-if="key === 'borderSpec'">
              <border-spec-adjustment
                :top="cBoderSpecTop"
                :bottom="cBoderSpecBottom"
                :borderSpecs="JSON.parse(properties.borderSpec || '[]')"
                :handleAdd="handleAddBorderSpec"
                :handleDelete="handleDeleteBorderSpec"
                :handleInterval="handleIntervalChange"
                :handleFill="handleFillChange"
              />
            </div>
            <md-field v-else-if="key === 'grade'">
              <dropdown-input
                name="grade"
                :key="reset"
                :options="grades"
                label="Grade"
                :handleSelect="handleSelect"
                :placeholder="properties.grade"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key === 'connection'">
              <dropdown-input
                name="connection"
                :key="reset"
                :options="connections"
                label="Connection"
                :handleSelect="handleSelect"
                :placeholder="properties.connection"
                :isRequired="false"
              />
            </md-field>
              <md-field v-else-if="key === 'mudType'">
              <dropdown-input
                name="mudType"
                :key="reset"
                :options="grades"
                label="Mud type"
                :handleSelect="handleSelect"
                :placeholder="properties.mudType"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else>
              <label :class="{ 'required-field': checkInRequireList(key) }">
                {{ getLabel(key) }} {{ getPropertyUnit(key) }}
              </label>
              <md-input
                v-model="properties[key]"
                :name="key"
              ></md-input>
            </md-field>
          </div>
        </div>
      </div>
      <div class="search-properties">
        <md-field>
          <md-input
            class="search"
            v-model="search"
            placeholder="Search properties"
          ></md-input>
        </md-field>
      </div>

      <delete-prop-dialog
        :properties="properties"
        :equip-id="equipId"
        app="WBS"
        :require-list="cRequireList"
        :show="deletePropDialogShow"
        :handle-close="closeDeletePropDialog"
      />

      <new-equip-property
        :show="addPropDialogActive"
        :handle-close="closeAddPropDialog"
        :equip-name="cName"
        :equip-id="equipId"
        app="WBS"
      />

      <md-dialog-confirm
        :md-active.sync="deleteActive"
        md-content="<strong>Delete this node will delete all of its children.</strong>"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancelDelete"
        @md-confirm="onConfirmDelete"
      />
    </div>
    <div
      style="width: 30px; cursor: pointer;"
      class="v-toolbar no-hover-gray"
      @click="onClose"
    >
      <!--<md-icon>navigate_before</md-icon>-->
    </div>
  </div>
</template>

<script>
import DeletePropDialog from "./DeletePropDialog.vue";
import NewEquipProperty from "./NewEquipProperty.vue";
import DropdownInput from "./DropdownInput.vue";
import BorderSpecAdjustment from "./BorderSpecAdjustment.vue";
import getLabel from "../mixins/getLabel";
import deviceInfo from "../mixins/deviceInfo";
import wbsDropList from "../mixins/wbsDroplist";
import casingDroplist from '../mixins/casingDroplist'
import { mapGetters, mapState } from "vuex";
import selection from "../mixins/selection";
import eventManager from "../eventManager";

export default {
  name: "WbsDeviceInfo",
  components: {
    "delete-prop-dialog": DeletePropDialog,
    "new-equip-property": NewEquipProperty,
    "dropdown-input": DropdownInput,
    "border-spec-adjustment": BorderSpecAdjustment,
  },
  mixins: [getLabel, deviceInfo, selection, wbsDropList, casingDroplist],
  computed: {
    ...mapGetters({
      getDeviceById: "getDeviceById",
      getDecendant: "getDecendant",
      mdMode: "showInMd",
    }),
    ...mapState({
      wbsDevices: (state) => state.well.wbsDevices,
    }),
    cId() {
      return this.equipId;
    },
    cDevice() {
      return this.getDeviceById("WBS", this.equipId);
    },
    cInformations() {
      if (!this.equipId) {
        return {};
      }
      let informations = {};
      if (this.cDevice) {
        for (const prop of this.cDevice.Properties) {
          if (prop.name === 'crossOverPos' || prop.name === 'bendingPos') {
            let top, bottom
            if (this.mdMode) {
              top = Number(this.cDevice.Properties.find(prop => prop.name === 'topMd').value)
              bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottomMd').value)
            } else {
              top = Number(this.cDevice.Properties.find(prop => prop.name === 'top').value)
              bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottom').value)
            }
            informations[prop.name] = Math.round((Number(prop.value) / 100) * (bottom - top)) + top
            continue
          }
          if (prop.name === "type") {
            if (prop.value === "Terminator") {
              informations["activityType"] = this.cDevice["terminatorType"];
            }
            continue;
          }
          if (prop.name !== "children") {
            informations[prop.name] = prop.value;
            continue;
          }
        }
        informations["name"] = this.cDevice["name"];
        informations["id_parent"] = this.cDevice["id_parent"];
      }
      if (informations.type === "Terminator") {
        informations.activityType = informations["terminatorType"];
        delete informations.hasCrossOver;
      }
      if (
        informations.hasCrossOver &&
        typeof informations.hasCrossOver != "boolean"
      ) {
        informations.hasCrossOver = informations.hasCrossOver === "1";
      }
      if (
        informations.stickToTop &&
        typeof informations.stickToTop != "boolean"
      ) {
        informations.stickToTop = informations.stickToTop === "1";
      }
      if (
        informations.hasBending &&
        typeof informations.hasBending != "boolean"
      ) {
        informations.hasBending = informations.hasBending === "1";
      }
      delete informations.terminatorType;
      return informations;
    },
    cPropertyChange() {
      let res = {};
      Object.keys(this.properties).forEach((key) => {
        if (this.cInformations[key] != this.properties[key]) {
          if (key === "startTime" || key === "stopTime") {
            let d1 = new Date(this.cInformations[key]).getTime();
            let d2 = new Date(this.properties[key]).getTime();
            if (d1 !== d2) {
              res[key] = this.properties[key];
            }
          } else {
            res[key] = this.properties[key];
          }
        }
      });
      if (Object.keys(res).indexOf('crossOverPos') >= 0) {
        let top, bottom;
        if (this.mdMode) {
          top = Number(this.properties.topMd)
          bottom = Number(this.properties.bottomMd)
        } else {
          top = Number(this.properties.top) 
          bottom = Number(this.properties.bottom)
        }
        res['crossOverPos'] = 100 *(res['crossOverPos'] - top) / (bottom - top)
      }
      if (Object.keys(res).indexOf('bendingPos') >= 0) {
        let top, bottom;
        if (this.mdMode) {
          top = this.properties.topMd
          bottom = this.properties.bottomMd
        } else {
          top = this.properties.top 
          bottom =this.properties.bottom
        }
        res['bendingPos'] = 100 *(res['bendingPos'] - top) / (bottom - top)
      }
      return res;
    },
    cSortKeys() {
      let firstPriorityOrders = ["name", "activityType"];
      let secondPriorityOrders = ["top", "bottom", "length", "topMd", "bottomMd"];
      return Object.keys(this.properties)
        .filter((prop) => {
          if (prop === "id_parent") {
            return false;
          }
          if (prop !== "type" && !this.search) {
            return true;
          }
          return (
            prop !== "type" &&
            prop
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        })
        .sort((a) => {
          if (firstPriorityOrders.indexOf(a) >= 0) {
            return -1;
          } else if (secondPriorityOrders.indexOf(a) >= 0) {
            return 0;
          } else {
            return 1;
          }
        });
    },
    cParentList() {
      let check = false;
      let myWbsDevices = [...this.wbsDevices].filter(
        (child) => child.type === "Casing" || child.type === "Parker"
      );
      let myId = this.equipId;
      while (!check) {
        let idx = myWbsDevices.findIndex(
          (device) => device.id === myId || device.id_parent === myId
        );
        if (idx >= 0) {
          check = false;
          myId = myWbsDevices[idx].id;
          myWbsDevices.splice(idx, 1);
        } else {
          check = true;
        }
      }
      // console.log(myWbsDevices)
      myWbsDevices = myWbsDevices.filter((device) => {
        if (device.id_equipment_type !== 4) {
          return true;
        }
        return !this.cDecendant.find((equip) => equip.id === device.id);
      });
      return myWbsDevices;
    },
    cParentNameOfEquip() {
      if (!this.properties.id_parent) {
        return "";
      } else if (this.properties.id_parent === "null") {
        return "NULL";
      } else {
        let idx = this.cParentList
          .map((device) => device.id)
          .indexOf(this.properties.id_parent);
        if (idx >= 0) {
          return this.cParentList[idx].name;
        }
      }
      return "";
    },
    cRequireList() {
      if (!this.cDevice) return {};
      let arr = ["name", "activityType", "type", "id_parent"];
      if (this.cDevice.type === "Casing") {
        arr.push(...["top", "bottom"]);
      }
      return arr;
    },
    cCasingList() {
      return [
        { name: " " },
        { name: "Conductor Casing" },
        { name: "Surface Casing" },
        { name: "Production Casing" },
        { name: "Intermediate Or Protective Casing" },
        { name: "Tubing" },
        { name: "Liner" },
      ];
    },
    cValveList() {
      return [
        {name: " "},
        {name: "Safty Valve"},
        {name: "Wireline Retrievable Insert Valve"},
        {name: "Lubricator Valve"},
      ]
    },
    cDecendant() {
      return this.getDecendant(this.cId);
    },
    cBoderSpecTop() {
      if (this.mdMode) {
        return parseInt(this.properties.topMd);
      } else {
        return parseInt(this.properties.top);
      }
    },
    cBoderSpecBottom() {
      if (this.mdMode) {
        return parseInt(this.properties.bottomMd);
      } else {
        return parseInt(this.properties.bottom);
      }
    },
  },
  data() {
    return {
      properties: {},
      reset: false,
      deleteActive: false,
      crossOverPosError: '',
      bendingPosError: ''
    };
  },
  methods: {
    resetProperty() {
      let res = {};
      Object.keys(this.cInformations).forEach((key) => {
        if (key === "startTime" || key === "stopTime") {
          res[key] = this.cInformations[key]
            ? new Date(this.cInformations[key])
            : null;
        } else {
          res[key] = this.cInformations[key];
        }
      });
      this.properties = res;
      this.reset = !this.reset;
    },
    checkInRequireList(name) {
      return this.cRequireList.findIndex((key) => key === name) >= 0;
    },
    handleActivityType(value) {
      this.properties.activityType = value.name;
    },
    handleSelect(value, name) {
      this.properties[name] = value.name;
    },
    handleParent(value) {
      this.properties.id_parent = value.id;
    },
    onCancelDelete() {
      this.deleteActive = false;
    },
    onConfirmDelete() {
      this.deleteActive = false;
      this.$store.dispatch("deleteWbsEquip", { equipId: this.cId });
    },
    hide() {
      this.properties.stopTime = new Date();
      this.$nextTick(() => {
        this.save();
        eventManager.emit("reset-time");
      });
    },
    handleAddBorderSpec() {
      let temp = JSON.parse(this.properties.borderSpec);
      temp.push({ interval: 0, fill: null });
      this.properties.borderSpec = JSON.stringify(temp);
    },
    handleDeleteBorderSpec(_idx) {
      let temp = JSON.parse(this.properties.borderSpec);
      temp = temp.filter((x, idx) => idx !== _idx);
      this.properties.borderSpec = JSON.stringify(temp);
    },
    handleIntervalChange(from, e, _idx, specs) {
      let length
      if (this.mdMode) {
        length = parseInt(this.properties.bottomMd || 0) - parseInt(this.properties.topMd || 0)
      } else {
        length =
          parseInt(this.properties.bottom) - parseInt(this.properties.top);
      }
      let to = Number(e.target.value);

      console.log(from, to, _idx, specs);
      let specs1 = specs.map((s, idx) => {
        let interval = (Number(s.to) - Number(s.from)) / length;
        if (idx > 0)
          interval = (Number(s.to) - Number(specs[idx - 1].to)) / length;
        return {
          fill: s.fill,
          interval,
        };
      });
      this.properties.borderSpec = JSON.stringify(specs1);
      /*
      if (to > parseInt(this.properties.bottom)) {
        to = parseInt(this.properties.bottom)
      } else if (to < from) {
        to = from
      }

      let temp = JSON.parse(this.properties.borderSpec)
      temp[_idx].interval = (to - from) / length
      this.properties.borderSpec = JSON.stringify(temp)
      */
    },
    handleFillChange(val, _idx) {
      console.log(val, _idx);
      let temp = JSON.parse(this.properties.borderSpec);
      temp[_idx].fill = val;
      this.properties.borderSpec = JSON.stringify(temp);
    },
  },
  watch: {
    "properties.id_parent"(newVal) {
      if (newVal === "null") {
        this.properties.id_parent = null;
      }
    },
    "properties.top"(newVal) {
      if (this.cDevice && this.cDevice.id_equipment_type === 1) {
        this.properties.length = this.properties.bottom - newVal;
      }
    },
    "properties.bottom"(newVal) {
      if (this.cDevice && this.cDevice.id_equipment_type === 1) {
        this.properties.length = newVal - this.properties.top;
      }
    },
    "properties.crossOverPos"(newVal) {
      if (this.cDevice.id_equipment_type == 1) {
        let value = Number(newVal)
        let top, bottom
        if (!this.mdMode) {
          top = Number(this.cDevice.Properties.find(prop => prop.name === 'top').value)
          bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottom').value)
        } else {
          top = Number(this.cDevice.Properties.find(prop => prop.name === 'topMd').value)
          bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottomMd').value)
        }
        if (value < top || value > bottom) {
          if (this.mdMode) {
            this.crossOverPosError = 'CrossOver Position must be in range of TopMD and BottomMD'
          } else {
            this.crossOverPosError = 'CrossOver Position must be in range of TopTVD and BottomTVD'
          }
        } else {
          this.crossOverPosError = ''
        }
      }
    },
    "properties.bendingPos"(newVal) {
      if (this.cDevice.id_equipment_type == 1) {
        let value = Number(newVal)
        let top, bottom
        if (!this.mdMode) {
          top = Number(this.cDevice.Properties.find(prop => prop.name === 'top').value)
          bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottom').value)
        } else {
          top = Number(this.cDevice.Properties.find(prop => prop.name === 'topMd').value)
          bottom = Number(this.cDevice.Properties.find(prop => prop.name === 'bottomMd').value)
        }
        if (value < top || value > bottom) {
          if (this.mdMode) {
            this.bendingPosError = 'Bending Position must be in range of TopMD and BottomMD'
          } else {
            this.bendingPosError = 'Bending Position must be in range of TopTVD and BottomTVD'
          }
        } else {
          this.bendingPosError = ''
        }
      }
    },
    cInformations() {
      this.resetProperty();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  display: flex;
  align-items: center;
  & > button {
    margin: 0px 5px;
  }
}
.WbsDeviceInfo {
  /*height: calc(100vh - 48px);*/
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  /*position: fixed;*/
  position: relative;
}
.WbsDeviceInfoMain {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
}
.search-properties {
  border-top: 1px solid #f0f0f0;
}

.info-header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h3 {
    margin: 0;
    color: #2c3e50;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
}

.wbs-info-body {
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  flex: 1;
}

label:first-letter {
  text-transform: capitalize;
}

label.required-field::after {
  color: red;
  content: "*";
  font-size: 20px;
  position: relative;
  right: 0;
  top: 5px;
}
.v-toolbar.hover-gray:hover {
  background-image: linear-gradient(90deg, transparent, #f0f0f0);
}

.clear-parent-btn {
  height: auto;
  min-width: auto;
  margin: 0;
  > .md-ripple {
    padding: 0;
  }
}

.error-text {
  color: red;
  margin: 0;
  font-size: 14px;
  transform: translateY(-15px);
}
</style>
