import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({
			mdMode: 'showInMd'
		}),
		cOffsetTop() {
			if (this.mdMode) {
				let topMd = this.options.Properties.find(prop => prop.name === 'topMd')
				if (topMd) {
					return this.convertLength(topMd.value)
				}
			}
			let top = this.options.Properties.find(prop => prop.name === 'top').value
      return this.convertLength(top);
		},
		cTop() {
			if (this.mdMode) {
				let topMd = this.options.Properties.find(prop => prop.name === 'topMd')
				if (topMd) {
					return topMd.value
				}
			}
			let top = this.options.Properties
        	.find(prop => prop.name === 'top');
			if (top) {
				return top.value;
			}
			return this.options.top
		}
	}
}