<template>
  <div class="CardGroup relative-position" :style="{
  }" @click="onClick">
    <div class="card-list" v-if="Object.keys(cardPositions).length > 0" :key="cZoom">
      <div v-for="(item) in items" :key="item.id + cZoom"> 
        <card :options="item" :type="type" :right="cardPositions[item.id].right"
          :top="cardPositions[item.id].top" :left="cardPositions[item.id].left"
          :line-width="cardPositions[item.id].lineWidth"
          :line-top="cardPositions[item.id].lineTop"></card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Card from './Card.vue';
import eventManager from '../eventManager';
import gapWidth from '../mixins/gapWidth'

export default {
  name: 'CardGroup',
  props: ['selectedDeviceId', 'items', 'type'],
  mixins: [gapWidth],
  data: function() {
    return {
      cardPositions: {}
    }
  },
  computed: {
    ...mapGetters({
      cWbsDiagramWidth: "cWbsDiagramWidth",
      convertLength: "convertLength",
      getShoeTop: "getShoeTop",
      getHangerTop: "getHangerTop",
      cZoom: 'cZoom',
      getDeviceOffsetLeft: 'getDeviceOffsetLeft',
      numOfWbsDevice: 'numOfWbsDevice'
    }),
    unitWidth() {
      return 150;
    },
    unitHeight() {
      return 20;
    }
  },
  mounted() {
    this.relayout()
    eventManager.on('relayout', () => {
      console.log('card group relayout')
      this.relayout()
    })
  },
  methods: {
    onClick() {
      eventManager.emit('click', {id: 'reset'})
    },
    relayout() {
      let res = {}, res2 = {}
      let pixelTop, top, left, bottom, lineTop
      for (const item of this.items) {
        switch(item.type) {
          case "Terminator":
            if (item.terminatorType === 'Hanger') {
              pixelTop = Math.round(Number(this.getHangerTop(item.id)) / this.unitHeight)
              lineTop = this.getHangerTop(item.id)
            } else if (item.terminatorType === 'Shoe') {
              pixelTop = Math.round(Number((this.getShoeTop(item.id) - this.cGapWidth) / this.unitHeight)),
              lineTop = this.getShoeTop(item.id)
            }
            break;
          case "Casing":
            top = item.Properties.find(prop => prop.name === 'top').value
            bottom = item.Properties.find(prop => prop.name === 'bottom').value
            pixelTop = Math.round((this.convertLength(top) + this.convertLength(bottom - top)/2) / this.unitHeight)
            lineTop = this.convertLength(top) + Math.round(this.convertLength(bottom - top)/2)
            break
          default:
            top = item.Properties.find(prop => prop.name === 'top').value
            pixelTop = Math.round(Number(this.convertLength(top)) / this.unitHeight)
            lineTop = this.convertLength(top)
        }
        if (!res[pixelTop]) {
          left = 0
          res[pixelTop] = [{
            id: item.id,
            left,
            lineTop
          }]
        } else {
          left = res[pixelTop].length * this.unitWidth
          res[pixelTop].push({
            id: item.id,
            left,
            lineTop
          })
        }
      }
      let offsetLeft
      for (const key of Object.keys(res)) {
        let arr = res[key]
        let mid = Math.round(arr.length / 2)
        for (let i = 0; i < mid; i++) {
          offsetLeft = this.getDeviceOffsetLeft(arr[i].id)
          res2[arr[i].id ] = {
            top: Number(key) * this.unitHeight, 
            left: `calc(50% - ${this.cWbsDiagramWidth/2}px - ${arr[i].left}px - ${this.unitWidth}px - 20px)`,
            lineWidth: offsetLeft + 40 + arr[i].left + this.cGapWidth/2,
            lineTop: arr[i].lineTop
          } 
        }
        for (let i = 0; i < arr.length - mid; i++) {
          offsetLeft = this.getDeviceOffsetLeft(arr[i + mid].id)
          res2[arr[mid + i].id ] = {
              top: Number(key) * this.unitHeight, 
              right: `calc(50% - ${this.cWbsDiagramWidth/2}px - ${arr[i].left}px - ${this.unitWidth}px - 20px)`,
              lineWidth: offsetLeft + 40 + arr[i].left + this.cGapWidth/2,
              lineTop: arr[i].lineTop
            }
        }
      }
      this.cardPositions = res2
    }
  },
  watch: {
    cZoom() {
      this.relayout()
    },
    numOfWbsDevice() {
      this.relayout()
    }
  },
  components: {
    'card': Card
  }
}
</script>
<style lang="scss" scoped>
  .CardGroup {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
  }
/*  .card-list {
    max-height: 70vh;
    overflow-y: scroll;
  }
*/
</style>
