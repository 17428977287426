<template>
  <div id="app">
    <div v-if="token">
      <md-app md-waterfall>
        <md-app-toolbar class="Toolbar md-dense md-primary">
          <div class="md-toolbar-section-start">
            <!--<md-button class="md-icon-button" @click="$router.push('/')">
              <md-icon>menu</md-icon>
            </md-button>-->

            <md-tabs class="md-primary" md-sync-route>
              <md-tab md-icon="apps" to="/well"></md-tab>
              <md-tab md-label="WBS" to="/wbs" :md-disabled="!well"></md-tab>
              <md-tab md-label="BHA" to="/bha" :md-disabled="!well"></md-tab>
              <!-- <md-tab
                md-label="Trajectory"
                to="/well-trajectory"
                :md-disabled="!well"
              ></md-tab> -->
              <!-- <md-tab
                v-if="well"
                :md-label="well.name"
                :md-template-data="{ badge: 1 }"
                to="/well"
              >
              </md-tab> -->
              <!-- <template slot="md-tab" slot-scope="{ tab }">
                {{ tab.label }}
                <i class="badge" v-if="tab.data.badge"></i>
              </template> -->
            </md-tabs>
            <span class="selected-well-name" v-if="well">{{ well.name }}</span>
          </div>
          <div class="md-toolbar-section-end">
            <span v-if="!auth.tmpToken" class="username" @click="showRightMenu = true">
              <md-icon v-if="cUser" style="margin-right: 0.3em;"
                >account_circle</md-icon
              >{{ cUser.username }}
            </span>
            <md-button class="md-icon-button" @click="showRightMenu = true">
              <md-icon>more_vert</md-icon>
            </md-button>
          </div>
        </md-app-toolbar>

        <md-app-content>
          <!-- <div style="word-wrap: break-word; opacity: 0.3">
            token: {{ token }}
          </div> -->
          <router-view></router-view>
        </md-app-content>
        <md-app-drawer
          class="md-right"
          :md-active.sync="showRightMenu"
          v-if="showRightMenu"
        >
          <md-list>
            <md-list-item @click="bhaDeviceSetting">
              <span class="md-list-item-text">BHA Device</span>
              <md-icon>settings</md-icon>
            </md-list-item>
            <md-list-item>
              <span class="md-list-item-text">Help</span>
              <md-icon>help</md-icon>
            </md-list-item>
            <md-divider></md-divider>
            <md-list-item v-if="!auth.tmpToken" @click="logout" class="md-icon-bottom">
              <span class="md-list-item-text">Logout</span>
              <md-icon class="md-primary">logout</md-icon>
            </md-list-item>
          </md-list>
        </md-app-drawer>
      </md-app>

      <div
        class="message"
        :class="{
          visible:
            message.error ||
            message.success ||
            messageBha.error ||
            messageBha.success,
        }"
        :style="{
          backgroundColor: message.error || messageBha.error ? 'red' : 'green',
        }"
      >
        {{
          message.error ||
            messageBha.error ||
            message.success ||
            messageBha.success
        }}
      </div>
    </div>
    <LoginPage v-else />
  </div>
</template>

<script>
import LoginPage from "./pages/login.vue";
import Common from "./mixins";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  data() {
    return {
      showMenu: false,
      showRightMenu: false,
    };
  },
  components: { LoginPage },
  mixins: [Common],
  computed: {
    ...mapState({
      auth: state => state.auth,
      well: (state) => state.well.well,
      message: (state) => state.well.message,
      messageBha: (state) => state.bha.messageBha,
    }),
    ...mapGetters({
      cUser: "cUser",
    }),
  },
  async created() {
    const wellParam = new URLSearchParams(location.search).get('well');
    if (wellParam) {
      const well = JSON.parse(wellParam);
      await this.$store.dispatch("upsertWell", well);
      const newWell = this.$store.state.well.wells.find(w => w.name === well.name);
      this.$store.dispatch("getWell", newWell.id);
    }
  },
  methods: {
    ...mapMutations(["logout"]),
    bhaDeviceSetting() {
      this.$router.push("bha-device");
      this.showRightMenu = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .md-app {
    background-color: #f0f0f0;
  }
  .md-app-content {
    height: calc(100vh - 48px);
    padding: 0;
    overflow: auto;
    /*background-color: #f0f0f0;*/
  }

  .md-tabs {
    .badge {
      width: 9px;
      height: 9px;
      position: absolute;
      top: 8px;
      right: 10px;
      background: #6dd36d;
      border-radius: 100%;
    }
  }

  .md-app-drawer {
    max-width: 320px;
  }

  .message {
    border-radius: 15px;
    min-width: 260px;
    height: 48px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    text-align: center;
    font-size: 16px;
    color: white;
    padding: 14px;
    opacity: 0;
    transition: all 1s;
    pointer-events: none;
  }
  .message.visible {
    opacity: 1;
  }
  .md-toolbar {
    padding-left: 0;
  }
  .md-toolbar .md-tabs {
    padding-left: 0;
  }

  .md-divider {
    width: 90%;
    margin: 0 auto;
  }

  .md-dialog-title {
    margin-bottom: 0;
  }

  .selected-well-name {
    text-transform: uppercase;
    min-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 120%;
    white-space: nowrap;
    font-weight: 700;
    color: wheat;
    border: 1px solid;
    text-align: center;
    padding: 6px;
  }
  .md-tabs-navigation .md-button:first-child {
    max-width: 48px;
    min-width: 48px;
  }
  .username {
    cursor: pointer;
  }
}
</style>
