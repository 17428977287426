<template>
  <div :style="{
    position: 'absolute',
    top: offsetTop + 'px',
    left: cOffsetLeft +'px',
    width: 'calc(100% - ' + 2*cOffsetLeft + 'px)',
    zIndex: cNumOfParents
  }" :class="{'outlined': selectedDeviceId === options.id || isSelected}">
    <casing-internal :options="options" :key="cTop+' '+cBottom+' '+cHeight"
      :has-cross-over="cHasCrossOver" :top="cTop" :bottom="cBottom" @select="$emit('select', $event);"
      :height="cHeight" :cross-over-pos="cCrossOverPos" :bending-pos="cBendingPos"/>
  </div>
</template>
<script>
import CasingInternal from './CasingInternal.vue';
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import bottom from '../mixins/bottom';
import selection from '../mixins/selection'
import {mapGetters} from 'vuex';

export default {
  name: 'Casing',
  components: {
    'casing-internal': CasingInternal
  },
  props: ['options', 'width', 'selectedDeviceId'],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      getNumOfParent: 'getNumOfParent',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return this.convertLength(this.cBottom - this.cTop);
    },
    cHasCrossOver() {
      let hasCrossOver = this.options.Properties
        .find(prop => prop.name === 'hasCrossOver');
      if (hasCrossOver) {
        return Number(hasCrossOver.value);
      }
      return this.hasCrossOver || (this.options || {}).hasCrossOver;
    },
    cCrossOverPos() {
      let crossOverPos = {
        ...this.options.Properties.find(prop => prop.name === 'crossOverPos')
      }
      if (crossOverPos && (crossOverPos.value == "0" || !isNaN(crossOverPos.value))) {
        crossOverPos.value = Math.round(crossOverPos.value / 100 * (this.cBottom - this.cTop))
        return this.convertLength(crossOverPos.value)
      }
      return this.cHeight/2;
    },
    cBendingPos() {
      let bendingPos = this.options.Properties.find(prop => prop.name === 'bendingPos')
      if (bendingPos) {
        return Math.round(bendingPos.value / 100 * (this.cBottom - this.cTop))
      }
      return 0
    },
    cStickToTop() {
      let stickToTop = this.options.Properties
        .filter(prop => prop.name === 'stickToTop')[0];
      if (stickToTop) {
        return Number(stickToTop.value);
      }
      return (this.options || {}).stickToTop;
    },
    offsetTop() {
      if (this.cStickToTop) {
        return 0;
      } else {
        return this.cOffsetTop
      }
    },
    cNumOfParents() {
      return this.getNumOfParent(this.options.id);
    },
  },
  mixins: [gapWidth, offsetLeft, selection, top, bottom],
}
</script>
<style lang="scss" scoped>
  // .Casing {
  //   position: relative;
  // }
</style>
