<template>
  <md-dialog
    v-if="deviceData"
    class="device-table"
    :md-active.sync="isShow"
    :md-click-outside-to-close="false"
  >
    <md-dialog-title>
      <md-button
        class="md-icon-button"
        style="position: absolute; right: 30px"
        @click="handleClose"
      >
        <md-icon>close</md-icon>
      </md-button>
      <div class="device-table-title">
        <h3>{{ type === "wbs" ? "WBS" : "BHA" }}</h3>
        <md-button class="md-primary" @click="download">
          <md-icon>file_download</md-icon>
          Download
        </md-button>
      </div>
    </md-dialog-title>
    <md-tabs md-dynamic-height>
      <md-tab md-label="Image">
        <table class="diagram-table" v-if="type === 'wbs'">
          <thead>
            <tr>
              <th>Well Schematic</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div v-if="imageRef" v-html="imageRef.outerHTML"></div>
            </tr>
          </tbody>
        </table>

        <table class="diagram-table" v-else>
          <thead>
            <tr>
              <th>Well Schematic</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div v-if="imageRef" v-html="imageRef.outerHTML"></div>
            </tr>
          </tbody>
        </table>
      </md-tab>

      <md-tab md-label="Table">
        <div>
          <div v-if="type === 'wbs'" style="display: flex">
            <div class="prop-table">
              <table>
                <thead>
                  <tr>
                    <th v-for="(name, idx) in headerInfo" :key="idx">
                      {{ name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ well && well.name }}</td>
                    <td>{{ wbsInfo && wbsInfo.name }}</td>
                    <td>{{ wbsInfo && wbsInfo.rigFloor }}</td>
                    <td>{{ wbsInfo && wbsInfo.rigName }}</td>
                    <td>{{ wbsInfo && wbsInfo.createdDate }}</td>
                    <td>{{ wbsInfo && wbsInfo.author }}</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th v-for="(name, idx) in headerProperty" :key="idx">
                      {{ name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  <tr v-for="(prop, idx) in tableProperty" :key="idx">
                    <td>{{ idx + 1 }}</td>
                    <td>{{ prop.name }}</td>
                    <td>{{ prop.activityType }}</td>
                    <td>{{ prop.OD }}</td>
                    <td>{{ prop.ID }}</td>
                    <td>{{ prop.length }}</td>
                    <td>{{ prop.startTime }}</td>
                    <td>{{ prop.stopTime }}</td>
                    <td>{{ prop.note }}</td>
                    <td>{{ prop.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style="display: flex" v-else>
            <div class="prop-table">
              <table>
                <thead>
                  <tr>
                    <th v-for="(name, idx) in headerInfo" :key="idx">
                      {{ name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ well && well.name }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ bhaInfo && bhaInfo.name }}</td>
                    <td></td>
                    <td></td>
                    <td>{{ bhaInfo && bhaInfo.createdDate }}</td>
                    <td>{{ bhaInfo && bhaInfo.author }}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th v-for="(name, idx) in headerProperty" :key="idx">
                      {{ name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  <tr v-for="(prop, idx) in tableProperty" :key="idx">
                    <td>{{ idx + 1 }}</td>
                    <td>{{ prop.description }}</td>
                    <td>{{ prop.manufacture }}</td>
                    <td>{{ prop.serialNumber }}</td>
                    <td>{{ prop.od }}</td>
                    <td>{{ prop.id }}</td>
                    <td>{{ prop.maxOd }}</td>
                    <td>{{ prop.bottomSize }}</td>
                    <td>{{ prop.topSize }}</td>
                    <td>{{ prop.bottomGender }}</td>
                    <td>{{ prop.topGender }}</td>
                    <td>{{ prop.bottomType }}</td>
                    <td>{{ prop.topType }}</td>
                    <td>{{ prop.fnOd }}</td>
                    <td>{{ prop.fnLength }}</td>
                    <td>{{ prop.length }}</td>
                    <td>{{ prop.cumLength }}</td>
                    <td>{{ prop.cumWeight }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </md-tab>
    </md-tabs>
  </md-dialog>
</template>

<script>
import XLSX from "xlsx";
import html2canvas from "html2canvas";
export default {
  name: "DeviceTable",
  props: [
    "type",
    "deviceData",
    "isShow",
    "handleClose",
    "imageRef",
    "well",
    "wbsInfo",
    "bhaInfo",
  ],
  data() {
    return {
      isPrint: false,
    };
  },
  computed: {
    tableProperty() {
      const props = [];
      let name,
        value = null;
      for (let equip of this.deviceData) {
        let tmpProp = {};
        for (let prop of equip["Properties"]) {
          name = prop.name;
          value = prop.value;
          tmpProp[name] = value;
        }
        tmpProp["name"] = equip.name;
        props.push(tmpProp);
      }
      return props;
    },
    headerProperty() {
      let header = [];
      if (this.type === "wbs") {
        header = [
          "STT",
          "Name",
          "Activity Type",
          "OD (m)",
          "ID (m)",
          "Length (m)",
          "Start Time",
          "Stop Time",
          "Note",
          "Comment",
        ];
      } else if (this.type === "bha") {
        header = [
          "STT",
          "Desc.",
          "Manu.",
          "Serial Number",
          "OD (in)",
          "ID (in)",
          "Max OD (in)",
          "Bot Size",
          "Top Size",
          "Bot Type",
          "Top Type",
          "Bot Gender",
          "Top Gender",
          "FN OD (in)",
          "FN Length",
          "Length (m)",
          "Cum.Length (m)",
          "Cum.Weight (t)",
        ];
      }

      return header;
    },
    headerInfo() {
      let header = [];
      if (this.type === "wbs") {
        header = [
          "Well Name",
          "WBS Name",
          "Rig Floor",
          "Rig",
          "Created Date",
          "Author",
        ];
      } else if (this.type === "bha") {
        header = [
          "Well Name",
          "Field Name",
          "Borehold Name",
          "Hole Size (in)",
          "Structure Name",
          "BHA Name",
          "Depth In (m)",
          "Depth Out (m)",
          "Created Date",
          "Author",
        ];
      }
      return header;
    },
  },
  updated() {
    if (this.isPrint) {
      window.print();
      this.isPrint = false;
    }
  },
  methods: {
    download() {
      const data = [];
      data.push(this.headerInfo);
      if (this.type === "wbs") {
        window.print();
        this.wbsInfo &&
          data.push([
            this.well.name,
            this.wbsInfo.name,
            this.wbsInfo.rigFloor,
            this.wbsInfo.rigName,
            this.wbsInfo.createdDate,
            this.wbsInfo.author,
          ]);
        data.push(this.headerProperty);
        for (let idx in this.tableProperty) {
          let prop = this.tableProperty[idx];
          data.push([
            Number.parseInt(idx) + 1,
            prop.activityType,
            prop.OD,
            prop.ID,
            prop.length,
            prop.startTime,
            prop.stopTime,
            prop.note,
            prop.comment,
          ]);
        }
      } else {
        this.imageRef &&
          html2canvas(this.imageRef, {
            allowTaint: true,
            useCORS: true,
            width: this.imageRef.offsetWidth,
            height: this.imageRef.offsetHeight || 1200,
          }).then((canvas) => {
            console.log(canvas);
            let img = canvas.toDataURL("image/png");
            let link = document.createElement("a");
            link.download = "well-image.png";
            link.href = img;
            link.click();
          });
        this.bhaInfo &&
          data.push([
            this.well.name,
            "",
            "",
            "",
            "",
            this.bhaInfo.name,
            "",
            "",
            this.bhaInfo.createdDate,
            this.bhaInfo.author,
          ]);
        data.push(this.headerProperty);
        for (let idx in this.tableProperty) {
          let prop = this.tableProperty[idx];
          data.push([
            Number.parseInt(idx) + 1,
            prop.description,
            prop.manufacture,
            prop.serialNumber,
            prop.od,
            prop.id,
            prop.maxOd,
            prop.bottomSize,
            prop.topSize,
            prop.bottomGender,
            prop.topGender,
            prop.bottomType,
            prop.topType,
            prop.fnOd,
            prop.fnLength,
            prop.length,
            prop.cumLength,
            prop.cumWeight,
          ]);
        }
      }

      let worksheet = XLSX.utils.aoa_to_sheet(data);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "Well.xlsx");
    },
  },
};
</script>

<style lang="scss">
.device-table {
  .prop-table {
    margin-right: 15px;
    overflow-x: auto;
    table {
      border-collapse: collapse;
      font-size: 0.9em;
      min-width: 400px;
      margin-bottom: 15px;
      tr {
        text-align: center;
      }
      th,
      td {
        border: 1px solid black;
        padding: 12px 15px;
        overflow: auto;
      }
    }
  }

  .md-theme-default {
    height: auto !important;
    overflow-y: auto;

    .md-ripple {
      height: 36px;
    }

    .md-primary {
      display: flex;
      margin-right: 100px;
    }

    .md-tabs-navigation {
      margin-left: 50px;
    }
  }

  .diagram-table {
    margin: auto;
    width: 400px;
    // width: 100%;
    th {
      font-size: 1.3em;
      padding: 12px 15px;
      overflow: auto;
    }
  }
}

.device-table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  h3 {
    margin: 0;
  }
}

@media print {
  @page {
    size: 20in;
  }

  body * {
    visibility: hidden;
  }
  .diagram-table,
  .diagram-table * {
    visibility: visible;
  }
  .diagram-table {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
