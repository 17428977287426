var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-title",style:({
    marginBottom: _vm.zoom * 15 + 'px',
    position: 'absolute',
    top: _vm.top + 'px',
    left: _vm.left,
    right: _vm.right
  })},[_c('span',{class:_vm.isSelected ? 'outlined' : '',style:({padding: '5px', display: 'flex', alignItems: 'center'}),on:{"click":_vm.onClick}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.getIndex(_vm.options.id))+". ")]),_vm._v(" "+_vm._s(_vm.cName))])]),(_vm.lineWidth && _vm.left)?_c('div',{style:({
    position: 'absolute',
    top: '50%',
    left: '120px',
    borderTop: `1px solid black`,
    width: `${_vm.lineWidth}px`,
    zIndex: 100
  })}):_vm._e(),(_vm.lineWidth && _vm.right)?_c('div',{style:({
    position: 'absolute',
    top: '50%',
    right: '120px',
    borderTop: `1px solid black`,
    width: `${_vm.lineWidth}px`,
    zIndex: 100
  })}):_vm._e(),_c('md-dialog-confirm',{attrs:{"md-active":_vm.deleteActive,"md-content":"<strong>Delete this node will delete all of its children.</strong>","md-confirm-text":"Delete","md-cancel-text":"Cancel"},on:{"update:mdActive":function($event){_vm.deleteActive=$event},"update:md-active":function($event){_vm.deleteActive=$event},"md-cancel":_vm.onCancelDelete,"md-confirm":_vm.onConfirmDelete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }