import axios from 'axios';
import config from './config';
import { v1 as uuid } from 'uuid';
import store from '../store';


const service = axios.create({ baseURL: config.serviceUrl })

service.interceptors.request.use((req) => {
    const token = store.state.auth.token;
    Object.assign(req.headers, {
        'Authorization': token,
        'WHOAMI': "WI_WBS_CLIENT",
        'Service': "WI_WBS_SERVICE"
    })

    return req;
}, (error) => {
    return Promise.reject(error);
})

service.interceptors.response.use(response => {

    return response
}, (error) => {
    if (error.response.data === "Unauthorized" && error.response.status === 401) {
        localStorage.removeItem('token')
        location.reload();
    }

    return Promise.reject(error)
})

const getWells = (path) => {
    return service.get(path);
}

const createWell = (path, { name, well_data, wbsShowOptions, bhaShowOptions }) => {
    return service.post(path, {
        name,
        well_data,
        wbsShowOptions,
        bhaShowOptions
    })
}

const editWell = (path, { name, well_data }) => {
    return service.put(path, {
        name,
        well_data
    })
}

const cloneWell = (path, { name, id_well }) => {
    return service.post(path, {
        name,
        id_well
    })
}

const deleteWell = (path) => {
    return service.delete(path);
}

const getWbsDevices = (path) => {
    return service.get(path);
}

const deleteDevice = (path) => {
    return service.delete(path)
}

const addWbsDevice = (path, data) => {
    return service.post(path, data)
}

const editEquip = (path, data) => {
    return service.put(path, data)
}

const getBhaDevices = (path) => {
    return service.get(path);
}

const addBhaDevice = (path, formData) => {
    return service.post(path, formData);
}

const editBhaDevice = (path, formData) => {
    return service.put(path, formData);
}

const deleteBhaDevice = (path) => {
    return service.delete(path)
}

const auth = axios.create({ baseURL: config.authUrl });

auth.interceptors.response.use((response) => {
    if (response.data.code !== 200) {
        return Promise.reject(response.data.reason);
    }

    return response.data.content;
})

const doLogin = (path, { username, password }) => {
    return auth.post(path, {
        username: username || "",
        password: password || "",
        whoami: "WI_WBS",
        client_id: uuid()
    })
}



export {
    doLogin, getWells, createWell, deleteWell, editWell, cloneWell,
    getWbsDevices, deleteDevice, addWbsDevice, editEquip,
    getBhaDevices, addBhaDevice, editBhaDevice, deleteBhaDevice, service
}
