var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{LandingCollar: true, 'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
    top: _vm.cOffsetTop + 'px',
    left: _vm.cOffsetLeft + 0.5 + 'px',
    height: _vm.cHeight + 'px',
    width: 'calc(100% - ' + 2*(_vm.cOffsetLeft + 0.5) + 'px)',
    display: 'flex'
  }),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id});}}},[_c('svg',{style:({
				stroke: '#000'
			}),attrs:{"height":"100%","width":"100%"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":"rgb(160, 87, 10)"}}),_c('line',{attrs:{"x1":"0","y1":"0%","x2":"100%","y2":"100%"}}),_c('line',{attrs:{"x1":"100%","y1":"0%","x2":"0%","y2":"100%"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }