<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <div class="search-div" 
      @click="handleDivClick"
    >
      <label for="searchDropdown"
        >{{ label }} <span v-if="isRequired" class="isRequired">*</span></label
      >
      <input
        :id="name"
        class="dropdown-input"
        :name="name"
        @focus="showOptions"
        @blur="exit"
        @keydown="tabMonitor"
        @keyup="enterMonitor"
        v-model="searchFilter"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
      />
      <md-icon class="dropdown_icon">arrow_drop_down</md-icon>
    </div>
    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        @mouseover="
          (event) => name === 'bha_Device_dropdown' && previewImage(option, event)
        "
        @mousedown="(event) => handleSelectOption(event, option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        <img v-if="option.icon_path" 
          :src="option.icon_path"
          class="option-icon" 
        />
        <div class="options-text">{{ option.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'

export default {
  name: "Dropdown",
  template: "Dropdown",
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    placeholder: {},
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: "Options of dropdown. An array of options with id and name",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 100,
      note: "Max items showing",
    },
    handleSelect: {
      type: Function,
    },
    label: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    handlePreview: {
      type: Function,
    },
    showIconOption: {
      type: Function
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: "",
      isFocus: false,
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (
          (this.searchFilter && this.searchFilter.length < 1) ||
          option.name.toString().match(regOption)
        ) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
  },
  methods: {
    selectOption(option, event) {
      event && event.preventDefault();
      this.selected = option;
      this.searchFilter = option.name
      this.optionsShown = false;
      this.handleSelect(option, this.name);
    },
    handleSelectOption(event, option) {
      let temp = jQuery(event.target)
      if (temp[0].nodeName === 'IMG') {
        this.showIconOption(option)
      } else {
        this.selectOption(option, event)
        jQuery(`#${this.name}`).blur()
      }
    },
    showOptions() {
      if (!this.disabled) {
        if (typeof(this.placeholder) === 'string') {
          this.placeholder = this.placeholder.trim();
        }
        this.searchFilter = this.placeholder || '';
        this.optionsShown = true;
      }
    },
    handleDivClick() {
      jQuery(`#${this.name}`).focus()
    },
    exit() {
      if (!this.selected.name || this.searchFilter != this.selected.name) {
        console.log(this.searchFilter)
        if (this.searchFilter == '') {
          this.searchFilter = this.placeholder
        }
        let option = {
          name: this.searchFilter
        }
        this.selectOption(option)
      } else {
        this.optionsShown = false
      }
      this.searchFilter = ''
    },
    // Selecting when pressing Enter
    enterMonitor: function(event) {
      if (event.key === "Enter") {
        if (this.filteredOptions[0]) {
          this.selectOption(this.filteredOptions[0]);
        } else {
          let temp = event.target.value
          let value = isNaN(temp) ? event.target.value : temp 
          let option = {
            name: value
          }
          this.selectOption(option)
        }
        jQuery(`#${this.name}`).blur()
      }
    },
    // Select when pressing Tab
    tabMonitor: function(event) {
      if (event.key === "Tab") {
        if (this.filteredOptions[0]) {
          this.selectOption(this.filteredOptions[0]);
        } else {
          event.preventDefault();
        }
      }
    },
    previewImage: function(option, event) {
      event && event.preventDefault();
      this.handlePreview(option.image_path);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  width: 100%;
  .search-div {
    width: 100%;
    label {
      top: -20px;
      font-size: 13px;
    }
    .dropdown-input {
      width: 100%;
      background: #fff;
      cursor: pointer;
      border: none;
      border-radius: 3px;
      color: #000;
      display: block;
      font-weight: bold;
      font-size: 1rem;
      padding: 6px;
      &:hover {
        background: #f8f8fa;
      }
    }
    .dropdown_icon {
      position: absolute;
      right: 0px;
      top: 5px;
    }
    .isRequired {
      color: red;
    }
  }
  .search-div:hover {
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #796d69;
    border-radius: 10px;
  }
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    width: 100%;
    max-height: 190px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 8px rgba(0, 0, 0, 0.2);
    overflow: auto;
    z-index: 999;
    .dropdown-item {
      color: #000;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1rem;
      padding: 8px;
      text-decoration: none;
      display: flex;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #c9cacc;
      }
      .option-icon {
        width: 16px;
        height: 16px;
      }
      .edit-icon {
        position: absolute;
        right: 0.8rem;
        background-color: rosybrown;
        z-index: 999;
      }
      .options-text {
        height: 1rem;
        padding-left: 5px;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
