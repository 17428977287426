<template>
  <component :is="cTerminatorType" :options="options"
    :handle-click="handleClick" :is-selected="isSelected"
    :selected-device-id="selectedDeviceId"
    :offset-left="cOffsetLeft" @select="$emit('select', $event)">
    <div class="inner-border">
    </div>
  </component>
</template>
<script>
import selection from '../mixins/selection';
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';

export default {
  props: ['options', 'terminatorType', 'selectedDeviceId'],
  data() {
    return {}
  },
  mixins: [selection, gapWidth, offsetLeft],
  computed: {
    cTerminatorType() {
      return this.terminatorType || this.options.terminatorType;
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
