const getTanFromDegrees = (degrees) => {
    return Math.abs(Math.tan(degrees * Math.PI / 180));
}

const getRadianFromDegrees = (degrees) => {
    return degrees * Math.PI / 180;
}

const signX = (azimuth) => {
    let aA = azimuth;
    while (aA < 0) {
        aA += 360;
    }
    if ( aA < 90) {
        return 1;
    }
    if( aA < 270 ) {
        return -1;
    }
    if ( aA < 360 ) {
        return 1;
    }
}
const signZ = (azimuth) => {
    let aA = azimuth;
    while (aA < 0) {
        aA += 360;
    }
    if ( aA < 180) {
        return 1;
    }
    if ( aA < 360 ) {
        return -1;
    }
}

const getRefPointsFromBendingPoints = (pointsData) => {
    let points = [{ x:0, y:0, z:0 }];
    let x = 0;
    let y = 0;
    let z = 0;
    let aA = 0;
    let dA = 0;
    let i = 1;
    let tempX;
    while (i < pointsData.length) {
      let p = pointsData[i];
      y = -p.deep;
      tempX = ((-y) * getTanFromDegrees(dA) ) / Math.sqrt(1 + getTanFromDegrees(aA) ** 2);
      x = tempX * signX(aA);
      z = tempX * getTanFromDegrees(aA) * signZ(aA);
      points.push({x, y, z});
      aA = p.azimuthAngle;
      dA = p.deviationAngle;
      i++;
    }
    return points;
}

export default { getTanFromDegrees, getRadianFromDegrees, getRefPointsFromBendingPoints }
