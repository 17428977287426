
import { getBhaDevices, editBhaDevice, deleteBhaDevice, addBhaDevice } from '../../utils/api'


const bhaDevice = {
    state: {
        bhaDevices: [],
        bhaGroups: [],
        bhaGroupDevices: [],
        messageBha: {
            success: null,
            error: null,
        }
    },
    mutations: {
        setMessageBha(state, { message, type }) {
            if (type === 'success') {
                state.messageBha.success = message;
            } else {
                state.messageBha.error = message;
            }
        },
    },
    actions: {
        async getBhaDevices(context) {
            try {
                const response = await getBhaDevices('/bhaDevice');
                context.state.bhaDevices = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getBhaGroups(context) {
            try {
                const response = await getBhaDevices('/bhaDevice/group');
                context.state.bhaGroups = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getBhaGroupDevices(context, group) {
            try {
                group = encodeURIComponent(group)
                console.log(group)
                const response = await getBhaDevices(`/bhaDevice?group=${group}`);
                context.state.bhaGroupDevices = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async addBhaDevice(context, { name, group, scale, image, icon }) {
            let message, type = null;
            try {
                const formData = new FormData();
                formData.append("device_name", name);
                formData.append("group", group);
                formData.append("scale_factor", scale);
                formData.append("image", image);
                formData.append("icon", icon);
                const response = await addBhaDevice('/bhaDevice', formData);
                context.state.bhaDevices.push(response.data);
                message = "Create done";
                type = 'success';
            } catch (error) {
                console.log(error);
                message = error.response.data.message;
                type = 'error';
            } finally {
                context.commit('setMessageBha', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessageBha', { message, type });
                }, 4000)
            }
        },
        async editBhaDevice(context, { id, name, group, scale, image, icon }) {
            let message, type = null;
            try {
                const formData = new FormData();
                formData.append("device_name", name);
                formData.append("group", group);
                formData.append("scale_factor", scale);
                formData.append("image", image);
                formData.append("icon", icon);
                const response = await editBhaDevice(`/bhaDevice/${id}`, formData);
                const newDevice = response.data;
                let bhaDevice = context.state.bhaDevices.find(device => device.id === id);
                bhaDevice.device_name = newDevice.device_name;
                bhaDevice.group = newDevice.group;
                bhaDevice.scale_factor = newDevice.scale_factor;
                if (newDevice.image_path) {
                    bhaDevice.image_path = newDevice.image_path;
                }
                message = "Edit done";
                type = 'success';
            } catch (error) {
                console.log(error);
                message = error.response.data.message;
                type = 'error';
            } finally {
                context.commit('setMessageBha', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessageBha', { message, type });
                }, 4000)
            }
        },
        async deleteBhaDevice(context, deviceId) {
            let message, type = null;
            try {
                await deleteBhaDevice(`/bhaDevice/${deviceId}`);
                let idx = context.state.bhaDevices.map(device => device.id).indexOf(deviceId);
                if (idx >= 0) context.state.bhaDevices.splice(idx, 1);
                type = 'success';
            } catch (error) {
                console.log(error);
                type = 'error';
            } finally {
                if (type === 'success') {
                    message = "Delete done!";
                } else {
                    message = "Delete error!";
                }
                context.commit('setMessageBha', { message, type });
                setTimeout(() => {
                    message = null;
                    context.commit('setMessageBha', { message, type });
                }, 4000)
            }
        }
    }
}

export default bhaDevice;
