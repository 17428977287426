export const LegendTable = {
  Cs: {
    color: "#F80",
    description: "Surface casing",
  },
  Cc: {
    color: "#F80",
    description: "Conductor Casing",
  },
  Cp: {
    color: "#F80",
    description: "Production casing",
  },
  Ci: {
    color: "#F80",
    description: "Intermediate casing",
  },
  Tu: {
    color: "#800",
    description: "Tubing",
  },
  Li: {
    color: "#800",
    description: "Liner",
  },
  Ha: {
    color: "#800",
    description: "Hanger",
  },
  Sh: {
    color: "#800",
    description: "Shoe",
  },
  Ba: {
    color: "#800",
    description: "Baker",
  },
  Pa: {
    color: "#800",
    description: "Parker",
  },
  Va: {
    color: "#808",
    description: "Safty Valve",
  },
  Lv: {
    color: "#808",
    description: "Lubricator Valve",
  },
  Wrv: {
    color: "#080",
    description: "Wireline Retrievable Insert Valve",
  },
  Ni: {
    color: "#080",
    description: "Nipple",
  },
  Se: {
    color: "#800",
    description: "Tieback Seal",
  },
  cE: {
    color: "#800",
    description: "Centralizer",
  },
  Pe: {
    color: "#404",
    description: "Perforation",
  },
  Pc: {
    color: "#000",
    description: "Cement Plug",
  },
  Cl: {
    color: "#34eb64",
    description: "Control Line",
  },
  Lc: {
    color: "#000",
    description: "Landing Collar",
  },
  Pl: {
    color: "#34eb64",
    description: "Plug",
  },
  Wr: {
    color: "red",
    description: "Whipstock Right",
  },
  Wl: {
    color: "green",
    description: "Whipstock Left",
  },
};

export const BhaLegendTable = {
  Dp: {
    color: "#edaa3e",
    description: "Drill Pipe",
  },
  Wp: {
    color: "#edaa3e",
    description: "Wash Pipe",
  },
  Hw: {
    color: "#edaa3e",
    description: "HW Drill Pipe",
  },
  Dc: {
    color: "#edaa3e",
    description: "Drill Collar",
  },
  Sd: {
    color: "#800",
    description: "Spiral Drill Collar",
  },
  Lw: {
    color: "#800",
    description: "LWD Collar",
  },
  Nc: {
    color: "#800",
    description: "Non-mag collar",
  },
  Lc: {
    color: "#800",
    description: "Landing collar",
  },
  BiS: {
    color: "#F80",
    description: "Bit Sub",
  },
  Rs: {
    color: "#F80",
    description: "RA Sub",
  },
  Js: {
    color: "#F80",
    description: "Junk Sub",
  },
  BuS: {
    color: "#F80",
    description: "Bumper Sub",
  },
  Ss: {
    color: "#ff8800",
    description: "Shock Sup",
  },
  Bs: {
    color: "#ff8800",
    description: "Bypass Sub",
  },
  Fs: {
    color: "#ff8800",
    description: "Float sub/Dart sub",
  },
  Ja: {
    color: "#ff8800",
    description: "Jar",
  },
  Spa: {
    color: "#ff8800",
    description: "Spang Jar",
  },
  Spr: {
    color: "#ff8800",
    description: "Spring Jar",
  },
  Re: {
    color: "#ff8800",
    description: "Reamer",
  },
  Ac: {
    color: "#ff8800",
    description: "Acceslerator",
  },
  St: {
    color: "#ff8800",
    description: "Stabiliser",
  },
  RoS: {
    color: "#ff8800",
    description: "Rotary steerable",
  },
  Ag: {
    color: "#ff8800",
    description: "Agigator",
  },
  Rb: {
    color: "#ff8800",
    description: "Rotary bit",
  },
  Pb: {
    color: "#ff8800",
    description: "PDC bit",
  },
  Sb: {
    color: "#ff8800",
    description: "Strikeforce bit",
  },
  Lh: {
    color: "#ff8800",
    description: "Linner hanger",
  },
  Be: {
    color: "#ff8800",
    description: "Borehole Enlargerment",
  },
  Dm: {
    color: "#ff8800",
    description: "DD Motor",
  },
  Mc: {
    color: "red",
    description: "MWD Collar",
  },
  DiC: {
    color: "#ff8800",
    description: "Die Collar",
  },
  Cr: {
    color: "red",
    description: "Crossover",
  },
  P4: {
    color: "#ff8800",
    description: "Perforation 4 ints",
  },
  Mp: {
    color: "red",
    description: "Middle perforation",
  },
  Tp: {
    color: "#ff8800",
    description: "Top perforation",
  },
  Bp: {
    color: "red",
    description: "Bottom perforation",
  },
  Tt: {
    color: "#ff8800",
    description: "Taper Tap",
  },
  Lj: {
    color: "red",
    description: "Lace Joint",
  },
  Wim: {
    color: "#ff8800",
    description: "Window Mill",
  },
  Pm: {
    color: "red",
    description: "Plug Mill",
  },
  Rnm: {
    color: "#ff8800",
    description: "Round Nose Mill",
  },
  Sm: {
    color: "red",
    description: "Skirted Mill",
  },
  Wam: {
    color: "#ff8800",
    description: "Watermelon Mill",
  },
  Trm: {
    color: "red",
    description: "Tri-Blade Mill",
  },
  Gm: {
    color: "#ff8800",
    description: "Guide Mill",
  },
  Tbm: {
    color: "red",
    description: "Tie Back Mill",
  },
  Waw: {
    color: "#ff8800",
    description: "Washover Whipstock",
  },
  Mw: {
    color: "red",
    description: "MERlin Whipstock",
  },
  Rw: {
    color: "#ff8800",
    description: "RDS Whipstock",
  },
  Wow: {
    color: "#4061e3",
    description: "Workover Whipstock",
  },
  Tew: {
    color: "#F80",
    description: "Tubing Exit Whipstock",
  },
  Mpw: {
    color: "red",
    description: "ML Packer / Whipstock",
  },
  Wo: {
    color: "#F80",
    description: "Whipstock overshot",
  },
  Sp: {
    color: "red",
    description: "SB Pulling",
  },
  Cdp: {
    color: "#F80",
    description: "Composite Deflector Pulling",
  },
  Apr: {
    color: "#4061e3",
    description: "AHR Packer Running",
  },
  Lac: {
    color: "#F80",
    description: "Latch Cleaning",
  },
  Hr: {
    color: "red",
    description: "Hydraulic Running",
  },
  Pp: {
    color: "#F80",
    description: "PPH Packer",
  },
  Rhs: {
    color: "red",
    description: "RHS Packer",
  },
  Gp: {
    color: "#F80",
    description: "G-10 Packer",
  },
  Rsb: {
    color: "#4061e3",
    description: "RSB Packer",
  },
  Ahc: {
    color: "#F80",
    description: "AHC Packer",
  },
  Rpp: {
    color: "red",
    description: "Retrievable Pump Packer",
  },
  Rhp: {
    color: "#F80",
    description: "Retrievable RH Packer",
  },
  Thb: {
    color: "red",
    description: "THB Packer",
  },
  Lhp: {
    color: "#F80",
    description: "Liner Hanger Packer",
  },
  Ahr: {
    color: "#4061e3",
    description: "AHR/C Parker",
  },
  Rdp: {
    color: "#F80",
    description: "RD parker",
  },
  Rp: {
    color: "red",
    description: "RDH/DHC parker",
  },
  Mhr: {
    color: "#F80",
    description: "MHR Parker",
  },
  Ej: {
    color: "#4061e3",
    description: "Expansion Joint",
  },
  Pe: {
    color: "#F80",
    description: "Perforation",
  },
  Tt1: {
    color: "red",
    description: "Tubing type1",
  },
  Tt2: {
    color: "#F80",
    description: "Tubing type2",
  },
  Pup: {
    color: "#4061e3",
    description: "Pup",
  },
  Pin: {
    color: "#F80",
    description: "Pin up",
  },
  Bu: {
    color: "red",
    description: "Box up",
  },
  Sh: {
    color: "#F80",
    description: "Single hangers",
  },
  Dh: {
    color: "#4061e3",
    description: "Dual Hanger",
  },
  Pbr: {
    color: "#F80",
    description: "PBR",
  },
  Tj: {
    color: "#4061e3",
    description: "Travel Jnts",
  },
  Ra: {
    color: "#F80",
    description: "Ratch",
  },
  L1: {
    color: "red",
    description: "Latch1",
  },
  L2: {
    color: "#F80",
    description: "Latch2",
  },
  Xo: {
    color: "#4061e3",
    description: "X/Overs",
  },
  "4x5": {
    color: "#F80",
    description: "4x5 type",
  },
  "5x4": {
    color: "red",
    description: "5x4 type",
  },
  Svn: {
    color: "#F80",
    description: "Safety Valve Nipple",
  },
  Ln: {
    color: "red",
    description: "Landing Nipple",
  },
  Sl: {
    color: "#F80",
    description: "Seal Locator",
  },
  Set: {
    color: "#4061e3",
    description: "Seal stack",
  },
  Ce: {
    color: "#3deb34",
    description: "Cent",
  },
  Weg: {
    color: "red",
    description: "WEG",
  },
  Fc1: {
    color: "#3deb34",
    description: "Fluted centrilisers 1",
  },
  Fc2: {
    color: "#4061e3",
    description: "Fluted centrilisers 2",
  },
  Ms: {
    color: "#3deb34",
    description: "Mule Shoe",
  },
  Sms: {
    color: "red",
    description: "Shearable Mule shoe",
  },
  Res: {
    color: "#3deb34",
    description: "Reciprocating shoe",
  },
  Fi: {
    color: "#4061e3",
    description: "Finder",
  },
  Fg: {
    color: "#3deb34",
    description: "Finder grab",
  },
  Eg: {
    color: "red",
    description: "Expandable grab",
  },
  Gr: {
    color: "#3deb34",
    description: "Grab",
  },
  Alg: {
    color: "#4061e3",
    description: "Aligator grab",
  },
  Hd: {
    color: "#3deb34",
    description: "HDFS",
  },
  Hy: {
    color: "red",
    description: "HYDRO",
  },
  Hpt: {
    color: "#3deb34",
    description: "HD Pulling Tool",
  },
  Tt3: {
    color: "#4061e3",
    description: "Tubing type 3",
  },
  Tt4: {
    color: "#3deb34",
    description: "Tubing type4",
  },
  Fl: {
    color: "red",
    description: "Flapper",
  },
  Ba: {
    color: "#3deb34",
    description: "Ball",
  },
  Mip: {
    color: "#4061e3",
    description: "Mirage Plug",
  },
  Spm: {
    color: "#3deb34",
    description: "Side pocket mandrel",
  },
  Gam: {
    color: "#4061e3",
    description: "Gauge Mandrel",
  },
  Lub: {
    color: "#3deb34",
    description: "Lub V/V",
  },
  Sbs: {
    color: "red",
    description: "Shearable Ball Seat",
  },
  Fc: {
    color: "#3deb34",
    description: "Fluted centrilisers",
  },
  Co: {
    color: "#4061e3",
    description: "Coupling",
  },
  Ssv: {
    color: "#3deb34",
    description: "SSV",
  },
  Pj: {
    color: "#3deb34",
    description: "Pup Joint",
  },
  Ni: {
    color: "$4061e3",
    description: "Nipple",
  },
  Sv: {
    color: "red",
    description: "Safety Valve",
  },
  Pg: {
    color: "#4061e3",
    description: "Pressure Gauge",
  },
};

export const reverseLegendTable = {
  "Surface Casing": "Cs",
  "Conductor Casing": "Cc",
  "Production Casing": "Cp",
  "Intermediate Or Protective Casing": "Ci",
  "Intermediate Casing": "Ci",
  "Protective Casing": "Ci",
  Tubing: "Tu",
  Liner: "Li",
  Hanger: "Ha",
  Shoe: "Sh",
  Baker: "Ba",
  Parker: "Pa",
  "Safty Valve": "Va",
  Nipple: "Ni",
  "Tieback Seal": "Se",
  Centralizer: "cE",
  Perforation: "Pe",
  "Cement Plug": "Pc",
  "Lubricator Valve": "Lv",
  "Wireline Retrievable Insert Valve": "Wrv",
  "Control Line": "Cl",
  "Landing Collar": "Lc",
  Plug: "Pl",
  "Whipstock Right": "Wr",
  "Whipstock Left": "Wl",
};

export const reverseBhaLegendTable = {
  "Drill Pipe": "Dp",
  "Wash Pipe": "Wp",
  "HW Drill Pipe": "Hw",
  "Drill Collar": "Dc",
  "Spiral Drill Collar": "Sd",
  "LWD Collar": "Lw",
  "Non-mag collar": "Nc",
  "Landing collar": "Lc",
  "Bit Sub": "BiS",
  "RA Sub": "Rs",
  "Junk Sub": "Js",
  "Bumper Sub": "BuS",
  "Shock Sup": "Ss",
  "Bypass Sub": "Bs",
  "Float sub/Dart sub": "Fs",
  Jar: "Ja",
  "Spang Jar": "Spa",
  "Spring Jar": "Spr",
  Reamer: "Re",
  Acceslerator: "Ac",
  Stabiliser: "St",
  "Rotary steerable": "RoS",
  Agigator: "Ag",
  "Rotary bit": "Rb",
  "PDC bit": "Pb",
  "Strikeforce bit": "Sb",
  "Linner hanger": "Lh",
  "Borehole Enlargerment": "Be",
  "DD Motor": "Dm",
  "MWD Collar": "Mc",
  "Die Collar": "Dc",
  Crossover: "Cr",
  "Perforation 4 ints": "P4",
  "Middle perforation": "Mp",
  "Top perforation": "Tp",
  "Bottom perforation": "Bp",
  "Taper Tap": "Tt",
  "Lace Joint": "Lj",
  "Window Mill": "Wim",
  "Plug Mill": "Pm",
  "Round Nose Mill": "Rnm",
  "Skirted Mill": "Sm",
  "Watermelon Mill": "Wam",
  "Tri-Blade Mill": "Trm",
  "Guide Mill": "Gm",
  "Tie Back Mill": "Tbm",
  "Washover Whipstock": "Waw",
  "MERlin Whipstock": "Mw",
  "RDS Whipstock": "Rw",
  "Workover Whipstock": "Wow",
  "Tubing Exit Whipstock": "Tew",
  "ML Packer / Whipstock": "Mpw",
  "Whipstock overshot": "Wo",
  "SB Pulling": "Sp",
  "Composite Deflector Pulling": "Cdp",
  "AHR Packer Running": "Apr",
  "Latch Cleaning": "Lac",
  "Hydraulic Running": "Hr",
  "PPH Packer": "Pp",
  "RHS Packer": "Rhs",
  "G-10 Packer": "Gp",
  "RSB Packer": "Rsb",
  "AHC Packer": "Ahc",
  "Retrievable Pump Packer": "Rpp",
  "Retrievable RH Packer": "Rhp",
  "THB Packer": "Thb",
  "Liner Hanger Packer": "Lhp",
  "AHR/C Parker": "Ahr",
  "RD parker": "Rdp",
  "RDH/DHC parker": "Rp",
  "MHR Parker": "Mhr",
  "Expansion Joint": "Ej",
  Perforation: "Pe",
  "Tubing type1": "Tt1",
  "Tubing type2": "Tt2",
  Pup: "Pup",
  "Pin up": "Pin",
  "Box up": "Bu",
  "Single hangers": "Sh",
  "Dual Hanger": "Dh",
  PBR: "Pbr",
  "Travel Jnts": "Tj",
  Ratch: "Ra",
  Latch1: "L1",
  Latch2: "L2",
  "X/Overs": "Xo",
  "4x5 type": "4x5",
  "5x4 type": "5x4",
  "Safety Valve Nipple": "Svn",
  "Landing Nipple": "Ln",
  "Seal Locator": "Sl",
  "Seal stack": "Set",
  Cent: "Ce",
  WEG: "Weg",
  "Fluted centrilisers 1": "Fc1",
  "Fluted centrilisers 2": "Fc2",
  "Mule Shoe": "Ms",
  "Shearable Mule shoe": "Sms",
  "Reciprocating shoe": "Res",
  Finder: "Fi",
  "Finder grab": "Fg",
  "Expandable grab": "Eg",
  Grab: "Gr",
  "Aligator grab": "Ag",
  HDFS: "Hd",
  HYDRO: "Hy",
  "HD Pulling Tool": "Hpt",
  "Tubing type 3": "Tt3",
  "Tubing type4": "Tt4",
  Flapper: "Fl",
  Ball: "Ba",
  "Mirage Plug": "Mip",
  "Side pocket mandrel": "Spm",
  "Gauge Mandrel": "Gam",
  "Lub V/V": "Lub",
  "Shearable Ball Seat": "Sbs",
  "Fluted centrilisers": "Fc",
  Coupling: "Co",
  SSV: "Ssv",
  "Pup Joint": "Pj",
  Nipple: "Ni",
  "Safety Valve": "Sv",
  "Pressure Gauge": "Pg",
};
