<template>
  <div  :style="{
    height: cHeight + 'px',
    width: '100%',
    display: 'flex'
  }" ref="myRef" 
    @click="handleClick">
     <svg v-if="percent" width='100%' height='100%' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none" stroke="#000" stroke-width="1.5" :opacity="isSelected ? 0.7 : 1" >
      <polygon :fill="cGapColor" 
        stroke="none" 
        :points="'0,0 '+ (percent) +',0 '+ 2 * percent +',100 '+ (0) +',100'" 
        />
      <polygon :fill="cGapColor"
        stroke="none"
        :points="'100,0 '+ (100-percent) +',0 '+ (100 - 2 * percent) +',100 '+ (100 - 0) +',100'" 
        />
      <line :x1="percent" y1="0" :x2="2 * percent" y2="100"/>
      <line :x1="100 - percent" y1="0" :x2="100 - 2 * percent" y2="100"/>
    </svg>
  </div>
</template>

<script>
import selection from '../mixins/selection';
import gapWidth from '../mixins/gapWidth';

export default {
  name: 'Crossover',
  props: ['options', 'height', 
    'id', 'casingType', 'fill'],
  data() {
    return {
      percent: 0
    }
  },
  computed: {
    cHeight() {
      return this.height || (this.options || {}).height || 10;
    },
    cGapColor() {
      return this.fill || 'transparent'
    },
  },
  mounted() {
    this.percent = Math.round((this.cGapWidth / this.$refs.myRef.clientWidth * 100) * 100 / 100);
  },
  mixins: [selection, gapWidth]
}
</script>

<style lang='scss' scoped>
  .Crossover {
    display: flex;
    justify-content: space-between;
    padding: 0 1px;
    align-items: flex-start;
    margin: auto;
  }
</style>