<template>
  <div :class="{'outlined': selectedDeviceId === options.id || isSelected }" :style="{
    position: 'absolute',
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*cOffsetLeft+ 'px)',
  }" @click="$emit('select', {id: options.id});">
    <svg :height="cHeight" :width="cWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
        :style="{
          stroke: cColor,
          left: '0px'
        }" :class="{Baker: true}">
        <rect x="0" y="0" width="100%" height="100%" :fill="cColor"/>
      </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import top from '../mixins/top';
import offsetLeft from '../mixins/offsetLeft';
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "Baker",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
    }),
    cHeight() {
      return this.cGapWidth;
    },
    cWidth() {
      return this.cGapWidth * 0.6;
    },
    cColor() {
      return '#c77334';
    },
  }
}
</script>

<style scoped lang='scss'>
  .Baker {
    position: absolute;
    z-index: 10;
  }
</style>
