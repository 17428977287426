var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{Perforation: true, 'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
  left: _vm.offsetLeft + 'px',
  top: _vm.cOffsetTop + 'px',
  height: _vm.cHeight + 'px',
  width: 'calc(100% - ' + 2*_vm.offsetLeft+ 'px)',
  zIndex: _vm.cNumOfParent,
}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id});}}},[_c('div',{style:({
    height: _vm.cHeight + 'px',
    width: _vm.cGapWidth * 2 + 'px',
  })}),_c('div',{style:({
    height: _vm.cHeight + 'px',
    width: _vm.cGapWidth * 2 + 'px',
  })})])
}
var staticRenderFns = []

export { render, staticRenderFns }