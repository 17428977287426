<template>
  <div :style="{
    position: 'absolute',
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*cOffsetLeft+ 'px)',
    display: 'flex',
    justifyContent: 'space-between'
  }" @click="$emit('select', {id: options.id})" :class="{'outlined': selectedDeviceId === options.id || isSelected}">
    <svg :height="cHeight" :width="cWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
      :style="{
        stroke: cColor,
      }" :class="{Nipple: true}">
      <rect x="0" y="0" width="100%" height="100%" :fill="cColor"/>
    </svg>
    <svg :height="cHeight" :width="cWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
      :style="{
        stroke: cColor,
      }" :class="{Nipple: true}">
      <rect x="0" y="0" width="100%" height="100%" :fill="cColor"/>
    </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "Nipple",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
    }),
    cHeight() {
      return this.cGapWidth;
    },
    cWidth() {
      return 8;
    },
    cColor() {
      return '#000';
    },
  }
}
</script>

<style scoped lang='scss'>
  .Nipple {
    z-index: 10;
  }
</style>
