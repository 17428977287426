<template>
  <div :style="{
      height: cHeight + 'px',
      width: '100%',
      margin: 'auto',
    }" :class="{Tubing: true}" @click="$emit('select', { id: options.id });">
    <div :style="{
      height: cHeight + 'px',
      width: _gapWdidth + 'px'
    }">
      <div v-for="(item, idx) in borderSpec" :key="idx"
        :style="{
          height: item.height + 'px',
          width: _gapWdidth + 'px',
          backgroundColor: cBorderFill[item.fill] || 'transparent'
        }">
      </div>
    </div>
    <div class="inner-border" style="flex: 1;">
      <slot></slot>
    </div>
    <div :style="{
      height: cHeight + 'px',
      width: _gapWdidth + 'px'
    }">
      <div v-for="(item, idx) in borderSpec" :key="idx"
        :style="{
          height: item.height + 'px',
          width: _gapWdidth + 'px',
          backgroundColor: cBorderFill[item.fill] || 'transparent'
        }">
      </div>
    </div>
  </div>
</template>

<script>
import selection from '../mixins/selection'
import gapWidth from '../mixins/gapWidth'

export default {
  name: "TubingInternal",
  props: ['options', 'height', 'width', 'id', 'borderSpec', "isBelowCrossOver"],
  computed: {
    cHeight() {
      return this.height || (this.options || {}).height;
    },
    cWidth() {
      return this.width || (this.options || {}).width || 100;
    },
    cActivityType() {
      let activityType = this.options.Properties.find(prop => prop.name === 'activityType');
      return activityType && activityType.value;
    },
    cBorderFill() {
      return {
        'good': 'rgb(160,87,10)',
        'bad': 'rgb(170,4,2)',
        'normal': 'rgb(113,113,113)',
        'none': 'transparent',
        'lead': 'rgb(207, 207, 207)',
        'tail': 'rgb(240, 214, 129)',
        'squeeze': 'rgb(80,101,57)'
      }
    },
    _gapWdidth() {
      if (this.isBelowCrossOver) {
        return 2 * this.cGapWidth
      }
      return this.cGapWidth
    } 
  },
  methods: {
  },
  mixins: [selection, gapWidth]
}
</script>

<style lang="scss" scoped>
  .Tubing {
    position: relative;
    border-top: none;
    border-bottom: none;
    box-sizing: border-box;
    border-image-repeat: round;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }
  .selected {
    box-shadow: 8px 2px 8px -4px #7caaf8, -8px 2px 8px -4px #7caaf8;
  }
</style>
