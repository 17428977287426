<template>
  <div class="md-title"
    :style="{
      marginBottom: zoom * 15 + 'px',
      position: 'absolute',
      top: top + 'px',
      left: left,
      right: right
    }">
    <span @click="onClick" :class="isSelected ? 'outlined' : ''"
      :style="{padding: '5px', display: 'flex', alignItems: 'center'}">
      <p><strong>{{getIndex(options.id)}}. </strong> {{cName}}</p>
      <!-- <md-button
        class="md-dense md-icon-button"
        @click="deleteActive = true">
        <md-icon>delete</md-icon>
        <md-tooltip md-direction="top">Delete</md-tooltip>
      </md-button> -->
    </span>
    <div v-if="lineWidth && left" :style="{
      position: 'absolute',
      top: '50%',
      left: '120px',
      borderTop: `1px solid black`,
      width: `${lineWidth}px`,
      zIndex: 100
    }"></div>
    <div v-if="lineWidth && right" :style="{
      position: 'absolute',
      top: '50%',
      right: '120px',
      borderTop: `1px solid black`,
      width: `${lineWidth}px`,
      zIndex: 100
    }"></div>
    <md-dialog-confirm
        :md-active.sync="deleteActive"
        md-content="<strong>Delete this node will delete all of its children.</strong>"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancelDelete"
        @md-confirm="onConfirmDelete"
      />
  </div>
</template>

<script>
import selection from '../mixins/selection';
import {mapGetters, mapState} from 'vuex'

export default {
  name: "Card",
  props: ['top', 'left', 'items', 'options', 'type', "right", 'lineWidth', 'lineTop'],
  data() {
    return {
      deleteActive: false,
    }
  },
  computed: {
    cName() {
      if (!this.options) {
        return 'Card';
      } else {
        return this.options.name || this.options.type;
      }
    },
    ...mapGetters({
      zoom: 'cZoom'
    }),
    ...mapState({
      wbsDevices: state => state.well.wbsDevices
    })
  },
  mixins: [selection],
  methods: {
    onClick(e) {
      this.handleClick && this.handleClick(e)
    },
    onCancelDelete() {
      this.deleteActive = false;
    },
    onConfirmDelete() {
      this.deleteActive = false;
      this.$store.dispatch("deleteWbsEquip", { equipId: this.cId });
    },
    getIndex(equipId) {
      return this.wbsDevices.map(equip => equip.id).indexOf(equipId) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-title {
    cursor: pointer;
    margin: 5px 0;
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    font-size: 16px;
    width: 100px;
    white-space: nowrap; 
    overflow: hidden;
    text-align: center
  }

  .outlined {
    background-color: #fff;
  }

  .md-icon-button.md-dense {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  .md-icon {
    width: 16px;
    min-width: 16px;
    height: 24px;
  }
</style>
