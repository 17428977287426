import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import math from '../utils/math.js';
import debounce from 'lodash.debounce';

const doRender = debounce(_doRender, 100);
function _doRender(obj) {
    requestAnimationFrame((t) => {
        if (obj._previousRAF == null) {
            obj._previousRAF = t;
        }
        if (obj.isRunning) doRender(obj);
        obj._threejs.render(obj._scene, obj._camera);
        obj._previousRAF = t;
    })
}

export default class Angle3d {
    constructor(_pointsData, _seaLevel, colors) {
        let seaLevel = _seaLevel / 5;
        let pointsData = _pointsData.map(p => ({
          azimuthAngle: p.azimuthAngle,
          deviationAngle: p.deviationAngle,
          deep: p.deep / 5
        }));
        this.isRunning = true;
        this.init(pointsData, seaLevel, colors);
    }

    init(pointsData, seaLevel, colors) {
        //init
        this._threejs = new THREE.WebGLRenderer({
            preserveDrawingBuffer: true,
            antialias: true,
        });

        this._threejs.outputEncoding = THREE.sRGBEncoding;
        this._threejs.shadowMap.enabled = true;
        this._threejs.shadowMap.type = THREE.PCFSoftShadowMap;
        this._threejs.setPixelRatio(window.devicePixelRatio);
        this._threejs.setSize(window.innerWidth - 20, window.innerHeight - 68);
        this._threejs.domElement.id = 'threejs';


        document.getElementById('container-3d').appendChild(this._threejs.domElement);
        window.addEventListener('resize', () => {
            this.windowResize();
        }, false);

        //camera
        const fov = 60;
        const aspect = window.innerWidth / window.innerHeight;
        const near = 1.0;
        const far = 30000;
        this._camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        this._camera.position.set(-600, 250, 530);
        // this._camera.lookAt(0, -200, 0);

        //scene
        this._scene = new THREE.Scene();
        //this._scene.background = new THREE.Color(0xf0f0f0);
        this._scene.background = new THREE.Color(0xffffff);

        this._orbitControls = new OrbitControls(this._camera, this._threejs.domElement);
        this._orbitControls.target = new THREE.Vector3(0, -100, 0);
        this._orbitControls.update();

        this.calcPointAngle(pointsData, colors);
        this.loadPlane(seaLevel);
        // this.loadLight();

        this.loadCoordinateAxis(100);

        _doRender(this);
    }
    stop() {
        console.log('stop', this);
        this.isRunning = false;
    }

    loadBendingBox(point, color) {
        const geometry = new THREE.BoxGeometry(5, 5, 5);
        const material = new THREE.MeshBasicMaterial({ color: color });
        const cube = new THREE.Mesh(geometry, material);
        cube.castShadow = true;
        cube.position.set(point.x, point.y, point.z);
        this._scene.add(cube);
    }
    calcPointAngle(pointsData, colors) {
        let points = math.getRefPointsFromBendingPoints(pointsData).map(p => (new THREE.Vector3(p.x, p.y, p.z)));
        for (let i = 0; i < points.length; i++) {
            let color = (i > 0) ? ((i < points.length - 1) ? colors[i % colors.length] : 0x0000ff) : 0x00ff00;
            this.loadBendingBox(points[i], color);
        }
        // Create Tube Geometry
        let tubeGeometry = new THREE.TubeGeometry(
            new THREE.CatmullRomCurve3(points),
            512,// path segments
            1.0,// THICKNESS
            8, //Roundness of Tube
            false //closed
        );
        const line = new THREE.Line(
            // new THREE.BufferGeometry().setFromPoints(points),
            tubeGeometry,
            new THREE.LineBasicMaterial({
                color: 'red',
                //linewidth: 5,
                //linecap: 'round', //ignored by WebGLRenderer
                //linejoin: 'round'
            }));

        this._scene.add(line);
    }

    calcPointAngle1(pointsData, colors) {
        let points = [];
        let x, y, z, point;


        for (let idx = 0; idx < pointsData.length; idx++) {
            let p = pointsData[idx]
            y = p.deep * (-1);
            x = (y * math.getTanFromDegrees(p.deviationAngle) * (-1)) / Math.sqrt(1 + Math.pow(math.getTanFromDegrees(p.azimuthAngle), 2));
            z = x * math.getTanFromDegrees(p.azimuthAngle);
            point = new THREE.Vector3(x, y, z);
            let color = colors[idx % colors.length];
            if (idx == 0) color = 0x00ff00;
            if (idx == (pointsData.length - 1)) color = 0x0000ff;
            this.loadBendingBox(point, color);
            points.push(point);
        }

        // Create Tube Geometry
        let tubeGeometry = new THREE.TubeGeometry(
            new THREE.CatmullRomCurve3(points),
            512,// path segments
            1.0,// THICKNESS
            8, //Roundness of Tube
            false //closed
        );
        const line = new THREE.Line(
            // new THREE.BufferGeometry().setFromPoints(points),
            tubeGeometry,
            new THREE.LineBasicMaterial({
                color: 'red',
                //linewidth: 5,
                //linecap: 'round', //ignored by WebGLRenderer
                //linejoin: 'round'
            }));


        this._scene.add(line);
    }

    calcPointAngle_axis(pointsData) {
        let points = [];
        const quaternion = new THREE.Quaternion();
        let x, y, z, point;
        let preDvAngle = 0;
        let preAzAngle = 0;

        for (let p of pointsData) {
            y = p.deep * (-1);
            x = (y * math.getTanFromDegrees(p.deviationAngle) * (-1)) / Math.sqrt(1 + Math.pow(math.getTanFromDegrees(p.azimuthAngle), 2));
            z = x * math.getTanFromDegrees(p.azimuthAngle);
            point = new THREE.Vector3(x, y, z);

            //rotate axis from y
            quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), math.getRadianFromDegrees(preAzAngle) * (-1));
            point.applyQuaternion();

            //rotate axis from z
            quaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), math.getRadianFromDegrees(preDvAngle));
            point.applyQuaternion(quaternion);

            points.push(point);
            preDvAngle = p.deviationAngle;
            preAzAngle = p.azimuthAngle;
        }

        const line = new THREE.Line(
            new THREE.BufferGeometry().setFromPoints(points),
            new THREE.LineBasicMaterial({ color: 0xff00ff, linewidth: 5 }));
        this._scene.add(line);
    }

    loadLight() {
        this._scene.add(new THREE.AmbientLight(0xf0f0f0));
        const light = new THREE.SpotLight(0xffffff, 1.5);
        light.position.set(0, 1500, 200);
        light.angle = Math.PI * 0.2;
        light.castShadow = true;
        light.shadow.camera.near = 200;
        light.shadow.camera.far = 2000;
        light.shadow.bias = - 0.000222;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        this._scene.add(light);
    }

    loadPlane(seaLevel) {
        const planeGeometry = new THREE.PlaneGeometry(1000, 1000);
        planeGeometry.rotateX(- Math.PI / 2);
        const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x0095ff, opacity: 0.5, transparent: true });

        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.position.y = - seaLevel;
        plane.receiveShadow = true;
        this._scene.add(plane);

        const helper = new THREE.GridHelper(1000, 25);
        helper.position.y = - seaLevel + 1;
        helper.material.opacity = 0.65;
        helper.material.transparent = true;
        this._scene.add(helper);
    }

    loadCoordinateAxis(size) {
        let point = new THREE.Vector3(0, 0, 0);
        let point_1 = new THREE.Vector3(size, 0, 0);
        let point_2 = new THREE.Vector3(0, size, 0);
        let point_3 = new THREE.Vector3(0, 0, size);
        let points = [];
        points.push(point);
        points.push(point_1);
        const line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), new THREE.LineBasicMaterial({ color: 'red' }));
        this._scene.add(line);

        points = []
        points.push(point);
        points.push(point_2);
        const line_2 = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), new THREE.LineBasicMaterial({ color: 'green' }));
        this._scene.add(line_2);

        points = []
        points.push(point);
        points.push(point_3);
        const line_3 = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), new THREE.LineBasicMaterial({ color: 'blue' }));
        this._scene.add(line_3);
    }

    windowResize() {
        this._camera.aspect = window.innerWidth / window.innerHeight;
        this._camera.updateProjectionMatrix();
        this._threejs.setSize(window.innerWidth, window.innerHeight);
    }

    render() {
        doRender(this);
    }

}
