<template>
  <div
    style="color: #00f;
        /*text-shadow: -1px -1px 0 #00f, 1px -1px 0 #00f, -1px 1px 0 #00f, 1px 1px 0 #00f;*/
        text-align: center;"
  >
    <div v-if="type === 'bha'" class="well-info">
      <md-button class="edit-btn" @click="editInfoShow = true">
        <md-icon>mode_edit</md-icon>
      </md-button>
      <div>
        <div v-if="cBhaInfo" class="info">
          <!-- <span><strong>BHA Name:</strong> {{ cBhaInfo.name }}</span>
          <span><strong>Created Date:</strong> {{ cBhaInfo.createdDate }}</span>
          <span><strong>Author:</strong> {{ cBhaInfo.author }}</span> -->
          <div>{{(cBhaInfo||{}).name}} / {{cBhaInfo.rigName}}</div>
          <div>{{cBhaInfo.createdDate}} / Author: {{cBhaInfo.author}}</div>
        </div>
      </div>
      <md-dialog
        :md-active.sync="editInfoShow"
        :md-click-outside-to-close="false"
      >
        <md-dialog-title>BHA Informations</md-dialog-title>
        <md-dialog-content>
          <md-field>
            <label>BHA Name</label>
            <md-input v-model="bha.name"></md-input>
          </md-field>
          <md-datepicker v-model="bha.createdDate" :md-immediately="true">
            <label>Created Date</label>
          </md-datepicker>
          <md-field>
            <label>Author</label>
            <md-input v-model="bha.author"></md-input>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions style="padding-right: 24px;">
          <md-button class="md-primary" @click="save">Save</md-button>
          <md-button class="md-primary" @click="editInfoShow = false"
            >Cancel</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </div>
    <div class="well-info" v-else>
      <md-button class="edit-btn" @click="editInfoShow = true">
        <md-icon>mode_edit</md-icon>
      </md-button>
      <div v-if="cWbsInfo" class="info">
        <div>{{(cWbsInfo||{}).name}} / {{cWbsInfo.rigFloor}} / {{cWbsInfo.rigName}}</div>
        <div>Slot: {{cWbsInfo.slot}}</div>
        <div>Depth (m): {{cWbsInfo.top}} / {{cWbsInfo.bottom}} / {{cWbsInfo.seaLevel}} / {{cWbsInfo.seaBed}}</div>
        <div>{{mdMode ? 'MD Mode Show' : 'TVD Mode Show'}}</div>
      </div>

      <md-dialog
        :md-active.sync="editInfoShow"
        :md-click-outside-to-close="false"
      >
        <md-dialog-title>WBS Informations</md-dialog-title>
        <md-dialog-content>
          <md-field>
            <label>WBS Name</label>
            <md-input v-model="wbs.name"></md-input>
          </md-field>
           <md-checkbox v-model="wbs.mdMode">MD Mode</md-checkbox>
          <md-field>
            <label>Rig Floor</label>
            <md-input v-model="wbs.rigFloor"></md-input>
          </md-field>
          <md-field>
            <label>Rig</label>
            <md-input v-model="wbs.rigName"></md-input>
          </md-field>
          <md-field>
            <label>Slot</label>
            <md-input v-model="wbs.slot"></md-input>
          </md-field>
          <md-field>
            <label>Top</label>
            <md-input v-model="wbs.top"></md-input>
          </md-field>
          <md-field>
            <label>Bottom</label>
            <md-input v-model="wbs.bottom"></md-input>
          </md-field>
          <md-field>
            <label>RT-MSL</label>
            <md-input type="number" v-model="wbs.seaLevel"></md-input>
          </md-field>
          <md-field>
            <label>RT - Seabed</label>
            <md-input type="number" v-model="wbs.seaBed"></md-input>
          </md-field>
          <md-field>
            <label>Water Depth</label>
            <md-input disabled v-model="cWaterDepth"></md-input>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions style="padding-right: 24px;">
          <md-button class="md-primary" @click="save">Save</md-button>
          <md-button class="md-primary" @click="editInfoShow = false"
            >Cancel</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "WellInfo",
  props: ["type", "cBhaInfo", "cWbsInfo"],
  data() {
    return {
      editInfoShow: false,
      bha: {
        name: "",
        createdDate: "",
        author: "",
      },
      wbs: {
        name: "",
        rigFloor: "",
        rigName: "",
        seaLevel: 0,
        seaBed: 0,
        top: "",
        bottom: "",
        mdMode: false
      },
      waterDepth: 0
    };
  },
  computed: {
    ...mapState({
      well: (state) => state.well.well
    }),
    ...mapGetters({
			mdMode: 'showInMd'
		}),
    cWaterDepth: {
      get: function() {
        if (this.cWbsInfo) {
          return this.wbs.seaBed - this.wbs.seaLevel
        }
        return 0;
      },
      set: function() {

      }
    }
  },
  mounted() {
    if (this.cBhaInfo) {
      Object.keys(this.cBhaInfo).forEach((key) => {
        this.bha[key] = this.cBhaInfo[key];
      });
    }
    if (this.cWbsInfo) {
      Object.keys(this.cWbsInfo).forEach((key) => {
        this.wbs[key] = this.cWbsInfo[key];
      });
    }
  },
  methods: {
    save() {
      this.editInfoShow = false;
      if (this.type === "bha") {
        this.$store.dispatch("editWellInfo", {
          bha: this.bha,
        });
      } else {
        this.$store.dispatch("editWellInfo", {
          wbs: this.wbs,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.well-info {
  display: flex;
  position: relative;
  align-items: center;
  // justify-content: space-between;
  color: rgb(25, 98, 167);
  padding: 0 15px;
  .info {
    display: flex;
    flex: 1;
    align-items: center;
    & > div {
      /*min-width: 200px;*/
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 1em;
      font-weight: 800;
    }
  }

  .edit-btn {
    padding: 0;
    min-width: 0px;
    margin: 0;
  }

}

.md-dialog ::v-deep.md-dialog-container {
    min-width: 400px;
}


</style>
