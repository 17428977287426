<template>
  <div class="DeviceLegend">
    <div class="item" v-for="(item,key) in LegendTable" :key="key">
      <Legend :signature="key" :description="full ? item.description : null" :type="type"/>
    </div>
  </div>
</template>
<script>
import {LegendTable, BhaLegendTable} from '../utils/legend.js'
import Legend from './Legend'
export default {
  name: "DeviceLegend",
  props: [
    "full",
    "type"
  ],
  components: {
    Legend
  },
  // data() {
  //   return {
  //     LegendTable: LegendTable
  //   }
  // },
  computed: {
    LegendTable() {
      if (this.type === 'BHA') {
        return BhaLegendTable
      }
      return LegendTable
    }
  }
}
</script>
<style lang="scss" scoped>
  .DeviceLegend {
    z-index: 100;
    position: relative;
    background-color: #fff;
    box-shadow: -1px 1px 2px 2px #ccc;
    display: block;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 500px;
    overflow: auto;
    > div {
      width: 45%;
    }
  }
  .item {
    display: inline-block;
    width: 200px;
    margin-bottom: 5px;
    z-index: 100;
    background-color: #fff;
  }
</style>
