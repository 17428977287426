var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'outlined': _vm.options.id === _vm.selectedDeviceId || _vm.isSelected},style:({
  position: 'absolute',
  width: 'calc(100% - ' + 2*_vm.cOffsetLeft+ 'px)',
  height: _vm.cGapWidth + 'px',
  left: _vm.cOffsetLeft + 'px',
  top: _vm.offsetTop + 'px',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: _vm.cNumOfParents,
  transform: 'translateY(-50%)'
}),on:{"click":function($event){return _vm.$emit('select', { id: _vm.options.id })}}},[_c('svg',{staticClass:"Shoe",attrs:{"width":_vm._gapWidth + 'px',"height":"100%","viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('polygon',{attrs:{"points":"0,0 0,100 100,0","stroke-width":"10px","stroke":_vm.cColor,"fill":"#eee"}}),_c('polygon',{attrs:{"points":"100,0 0,100 100,100","fill":_vm.cColor}})]),_c('svg',{staticClass:"Shoe",attrs:{"width":_vm._gapWidth + 'px',"height":"100%","viewBox":"0 0 100 100","preserveAspectRatio":"none"}},[_c('polygon',{attrs:{"points":"0,0 0,100 100,100","fill":_vm.cColor}}),_c('polygon',{attrs:{"points":"0,0 100,0 100,100","stroke-width":"10px","stroke":_vm.cColor,"fill":"#eee"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }