<template>
  <div class="well">
    <div class="search-well-container">
      <md-button class="md-primary" @click="create.isCreate = true">
        <md-icon>add</md-icon>
        New Well
      </md-button>
      <div class="search-well">
        <md-field>
          <md-input
            class="search"
            v-model="search"
            placeholder="Search"
          ></md-input>
        </md-field>
      </div>
    </div>

    <div class="well-list" v-if="ready">
      <md-card
        v-for="item in wellsFilter"
        :key="item.id"
        :class="{
          'md-elevation-3': true,
          'md-elevation-8': well && well.id == item.id,
          'well-item-selected': well && well.id == item.id,
          'well-item': true
        }"
        md-with-hover
      >
        <div @click="activeWell(item.id)">
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" style="word-wrap: break-all">
                <div class="well-16x16"></div>
                {{ item.name }}
              </div>
              <div class="md-subhead">id-{{ item.id }}-{{ item.company }}</div>
            </md-card-header-text>
            <md-menu md-size="medium" md-direction="bottom-end">
              <md-button
                class="md-icon-button"
                @click="(e) => e.stopPropagation()"
                md-menu-trigger
              >
                <md-icon>more_vert</md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item
                  @click="editWell(item.id, item.name, item.well_data)"
                >
                  <span>Edit</span>
                  <md-icon>edit</md-icon>
                </md-menu-item>
                <md-menu-item @click="cloneWell(item.id)">
                  <span>Clone</span>
                  <md-icon></md-icon>
                </md-menu-item>
                <md-menu-item @click="deleteWell(item.id)">
                  <span style="color: red">Delete</span>
                  <md-icon style="color: red">delete</md-icon>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-card-header>

          <md-card-content>
            Well Informations
          </md-card-content>

          <md-card-expand style="margin: 5px">
            <md-card-actions>
              <md-card-expand-trigger>
                <md-button class="md-icon-button">
                  <md-icon>keyboard_arrow_down</md-icon>
                </md-button>
              </md-card-expand-trigger>
            </md-card-actions>

            <md-card-expand-content>
              <md-card-content>
                <!-- <vue-json-pretty
                    :data="item.well_data"
                    :deep="1"
                    :showDoubleQuotes="false"
                  >
                  </vue-json-pretty> -->
                <div>
                  <span style="font-weight:600">Basin: </span>
                  {{ item.well_data.basin }}
                </div>
                <div>
                  <span style="font-weight:600">Block: </span>
                  {{ item.well_data.block }}
                </div>
                <div>
                  <span style="font-weight:600">Field: </span>
                  {{ item.well_data.field }}
                </div>
                <div>
                  <span style="font-weight:600">Well Type: </span>
                  {{ item.well_data.wellType }}
                </div>
                <div>
                  <span style="font-weight:600">Location(X,Y): </span>
                  {{ item.well_data.location }}
                </div>
              </md-card-content>
            </md-card-expand-content>
          </md-card-expand>

          <md-card-actions>
            <md-button
              class="md-button md-primary"
              @click="activeWell(item.id)"
            >
              {{ well && item.id === well.id ? "selected" : "select" }}
            </md-button>
          </md-card-actions>
        </div>
      </md-card>
    </div>

    <md-dialog-confirm
      :md-active.sync="delWell.isDelete"
      md-title="Do you want to delete?"
      md-content="This action will permanently delete this well in database and cannot be restored.
      Do you want to continue?"
      style="max-width: 720px; margin: auto"
      md-confirm-text="Delete"
      md-cancel-text="Cancel"
      @md-confirm="confirmDelete"
    />

    <md-dialog :md-active.sync="edit.isEdit" :md-click-outside-to-close="false">
      <md-dialog-title>Edit Well</md-dialog-title>
      <md-dialog-content>
        <div class="dialog-content">
          <div>
            <md-field>
              <label>Name <span style="color: red">*</span></label>
              <md-input v-model="edit.name"></md-input>
            </md-field>
            <md-field>
              <label>Basin</label>
              <md-input v-model="edit.well_data.basin"></md-input>
            </md-field>
            <md-field>
              <label>Block</label>
              <md-input v-model="edit.well_data.block"></md-input>
            </md-field>
          </div>
          <div>
            <md-field>
              <label>Field</label>
              <md-input v-model="edit.well_data.field"></md-input>
            </md-field>
            <md-field>
              <label>Well Type</label>
              <md-input v-model="edit.well_data.wellType"></md-input>
            </md-field>
            <md-field>
              <label>Location(X, Y)</label>
              <md-input v-model="edit.well_data.location"></md-input>
            </md-field>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          :disabled="!edit.name"
          @click="confirmEdit"
        >
          Edit
        </md-button>
        <md-button class="md-primary" @click="edit.isEdit = false"
          >Close</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="create.isCreate"
      :md-click-outside-to-close="false"
    >
      <md-dialog-title>New Well</md-dialog-title>
      <md-dialog-content>
        <div class="dialog-content">
          <div>
            <md-field>
              <label>Name <span style="color: red">*</span></label>
              <md-input v-model="create.name"></md-input>
            </md-field>
            <md-field>
              <label>Basin</label>
              <md-input v-model="create.well_data.basin"></md-input>
            </md-field>
            <md-field>
              <label>Block</label>
              <md-input v-model="create.well_data.block"></md-input>
            </md-field>
          </div>
          <div>
            <md-field>
              <label>Field</label>
              <md-input v-model="create.well_data.field"></md-input>
            </md-field>
            <md-field>
              <label>Well Type</label>
              <md-input v-model="create.well_data.wellType"></md-input>
            </md-field>
            <md-field>
              <label>Location(X, Y)</label>
              <md-input v-model="create.well_data.location"></md-input>
            </md-field>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="create.isCreate = false"
          >Close</md-button
        >
        <md-button
          class="md-primary"
          :disabled="!create.name"
          @click="confirmCreate"
        >
          Create
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="clone.isClone" :md-click-outside-to-close="false">
      <md-dialog-title>Clone Well</md-dialog-title>
      <md-dialog-content>
        <div class="dialog-content">
          <div class="clone-well-field">
            <md-field>
              <label>New well name <span style="color: red">*</span></label>
              <md-input v-model="clone.name"></md-input>
            </md-field>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="clone.isClone = false"
          >Close</md-button
        >
        <md-button
          class="md-primary"
          :disabled="!clone.name"
          @click="confirmClone"
        >
          Clone
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  name: "WellPage",
  components: {
    // VueJsonPretty,
  },
  data() {
    return {
      delWell: {
        id: null,
        isDelete: false,
      },
      edit: {
        id: null,
        name: null,
        well_data: {
          basin: null,
          block: null,
          field: null,
          wellType: null,
          location: null,
        },
        isEdit: false,
      },
      create: {
        name: null,
        well_data: {
          basin: null,
          block: null,
          field: null,
          wellType: null,
          location: null,
          zoom: 1,
          zoomFactor: 1.2,
          zoomWBS: 1,
          zoomBHA: 1,
          bha: {
            author: "",
            createdDate: "",
            name: ""
          },
          wbs: {
            top: 0,
            bottom: 0,
            name: "",
            rigFloor: "",
            rigName: "",
            slot: "",
            mdMode: true
          }
        },
        wbsShowOptions: '{}',
        bhaShowOptions: '{}',
        isCreate: false,
      },
      clone: {
        isClone: false,
        name: null,
        id_well: null,
      },
      search: "",
      ready: false,
    };
  },
  created() {
    const wellParam = new URLSearchParams(location.search).get('well');
    if (wellParam) {
      this.$watch(
        () => this.well && this.wells,
        () => {
          this.ready = true;
        }
      );
    } else {
      this.ready = true;
      this.$store.dispatch("getWells");
    }
  },
  computed: {
    ...mapState({
      wells: (state) => state.well.wells,
      well: (state) => state.well.well,
      message: (state) => state.well.message,
    }),
    wellsFilter() {
      if (this.search) {
        return this.wells.filter((well) => {
          let temp = this.search.toLowerCase();
          return well.name.toLowerCase().includes(temp);
        });
      }
      return this.wells;
    },
  },
  watch: {},
  methods: {
    deleteWell(wellId) {
      this.delWell.id = wellId;
      this.delWell.isDelete = true;
    },
    confirmDelete() {
      if (this.delWell.isDelete) {
        this.$store.dispatch("deleteWell", this.delWell.id);
      }
    },
    editWell(wellId, wellName, wellData) {
      this.edit.isEdit = true;
      this.edit.id = wellId;
      this.edit.name = wellName;
      this.edit.well_data = {
        ...wellData,
      };
    },
    confirmEdit() {
      this.$store.dispatch("editWell", {
        id: this.edit.id,
        name: this.edit.name,
        well_data: this.edit.well_data,
      });
      this.edit.isEdit = false;
    },
    createWell() {
      this.create.isCreate = true;
    },
    confirmCreate() {
      this.$store.dispatch("createWell", {
        name: this.create.name,
        well_data: this.create.well_data,
        wbsShowOptions: this.create.wbsShowOptions,
        bhaShowOptions: this.create.bhaShowOptions
      });
      this.create.name = null;
      for (let k in this.create.well_data) {
        this.create.well_data[k] = null;
      }
      this.create.isCreate = false;
    },
    activeWell(wellId) {
      this.$store.dispatch("getWell", wellId);
    },
    cloneWell(id_well) {
      this.clone = {
        ...this.clone,
        isClone: true,
        id_well
      }
    },
    confirmClone() {
      this.$store.dispatch("cloneWell", {
        name: this.clone.name,
        id_well: this.clone.id_well
      })
      this.clone = {
        isClone: false,
        name: null,
        id_well: null,
      }
      this.$store.dispatch("getWells")
    }
  },
};
</script>

<style lang="scss" scoped>
.well {
  .search-well-container {
    display: flex;
    align-items: center;
    > button {
      margin-left: 10px;
    }
  }
  .search-well {
    margin: auto;
    width: 450px;
  }

  .md-speed-dial {
    top: 60px;
  }

  .well-list {
    position: relative;

    .md-card {
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      width: 320px;
      margin: 15px;
      border-radius: 10px;
      box-sizing: border-box;

      .md-title,
      .md-subhead {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 240px;
      }

      .md-card-actions {
        .md-button {
          margin: 5px;
        }
      }
    }

    .well-item {
      border-radius: 10px;
    }

    .well-item-selected {
      border: 2px solid #1962a7;
    }
  }
}

.md-dialog {
  .md-dialog-content {
    width: 560px;
    .dialog-content {
      display: flex;
      justify-content: space-between;
      > div {
        width: 46%;
      }
      > div.clone-well-field {
        margin: auto;
        width: 60%;
      }
    }
  }
  .md-dialog-actions {
    padding-right: 24px;
  }
}
</style>
