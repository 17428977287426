var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{Tubing: true},style:({
    height: _vm.cHeight + 'px',
    width: '100%',
    margin: 'auto',
  }),on:{"click":function($event){return _vm.$emit('select', { id: _vm.options.id });}}},[_c('div',{style:({
    height: _vm.cHeight + 'px',
    width: _vm._gapWdidth + 'px'
  })},_vm._l((_vm.borderSpec),function(item,idx){return _c('div',{key:idx,style:({
        height: item.height + 'px',
        width: _vm._gapWdidth + 'px',
        backgroundColor: _vm.cBorderFill[item.fill] || 'transparent'
      })})}),0),_c('div',{staticClass:"inner-border",staticStyle:{"flex":"1"}},[_vm._t("default")],2),_c('div',{style:({
    height: _vm.cHeight + 'px',
    width: _vm._gapWdidth + 'px'
  })},_vm._l((_vm.borderSpec),function(item,idx){return _c('div',{key:idx,style:({
        height: item.height + 'px',
        width: _vm._gapWdidth + 'px',
        backgroundColor: _vm.cBorderFill[item.fill] || 'transparent'
      })})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }