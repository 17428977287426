<template>
  <div class="wbs">
    <div class="toolbar">
      <md-button class="md-icon-button md-raised" @click="showOptionDialog = true">
        <md-tooltip md-direction="bottom">options</md-tooltip>
        <md-icon>more_horiz</md-icon>
      </md-button>
      <md-button @click="resetZoom" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">reset</md-tooltip>
        <md-icon>crop_free</md-icon>
      </md-button>
      <md-button @click="zoomOut" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">zoom out</md-tooltip>
        <md-icon>zoom_out</md-icon>
      </md-button>
      <md-button @click="zoomIn" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">zoom in</md-tooltip>
        <md-icon>zoom_in</md-icon>
      </md-button>
      <md-button class="md-icon-button md-raised" @click="addEquipModalShow = true">
        <md-tooltip md-direction="bottom">Add equipment</md-tooltip>
        <md-icon>add</md-icon>
      </md-button>
      <md-button class="md-icon-button md-raised" @click="handleExport">
        <md-icon>print</md-icon>
        <md-tooltip md-direction="bottom">Export</md-tooltip>
      </md-button>
    </div>
    <div class="wbs-container">
      <div class="diagram-container" :style="{ minWidth: '350px' }" ref="wbsDiagramAll" @click="resetSelection">
        <div style="display:flex; align-items: center;">
          <well-info :type="'wbs'" :cWbsInfo="cWbsInfo" />
          <md-checkbox v-model="showAll">
            <label>Show all</label>
          </md-checkbox>
          <div style="flex: 1; text-align: center; padding: 0 2em;width: 180px; color: #1962a7 ;font-weight: 700;">{{cCheckDate}}</div>
        </div>
        <div style="display: flex; position: relative;" ref="wbsDiagram">
          <sea-level :sea-level="cWbsInfo.seaLevel || 0" :sea-bed="cWbsInfo.seaBed || 0"/>
          <Annotation v-if="cAnnotationShow" :devices="wbsDevices" :zoom="zoom" :selected-device-id="selectedDeviceId"
            @select="selectEvent($event);"/>
          <depth-axis v-if="cDepthAxisShow" :devices="wbsDevices" :zoom="zoom" :selected-device-id="selectedDeviceId"/>
          <div class="diagram"
            :style="{ width: cWbsDiagramWidth + 20 + 'px', height: cWbsDiagramHeight + 'px' }" >
            <component
              v-for="(item, idx) in wbsDevices"
              :key="idx"
              :is="item.type"
              :options="item"
              :selected-device-id="selectedDeviceId"
              @select="selectEvent($event)"
            ></component>
          </div>
        </div>
      </div>
      <div style="width: 564px;" class="wbs-device-info-container">
        <div :style="{
          width: expanded?'calc(100% - 80px)':'564px',
          height: 'calc(100vh - 48px)',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          right: 0,
          zIndex: 100,
          transition: 'width 0.25s'
        }">
          <wbs-device-info v-show="tabIdx === 1"
            :equip-id="selectedDeviceId"
            :on-close="false && closeWbsDeviceInfo"
            my-width="100%"
          />
          <div v-show="tabIdx === 2" class="side-panel shadow-border">
            <editable-device-table :selected-equipment-id="selectedDeviceId" :devices="wbsDevices"
              :fields="cEditTableFields"
              @select="selectEvent($event)" type="WBS" :check-date="checkDate"/>
          </div>
          <div v-show="tabIdx === 3" class="side-panel shadow-border">
            <history-view v-model="checkDate" :devices="rawWbsDevices" />
          </div>
          <div style="z-index: 1;text-align: right;">
            <div class='my-tab' :class="{'active-tab shadow-border': tabIdx === 1}" @click="tabIdx = 1">Details</div>
            <div class='my-tab' :class="{'active-tab shadow-border': tabIdx === 3}" @click="tabIdx = 3">History</div>
            <div class='my-tab' :class="{'active-tab shadow-border': tabIdx === 2}" @click="tabIdx = 2">List</div>
          </div>
          <div style="position:absolute; top: 50%;left:0;transform: translate(5px, -50%);
            cursor: pointer;" @click="expanded=!expanded">
            <md-icon>{{expanded?'chevron_right':'chevron_left'}}</md-icon>
          </div>
        </div>
      </div>
    </div>

    <new-wbs-device-dialog
      :is-show="addEquipModalShow"
      :selected-device-id="selectedDeviceId"
      :handle-close="closeNewWbsDialog"
    />

    <device-table-dialog
      :isShow="exportModalShow"
      :handle-close="closeExportModal"
      :deviceData="wbsDevices"
      :type="'wbs'"
      :well="well"
      :wbsInfo="cWbsInfo"
      :imageRef="$refs.wbsDiagram"
    />

    <wbs-option-dialog :open="showOptionDialog" :on-close="closeOptionDialog" />
  </div>
</template>
<script>
import eventManager from "../eventManager";
import { mapState, mapGetters } from "vuex";
import NewWbsDeviceDialog from "../components/NewWbsDeviceDialog.vue";
import WbsDeviceInfo from "../components/WbsDeviceInfo.vue";
import DeviceTable from "../components/DeviceTable.vue";
import EditableDeviceTable from "../components/EditableDeviceTable.vue";
import WellInfo from "../components/WellInfo.vue";
import WbsOptionDialog from '../components/WbsOptionDialog.vue'
import getLabel from "../mixins/getLabel"
import {wbsDefaultPriorityShowOptions} from '../utils/defaultPriority'

import SeaLevel from '../components/SeaLevel.vue'
import Annotation from '../components/Annotation.vue'
import DepthAxis from '../components/DepthAxis.vue'
import HistoryView from '../components/HistoryView.vue'
import { print } from '../utils/print.js';
export default {
  name: "WbsPage",
  components: {
    "new-wbs-device-dialog": NewWbsDeviceDialog,
    "wbs-device-info": WbsDeviceInfo,
    "device-table-dialog": DeviceTable,
    EditableDeviceTable,
    WellInfo,
    'wbs-option-dialog': WbsOptionDialog,
    SeaLevel,
    Annotation,
    DepthAxis,
    HistoryView
  },
  methods: {
    selectEvent(e) {
      if (e.id === this.selectedDeviceId) this.selectedDeviceId = null;
      else this.selectedDeviceId = e.id;
    },
    resetSelection(e) {
      e.preventDefault();
      eventManager.emit("click", { id: "reset" });
    },
    handleExport() {
      console.log(this.$refs.wbsDiagramAll, this.$refs['wbsDiagramAll'].scrollWidth, this.$refs['wbsDiagramAll'].scrollHeight);
      print(this.$refs.wbsDiagramAll,
          this.$refs['wbsDiagramAll'].scrollWidth,
          this.$refs['wbsDiagramAll'].scrollHeight, 'wbs.png');
    },
    closeNewWbsDialog() {
      this.addEquipModalShow = false;
    },
    closeWbsDeviceInfo() {
      this.selectedDeviceId = null;
    },
    closeExportModal() {
      this.exportModalShow = false;
    },
    zoomOut() {
      this.$store.dispatch("zoomWbs", { flag: "zoomOut", app: 'WBS' });
    },
    zoomIn() {
      this.$store.dispatch("zoomWbs", { flag: "zoomIn", app: 'WBS' });
    },
    resetZoom() {
      this.$store.dispatch("zoomWbs", { flag: "reset", app: 'WBS' });
    },
    save() {
      console.log(this.wbs);
      this.$store.dispatch("editWellInfo", {
        wbs: this.wbs,
      });
      this.editWbsInfoShow = false;
    },
    closeOptionDialog() {
      this.showOptionDialog = false
    },
  },
  data() {
    return {
      addEquipModalShow: false,
      exportModalShow: false,
      editWbsInfoShow: false,
      selectedDeviceId: null,
      showOptionDialog: false,
      max: Date.now(),
      checkDate: Date.now(),
      showAll: false,
      tabIdx: 2,
      temp: 3,
      expanded: false,
      eventManager: eventManager,
      wbs: {
        name: "",
        rigFloor: "",
        rigName: "",
        top: "",
        bottom: "",
      }
    };
  },
  computed: {
    ...mapState({
      well: (state) => state.well.well,
      wbsDevices(state) {
        if (this.showAll) {
          return state.well.wbsDevices || [];
        }
        let wbsDevs = state.well.wbsDevices.filter(equipment => {
          let startDate = equipment.Properties.find(p => p.name === "startTime").value;
          let stopDate = equipment.Properties.find(p => p.name === "stopTime").value;

          if (!startDate) return true;
          if (this.checkDate > new Date(startDate).getTime()) {
            if (!stopDate) return true;
            if (this.checkDate < new Date(stopDate).getTime()) return true;
          }
          return false;
        });
        return wbsDevs;
      },
      rawWbsDevices(state) {
        return state.well.wbsDevices || [];
      },
      wbsShowOptions: (state) => JSON.parse((state.well.well || {}).wbsShowOptions || '{}') || wbsDefaultPriorityShowOptions
    }),
    ...mapGetters({
      cWbsDiagramWidth: "cWbsDiagramWidth",
      numOfWbsDevice: "numOfWbsDevice",
      getChildren: "getChildren",
      zoom: "cZoomWBS",
      cWbsInfo: "cWbsInfo",
      convertLength: "convertLength",
    }),
    cMinTime() {
      return this.rawWbsDevices.reduce((oldMin, equpmnt) => {
        let startTime = equpmnt.Properties.find(p => p.name === "startTime").value;
        if (!startTime) return oldMin;
        startTime = new Date(startTime).getTime();
        if (oldMin > startTime) return startTime;
        return oldMin;
      }, Date.now());
    },
    cCheckDate() {
      return new Date(this.checkDate).toDateString();
    },
    cItems() {
      return this.getChildren("WBS", null);
    },
    cDisableSaveWbsInfo() {
      if (!this.cWbsInfo) {
        if (Object.values(this.wbs).some((val) => val)) {
          return false;
        }
        return true;
      }
      return Object.keys(this.cWbsInfo).every((key) => {
        return this.cWbsInfo[key] === this.wbs[key];
      });
    },
    zoomFactor: {
      get() {
        return this.$store.state.well.zoomFactor;
      },
      set(value) {
        this.$store.commit("setZoomFactor", { value });
      },
    },
    cDepthAxisShow() {
      return this.wbsShowOptions['depthAxisShow'] >= 0
      // return Object.keys(this.wbsShowOptions).indexOf('depthAxisShow') >= 0
    },
    cAnnotationShow() {
      return this.wbsShowOptions['annotationShow'] >= 0
      // return Object.keys(this.wbsShowOptions).indexOf('annotationShow') >= 0
    },
    cWbsDiagramHeight() {
      return this.convertLength(+this.cWbsInfo.seaBed + 5)
    },
    cEditTableFields() {
      let res = []
      for (const prop of Object.keys(this.wbsShowOptions)) {
        if (prop !== 'annotationShow' && prop !== 'depthAxisShow') {
          if (this.wbsShowOptions[prop] > 0) {
            if (prop == 'startTime' || prop == 'stopTime') {
              res.push({
                key: prop, label: this.getLabel(prop), type: 'md-datepicker', order: this.wbsShowOptions[prop]
              })
            } else {
              res.push({
                key: prop, label: this.getLabel(prop), type: 'md-field', order: this.wbsShowOptions[prop]
              })
            }
          }
        }
      }
      res.sort((a, b) => {
        if (a.order < b.order) {
          return 1
        } else if (a.order === b.order) {
          return 0
        }
        return -1
      })
      return res;
    }
  },
  created() {
    if (!this.well) {
      this.$router.push("/well");
    }
  },
  mounted() {
    eventManager.on("delete-device", ({ id }) => {
      if (this.selectedDeviceId == id) {
        this.selectedDeviceId = null;
      }
    });
    eventManager.on("click", ({ id }) => {
      if (this.selectedDeviceId != id && id !== "reset") {
        this.selectedDeviceId = id;
      }
    });
    eventManager.on('reset-time', () => {
      console.log('change time')
      this.checkDate = Date.now()
      this.max = Date.now()
    })
  },
  watch: {},
  mixins: [getLabel]
};
</script>
<style lang="scss" scoped>
.diagram {
  position: relative;
  margin: auto;
  margin-top: 15px;
  /*background-color: #f0f0f0;*/
  background-color: #fff;
}
.diagram-container {
  text-align: center;
  color: #2c3e50;
  position: relative;
  flex: 1;
  z-index: 1;
  border-radius: 10px;
}
.card-container {
  // min-width: 360px;
  width: 100%;
  left: 0;
  position: absolute;
  min-height: 80vh;
}
.wbs-container {
  position: relative;
  display: flex;
}

.wbs-device-info-container {
  height: calc(100vh - 48px);
  position: relative;
  /*transition: width 0.3s;*/
}

.md-dialog ::v-deep.md-dialog-container {
  min-width: 400px;
}
.toolbar {
  width: 60px;
  position: fixed;
  left: 20px;
  bottom: 10px;
  z-index: 220;
  background: none;
  & > button {
    margin: 10px 0;
  }
}
.my-tab {
  display: inline-block;
  padding: 4px 12px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}
.my-tab.active-tab {
  background-color: white;
  color: #1962a7;
  text-decoration: underline;
  font-weight: 700;
  border-right: 1px solid #ccc;
}
.side-panel {
  background-color: #fff;
  flex:1;
  width:100%;
  overflow:auto;
}
.wbs .md-icon {
  color: #ccc;
}
.wbs .md-icon:hover {
  color: #1962a7;
}
</style>
