<template>
  <div class="HistoryView">
    <div class="info-header">
      <h3>Activity Stream</h3>
      <h3 class="legend-button" :class="{'bordered': showLegend }" @click="showLegend = !showLegend"><md-icon>expand_more</md-icon></h3>
      <div v-show="showLegend">
        <device-legend :full="true" :type="type" />
      </div>
    </div>
    <div class="info-body">
      <div class="table">
        <div class="table-row table-header">
          <div class="indicator" style="border: none;"></div>
          <div class="flex-2">Date</div>
          <div class="flex-2">Equipment</div>
          <div class="flex-1">Activity</div>
        </div>
        <div v-for="(e, idx) in events" :key="idx" class="table-row" :class="{ 
                            'faded': e.time >= value
            }"
            @click="$emit('input', new Date(e.time.getTime() + 1).getTime())">
          <div class="indicator" :style="getRowStyle(e)"></div>
          <div class="flex-2">{{e.time.toDateString()}}</div>
          <div class="flex-2">
            <Legend x-style="display:inline-block;margin-right:0.5em;" :type="type" 
              :signature="reverseLegendTable[e.activityType]" />{{e.name}}</div>
          <div class="flex-1">
            <div class="tag" :class="{
              'added': e.eventType === 'Added',
              'hidden': e.eventType === 'Hidden'
            }">{{e.eventType}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center;width: 100%; padding: 10px;">
      <vue-range-slider v-if="cMinTime < cMaxTime" style="width: 100%" :min="cMinTime" :max="cMaxTime" :value="value" @input="$emit('input', $event)"/>
    </div>
  </div>
</template>
<script>
import VueRangeSlider from 'vue-range-slider'
import Legend from './Legend'
import DeviceLegend from './DeviceLegend'
import { reverseLegendTable, reverseBhaLegendTable } from '../utils/legend'
import palettes from '../utils/palettes'
export default {
  name: "HistoryView",
  props: ["value", "devices"],
  data() {
    return { 
      showLegend: false,
      type: 'WBS',
      cMaxTime: new Date().getTime(),
      palette: palettes.dark,
      events: []
    }
  },
  components: {
    VueRangeSlider,
    Legend, DeviceLegend
  },
  mounted() {
    this.generateEvents();
  },
  methods: {
    onInput($event) {
      console.log($event);
      this.$emit('input', $event.target.value)
    },
    getRowStyle(e) {
      let code = Math.abs(e.time.toDateString().hashCode());
      let color = this.palette[ code % this.palette.length];
      let style = (e.time < this.value) ? { 'backgroundColor': color, 'borderColor': color }: null;
      return style;
    },
    generateEvents() {
      let events = [];
      for (let d of this.devices) {
        let activityType = d.Properties.find(p => p.name === 'activityType').value;
        for (let p of d.Properties) {
          switch(p.name) {
            case 'startTime': {
              let startTime = p.value?new Date(p.value):new Date(null);
              events.push({ id: d.id, name: d.name, activityType, eventType: 'Added', time: startTime } );
              break;
            }
            case 'stopTime': {
              if (!p.value) break;
              let stopTime = new Date(p.value);
              events.push({ id: d.id, name: d.name, activityType, eventType: 'Hidden', time: stopTime } );
              break;
            }
          }
        }
      }
      this.events = events.sort((d1, d2) => (d1.time - d2.time));
    }
  },
  computed: {
    reverseLegendTable() {
      if (this.type === 'BHA') {
        return reverseBhaLegendTable
      }
      return reverseLegendTable;
    },
    cMinTime() {
      return (this.events[0]||{time: new Date(0)}).time.getTime();
    },
    /*cMaxTime() {
      return (this.events[this.events.length - 1] || {time: new Date(0)}).time.getTime() + 24 * 3600 * 1000;
    },*/
  },
  watch: {
    devices() {
      this.generateEvents()  
    }
  }
}
</script>
<style lang="scss">
.HistoryView {
  background-color: #fff;
  margin: 0px 5px 0px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 35px);

  .md-icon.md-theme-default.md-icon-image svg {
    fill: #ccc;
  }
  .md-icon:hover.md-theme-default.md-icon-image svg {
    fill: #1962a7;
  }
  .md-icon.md-theme-default.md-icon-font {
    color: #ccc;
  }
  .md-icon:hover.md-theme-default.md-icon-font {
    color: #1962a7;
  }

  .info-header {
    padding: 20px 0;
    position: relative;
    & > h3 {
      margin: 0;
      color: #2c3e50;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      display: inline-block;
    }
    & > h3.legend-button {
      position: relative;
      padding: 0px 10px;
      margin: 0 5px;
      z-index: 7;
      background-color: #fff;
    }
    & > h3.legend-button.bordered {
      border: 1px solid #ccc;
      border-bottom: none;
    }
    span {
      cursor: pointer;
    }
    & > div {
      position: absolute;
      left: -10px;
      right: 20px;
      top: 45px;
      z-index: 6;
    }
  }
  .info-body {
    border: 1px solid #f0f0f0;
    border-bottom: none;
    overflow: auto;
    flex: 1;
  }
  .table {
    display: flex;
    flex-direction: column;
    align-items: left;
    overflow: visible;
  }
  .table-row {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
  .table-header {
    font-weight: 700;
  }
  .table-row > div {
    border: 1px solid #eee;
    height: 46px;
    line-height: 1.9;
    padding: 10px;
    cursor: pointer;
  }
  .table-row.table-header > div {
    font-size: 13px;
    color: navy;
    height: 34px;
    line-height: 1;
  }
  .tag {
    border: 1px solid;
    padding: 0 3px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 700;
  }
  .added {
    color: green;
  }
  .hidden {
    color: blue;
  }
  .table-row.faded, .table-row.faded * {
    color: #ccc !important;
  }
  .md-icon.md-icon-font.tiny {
    font-size: 12px !important;
    width: 12px;
    height: 12px;
    min-width: 12px;
  }
  .table-row > div.indicator {
    flex-basis: 2px;
    padding: 0;
  }
}
</style>
