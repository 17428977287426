<template>
  <div :style="{ width: myWidth }" class="BhaDeviceInfo shadow-border">
    <md-empty-state v-if="!equipId" md-icon="code_off">
    </md-empty-state>
    <div v-show="equipId" class="BhaDeviceInfoMain">
      <div class="device-header">
        <h3>{{ cName || "Properties" }}</h3>
        <div v-show="equipId" class="v-toolbar">
          <md-button class="md-dense md-icon-button" @click="handleMove('up')">
            <md-icon>arrow_upward</md-icon>
            <md-tooltip md-direction="top">Move up</md-tooltip>
          </md-button>
          <md-button
            class="md-dense md-icon-button"
            @click="handleMove('down')"
          >
            <md-icon>arrow_downward</md-icon>
            <md-tooltip md-direction="top">Move down</md-tooltip>
          </md-button>
          <br />
          <!-- <md-button
            class="md-dense md-icon-button"
            @click="addPropDialogActive = true"
          >
            <md-icon>add</md-icon>
            <md-tooltip md-direction="top">Add new property</md-tooltip>
          </md-button> -->
          <!-- <md-button
            class="md-dense md-icon-button"
            @click="deletePropDialogShow = true"
          >
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top"
              >Choose to delete properties</md-tooltip
            >
          </md-button> -->
          <md-button class="md-dense md-icon-button" @click="deleteActive = true">
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top">Delete</md-tooltip>
          </md-button>
          <md-button
            class="md-dense md-primary"
            @click="save"
            :disabled="Object.keys(cPropertyChange).length <= 0"
            >OK</md-button
          >
        </div>
      </div>

      <div class="bha-info-body" v-if="cDevice">
        <div class="bha-info-layout">
          <md-field>
              <label>Group</label>
              <md-input v-model="cDevice.bha_device.group" disabled></md-input>
            </md-field>
            <md-field>
              <label>Device Name</label>
              <md-input v-model="cDevice.bha_device.device_name" disabled></md-input>
            </md-field>
          <div v-for="(key, idx) in cSortKeys" :key="idx">
            <md-field v-if="key == 'manufacture'">
              <custom-dropdown 
                name="manufacture"
                :options="cManufactureDropList"
                :label="'Manufacture'"
                :handleSelect="handleSelect"
                :placeholder="properties.manufacture"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'OD'">
              <custom-dropdown 
                name="OD"
                :options="cOdDropList"
                :label="'OD (in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.OD"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'ID'">
              <custom-dropdown 
                name="ID"
                :options="cIdDropList"
                :label="'ID (in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.ID"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'maxOd'">
              <custom-dropdown 
                name="maxOd"
                :options="cMaxOdDroplist"
                :label="'MaxOD (in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.maxOd"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'topSize'">
              <custom-dropdown 
                name="topSize"
                :options="cTopSizeDropList"
                :label="'Top Size (in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.topSize"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'bottomSize'">
              <custom-dropdown 
                name="bottomSize"
                :options="cBottomSizeDroplist"
                :label="'Bottom Size (in)'"
                :handleSelect="handleSelect"
                :placeholder="properties.bottomSize"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'topType'">
              <custom-dropdown 
                name="topType"
                :options="cTopTypeDropList"
                :label="'Top Type'"
                :handleSelect="handleSelect"
                :placeholder="properties.topType"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'bottomType'">
              <custom-dropdown 
                name="bottomType"
                :options="cBottomTypeDropList"
                :label="'Bottom Type'"
                :handleSelect="handleSelect"
                :placeholder="properties.bottomType"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'topGender'">
              <custom-dropdown 
                name="topGender"
                :options="cTopGenderDropList"
                :label="'Top Gender'"
                :handleSelect="handleSelect"
                :placeholder="properties.topGender"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else-if="key == 'bottomGender'">
              <custom-dropdown 
                name="bottomGender"
                :options="cBottomGenderDropList"
                :label="'Bottom Gender'"
                :handleSelect="handleSelect"
                :placeholder="properties.bottomGender"
                :isRequired="false"
              />
            </md-field>
            <md-field v-else>
              <label
                >{{ getLabel(key) }} {{ getPropertyUnit(key) }}
                <span v-if="required.includes(key)" style="color: red">*</span>
              </label>
              <md-input v-model="properties[key]" :name="key"></md-input>
            </md-field>
          </div>
          <div> 
            <md-field>
              <label>CumLength (m)</label>
              <md-input :value="cCumLength" :disabled="true" name="cumLength"></md-input>
            </md-field>
          </div>
        </div>
      </div>

      <div class="search-properties">
        <md-field>
          <md-input
            class="search"
            v-model="search"
            placeholder="Search properties"
          ></md-input>
        </md-field>
      </div>
      <delete-prop-dialog
        :properties="properties"
        :equip-id="equipId"
        app="BHA"
        :show="deletePropDialogShow"
        :handle-close="closeDeletePropDialog"
      />

      <new-equip-property
        :show="addPropDialogActive"
        :handle-close="closeAddPropDialog"
        :equip-name="cName"
        :equip-id="equipId"
        app="BHA"
      />

      <md-dialog-confirm
        :md-active.sync="deleteActive"
        md-content="<strong>Do you want to delete this equipment ?</strong>"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancelDelete"
        @md-confirm="onConfirmDelete"
      />
      
    </div>
    <div
      style="width: 30px; cursor: pointer;"
      class="v-toolbar hover-gray"
      @click="onClose"
    >
      <!-- <md-icon>navigate_before</md-icon> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DeletePropDialog from "./DeletePropDialog.vue";
import NewEquipProperty from "./NewEquipProperty.vue";
import DropdownInput from './DropdownInput.vue';
import getLabel from "../mixins/getLabel";
import deviceInfo from "../mixins/deviceInfo";
import bhaDroplist from '../mixins/bhaDroplist';

export default {
  name: "BhaDeviceInfo",
  data() {
    return {
      required: ["name"],
      reset: false,
      deleteActive: false
    };
  },
  components: {
    "delete-prop-dialog": DeletePropDialog,
    "new-equip-property": NewEquipProperty,
     'custom-dropdown': DropdownInput
  },
  mixins: [getLabel, deviceInfo, bhaDroplist],
  computed: {
    ...mapState({
      bhaDevices: (state) => state.well.bhaDevices,
    }),
    ...mapGetters({
      getDeviceById: "getDeviceById",
      getCumLength: "getCumLength"
    }),
    cDevice() {
      return this.getDeviceById("BHA", this.equipId);
    },
    cCumLength() {
      return this.getCumLength(this.equipId)
    },
    cSortKeys() {
      let keys = this.properties

      return Object.keys(keys)
        .filter(prop => prop.toString().toLowerCase().includes(this.search.toLowerCase()))
        .sort((a) => {
          if (a === 'name' || a === 'manufacture') {
            return -1;
          }
        })
    },
  },
  methods: {
    handleMove(move) {
      this.$store.dispatch("moveBhaEquip", {
        idEquip: this.equipId,
        move,
      });
    },
    handleSelect(value, name) {
      this.properties[name] = value.name
    },
    onBhaLengthChange({offsetLength}) {
      let equipId = this.cDevice.id
      let properties, cumLength
      let idx = this.bhaDevices.map(device => device.id).indexOf(equipId)
      if (idx >= 0) {
        for (let i = idx; i < this.bhaDevices.length; i++) {
        cumLength = this.bhaDevices[i].Properties.find(prop => prop.name === 'cumLength')
        if (cumLength) {
          properties = [{
            id: cumLength.id,
            name: "cumLength",
            value: Number(cumLength.value) + Number(offsetLength)
          }]
          this.$store.dispatch('editProperties', {
            equipId: this.bhaDevices[i].id,
            properties,
            app: 'BHA'
          })
        }
      }
      }
    },
    onCancelDelete() {
      this.deleteActive = false;
    },
    onConfirmDelete() {
      this.deleteActive = false;
      this.$store.dispatch("deleteBhaEquip", { equipId: this.cDevice.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  display: flex;
  align-items: center;
  & > button {
    margin: 0px 5px;
  }
}
.BhaDeviceInfo {
  height: calc(100vh - 48px);
  // height: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  // position: fixed;
  // z-index: 50;
}

.BhaDeviceInfoMain {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
}

.device-header {
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h3 {
    margin: 0;
    color: #2c3e50;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
}

.search-properties {
  border-top: 1px solid #f0f0f0;
}

.bha-info-body {
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  flex: 1;
}

label::first-letter {
  text-transform: uppercase;
}

.v-toolbar.hover-gray:hover {
  background-image: linear-gradient(90deg, transparent, #f0f0f0);
}


</style>
