var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
  position: 'absolute',
  top: _vm.offsetTop + 'px',
  left: _vm.cOffsetLeft +'px',
  width: 'calc(100% - ' + 2*_vm.cOffsetLeft + 'px)',
  zIndex: _vm.cNumOfParents
})},[_c('casing-internal',{key:_vm.cTop+' '+_vm.cBottom+' '+_vm.cHeight,attrs:{"options":_vm.options,"has-cross-over":_vm.cHasCrossOver,"top":_vm.cTop,"bottom":_vm.cBottom,"height":_vm.cHeight,"cross-over-pos":_vm.cCrossOverPos,"bending-pos":_vm.cBendingPos},on:{"select":function($event){return _vm.$emit('select', $event);}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }