<template>
  <div class="DepthAxis">
    <div v-for="(d, idx) in map" :key="'t-'+idx" class="tick" :class="{'outlined': d.id === selectedDeviceId}"
      :style="{
        top: `${d.valuePx}px`, 
      }">{{d.value}}</div>
  </div>
</template>
<script>
import { mapGetters} from 'vuex'
export default {
  name: "Annotation",
  props: ['devices', 'zoom', 'selectedDeviceId'],
  data() {
    return {map: []};
  },
  mounted() {
    this.updateMap();
  },
  computed: {
    ...mapGetters({
      convertLength: "convertLength",
      getShoeTop: "getShoeTop",
      getHangerTop: "getHangerTop",
      cZoom: 'cZoom',
      cWbsDiagramWidth: "cWbsDiagramWidth",
      getDeviceOffsetLeft: 'getDeviceOffsetLeft',
      mdMode: 'showInMd'
    }),
  },
  methods: {
    updateMap() {
      console.log("Update map");
      let map = [];
      this.devices.forEach((d) => {
        let tick = {
          name: d.name,
          id: d.id
        };
        for (let p of d.Properties) {
          switch(p.name) {
            case "activityType":
              tick.activityType = p.value;
              break;
            case "top": {
              tick.type = 'top';
              let topMd = d.Properties.find(p => p.name === 'topMd')
              if (topMd) {
                tick.value = topMd.value +  ' / ' + p.value;
              } else {
                tick.value = p.value;
              }
              if (this.mdMode) {
                tick.valuePx = this.convertLength(Number(topMd.value));
              } else {
                tick.valuePx = this.convertLength(Number(p.value));
              }
              break;
            }
          }
        }
        if (map.map(item => item.valuePx).indexOf(tick.valuePx) <= 0) {
          if (tick.value) map.push(tick);
        }
      });
      this.devices.forEach((d) => {
        let tick = {
          name: d.name,
          id: d.id
        };
        for (let p of d.Properties) {
          switch(p.name) {
          case "activityType":
            tick.activityType = p.value;
            break;
          case "bottom": {
              tick.type = 'bottom';
              let bottomMd = d.Properties.find(p => p.name === 'bottomMd')
              if (bottomMd) {
                tick.value = bottomMd.value +  ' / ' + p.value 
              } else {
                tick.value = p.value;
              }
              if (this.mdMode) {
                tick.valuePx =  this.convertLength(Number(bottomMd.value))
              } else {
                tick.valuePx = this.convertLength(Number(p.value));
              }
              break;
            }
          }
        }
        if (map.map(item => item.valuePx).indexOf(tick.valuePx) <= 0) {
          if (tick.value) map.push(tick);
        }
      });
      console.log(map);
      this.map = map;
    }
  },
  watch: {
    devices() {
      this.updateMap();
    },
    zoom() {
      this.updateMap();
    },
    mdMode() {
      this.updateMap();
    }
  }
}
</script>
<style lang="scss" scoped>
  .DepthAxis {
    z-index: 2;
    margin-top: 15px;
    position: absolute;
    left: 100px;
    width: 1px;
    height: 1px;
    .tick {
      position: absolute;
      width: 100px;
      display: flex;
      align-items: center;
      transform: translateY(-50%);
    }
    .tick:after, .tick:before {
      content: " ";
      display: inline-block;
      flex: 1;
      background-color: #ccc;
      height: 1px;
      margin: 0 3px;
    }
    .tick.outlined {
      border: none;
      color: red;
      font-weight: 700;
      background-color: #fff;
    }
    .tick.outlined:after, .tick.outlined:before {
      background-color: red;
    }
  }
</style>
