import dropList from '../utils/dropList.json'

export default {
  computed: {
    cManufactureDropList() {
      return dropList['BHA']['manufacture'].map(value => ({ name: value }))
    },
    cOdDropList() {
      return dropList['BHA']['od'].map(value => ({ name: value }));
    },
    cIdDropList() {
      return dropList['BHA']['id'].map(value => ({ name: value }));
    },
    cMaxOdDroplist() {
      return dropList['BHA']['maxOd'].map(value => ({ name: value }));
    },
    cTopSizeDropList() {
      return dropList['BHA']['topSize'].map(value => ({ name: value }));
    },
    cBottomSizeDroplist() {
      return dropList['BHA']['bottomSize'].map(value => ({ name: value }));
    },
    cBottomTypeDropList() {
      return dropList['BHA']['bottomType'].map(value => ({ name: value }));
    },
    cTopTypeDropList() {
      return dropList['BHA']['topType'].map(value => ({ name: value }));
    },
    cTopGenderDropList() {
      return dropList['BHA']['topGender'].map(value => ({ name: value }));
    },
    cBottomGenderDropList() {
      return dropList['BHA']['bottomGender'].map(value => ({ name: value }));
    }
  }
}