<template>
  <div :style="{
    left: offsetLeft + 'px',
    top: cOffsetTop + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*offsetLeft+ 'px)',
    zIndex: cNumOfParent,
  }" :class="{Perforation: true, 'outlined': selectedDeviceId === options.id || isSelected}" @click="$emit('select', {id: options.id});">
    <div :style="{
      height: cHeight + 'px',
      width: cGapWidth * 2 + 'px',
    }"></div>
    <div :style="{
      height: cHeight + 'px',
      width: cGapWidth * 2 + 'px',
    }"></div>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth'
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import bottom from '../mixins/bottom'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "Perforation",
  props: ['options', 'selectedDeviceId'],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver', 
      getNumOfParent: 'getNumOfParent',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return this.convertLength(this.cBottom - this.cTop);
    },
    offsetLeft() {
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      if (this.isAboveCrossOver(this.options.id_parent, top)) {
        return this.cOffsetLeft - this.cGapWidth * 3;
      }
      return this.cOffsetLeft - this.cGapWidth * 2;
    },
    cNumOfParent() {
      return this.getNumOfParent(this.options.id)
    }
  },
  mixins: [gapWidth, offsetLeft, selection, top, bottom],
}
</script>

<style lang='scss' scoped>
  .Perforation {
    height: 100%;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    > div {
      background-size: contain;
      background-repeat: round;
    }
    > div:first-child {
      background-image: url('../../public/perforation.svg');
    }
    > div:nth-child(2) {
      background-image: url('../../public/perforation-right.svg');
    }
  }

</style>
