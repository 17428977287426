<template>
  <div class="BorderSpecAdjustment">
    <div class="header">
      <p for="">Cement Intervals</p>
      <md-button class="md-icon-button" @click="handleAdd"
        ><md-icon>add</md-icon></md-button
      >
    </div>
    <div class="container">
      <div class="body" v-for="(spec, idx) in cFill" :key="idx">
        <md-field>
          <label>From(md)</label>
          <md-input
            v-model="spec.from"
            disabled
            style="overflow: hidden; margin-right: 6px"
          ></md-input>
        </md-field>

        <md-field
          :key="mdField_key"
          :class="{
            'md-invalid':
              spec.to < spec.from || spec.to > bottom ? true : false,
          }"
        >
          <label>To(md)</label>
          <md-input
            v-model="spec.to"
            style="overflow: hidden; margin-right: 6px"
            @change="
              (e) => {
                handleChange();
                handleInterval(spec.from, e, idx, cFill);
              }
            "
          >
          </md-input>
          <span class="md-error"
            >Value must be in range [{{ spec.from }}, {{ bottom }}]</span
          >
        </md-field>

        <md-field>
          <label>Cement Quality</label>
          <md-select
            class="cement"
            :class="{
              'cement-none': (spec || {}).fill === 'none',
              'cement-good': (spec || {}).fill === 'good',
              'cement-normal': (spec || {}).fill === 'normal',
              'cement-bad': (spec || {}).fill === 'bad',
              'cement-lead': (spec || {}).fill === 'lead',
              'cement-tail': (spec || {}).fill === 'tail',
              'cement-squeeze': (spec || {}).fill === 'squeeze',
            }"
            v-model="spec.fill"
            name="borderSpec"
            @md-selected="(val) => handleFill(val, idx)"
          >
            <md-option :value="'none'"
              ><div class="cement-none">None</div></md-option
            >
            <md-option :value="'good'"
              ><div class="cement-good">Good</div></md-option
            >
            <md-option :value="'normal'"
              ><div class="cement-normal">Normal</div></md-option
            >
            <md-option :value="'bad'"
              ><div class="cement-bad">Bad</div></md-option
            >
            <md-option :value="'lead'"
              ><div class="cement-lead">Lead</div></md-option
            >
            <md-option :value="'tail'"
              ><div class="cement-tail">Tail</div></md-option
            >
            <md-option :value="'squeeze'"
              ><div class="cement-squeeze">Squeeze</div></md-option
            >
          </md-select>
        </md-field>

        <a class="icon" @click="handleDelete(idx)">
          Remove
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BorderSpecAdjustment",
  props: [
    "top",
    "bottom",
    "borderSpecs",
    "handleAdd",
    "handleDelete",
    "handleInterval",
    "handleFill",
  ],
  data() {
    return {
      mdField_key: true,
      cFill: [],
    };
  },
  mounted() {
    this.parseBorderSpecs();
  },
  watch: {
    borderSpecs() {
      this.parseBorderSpecs();
    },
  },
  methods: {
    handleChange() {
      this.mdField_key = !this.mdField_key;
    },
    parseBorderSpecs() {
      let list = [];
      let cumInterval = 0;
      this.borderSpecs.forEach((x, idx) => {
        let result = {};
        if (cumInterval >= 1) return;
        let interval = Math.min(x.interval, 1 - cumInterval);
        cumInterval += interval;

        if (idx === 0) {
          result.from = this.top;
          result.to = this.top + interval * (this.bottom - this.top);
        } else {
          result.from = list[idx - 1].to;
          result.to = list[idx - 1].to + interval * (this.bottom - this.top);
        }
        result.fill = x.fill;
        list.push(result);
      });
      this.cFill = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.BorderSpecAdjustment {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
  }
  .container {
    padding-left: 20px;
    border: none;
    border-left: 3px solid #eee;
    .body {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      .icon {
        cursor: pointer;
        padding: 0 0 0 1em;
      }
    }
  }
  .md-field {
    width: auto;
    overflow: hidden;
  }
}
</style>
