<template>
  <div  :class="{'outlined': options.id === selectedDeviceId || isSelected}" :style="{
    position: 'absolute',
    width: 'calc(100% - ' + 2*cOffsetLeft+ 'px)',
    height: cGapWidth + 'px',
    left: cOffsetLeft + 'px',
    top: offsetTop + 'px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: cNumOfParents,
    transform: 'translateY(-50%)'
  }" @click="$emit('select', { id: options.id })">
    <svg :width=" _gapWidth + 'px'" height='100%' viewBox="0 0 100 100" class="Shoe"
      preserveAspectRatio="none">
      <polygon points="0,0 0,100 100,0"
          stroke-width="10px" :stroke="cColor"
              fill="#eee"/>
      <polygon points="100,0 0,100 100,100"
              :fill="cColor"/>
    </svg>
    <svg :width=" _gapWidth + 'px'" height='100%' viewBox="0 0 100 100" class="Shoe"
      preserveAspectRatio="none">
      <polygon points="0,0 0,100 100,100"
        :fill="cColor"/>
      <polygon points="0,0 100,0 100,100"
        stroke-width="10px" :stroke="cColor"
            fill="#eee"/>
    </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth'
import top from '../mixins/top'
import {mapGetters} from 'vuex'

export default {
  name: "Shoe",
  props: ['options', 'color', 'selectedDeviceId',
      'handleClick', 'isSelected', 'offsetLeft'],
  computed: {
    ...mapGetters({
      getShoeTop: 'getShoeTop',
      convertLength: 'convertLength',
      getNumOfParent: 'getNumOfParent',
      getDeviceById: 'getDeviceById',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return this.cGapWidth;
    },
    cColor() {
      // return this.color || this.options.color || '#000';
      return '#000';
    },
    offsetTop() {
      let top = this.options.Properties.find(prop => prop.name === 'top')
      if (!top) {
        return this.getShoeTop(this.options.id);   
      }
      return this.cOffsetTop;
    },
    cOffsetLeft() {
      return this.offsetLeft
    },
    cNumOfParents() {
      return this.getNumOfParent(this.options.id);
    },
    _gapWidth() {
      let parent = this.getDeviceById("WBS", this.options.id_parent)
      if (!parent) {
          return 0;
      }
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd')
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top')
      }
      if (top) {
          top = Number(top.value)
          let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
          let crossOverPos = parent.Properties.find(prop => prop.name === 'crossOverPos')
          if (!hasCrossOver || !Number(hasCrossOver.value)) {
              return this.cGapWidth
          } else {
            let parentTop, parentBottom
            if (this.mdMode) {
              parentTop = Number(parent.Properties.find(prop => prop.name === 'topMd').value)
              parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottomMd').value)
            } else {
              parentTop = Number(parent.Properties.find(prop => prop.name === 'top').value)
              parentBottom = Number(parent.Properties.find(prop => prop.name === 'bottom').value)
            }
            crossOverPos = Math.round((Number(crossOverPos.value) / 100 * (parentBottom - parentTop)) + parentTop)
            if (top > crossOverPos) {
                return 2 * this.cGapWidth
            }
          }
          return this.cGapWidth
      }
      let hasCrossOver = parent.Properties.find(prop => prop.name === 'hasCrossOver')
      if (hasCrossOver && Number(hasCrossOver.value) && this.options.terminatorType === 'Shoe') {
          return 2 * this.cGapWidth + 2
      }
      return this.cGapWidth
    }
  },
  mixins: [gapWidth, top]
}
</script>

<style scoped lang='scss'>
  .Shoe {
    z-index: 10;
    // position: absolute;
  }
  .selected {
    filter: drop-shadow(0 0 8px #0958e0);
  }
</style>
