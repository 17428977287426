<template>
  <md-dialog :md-active.sync="show" :md-click-outside-to-close="false">
    <md-dialog-title>Add new property to {{equipName}}</md-dialog-title>
    <md-dialog-content>
      <md-field>
        <label >Property name</label>
        <md-input v-model="name"></md-input>
      </md-field>
      <md-datepicker v-model="value" v-if="unit == 'date'" :md-immediately="true">
        <label>Property value</label>
      </md-datepicker>
      <md-field v-else>
        <label>Property value</label>
        <md-input v-model="value" :type="unit == 'string' ? 'text' : 'number'"></md-input>
      </md-field>
      <md-field>
        <label>Property unit</label>
        <md-select v-model="unit" :placeholder="unit">
            <md-option value="m">m</md-option>
            <md-option value="ton">ton</md-option>
            <md-option value="lb">Lb</md-option>
            <md-option value="in">in</md-option>
            <md-option value="date">Datetime</md-option>
            <md-option value="string">String</md-option>
            <md-option value="degree">Degree</md-option>
          </md-select>
      </md-field>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="addProperty"
        :disabled="!(name && value && unit)">Add</md-button>
      <md-button class="md-primary" @click="close">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
export default {
  name: "NewEquipProperty",
  props: ['show', 'handleClose', 'equipName', 'equipId', 'app'],
  data() {
    return {
      name: '',
      value: '',
      unit: ''
    }
  },
  methods: {
    addProperty() {
      this.$store.dispatch('addProperty', {
        equipId:this.equipId, 
        name: this.name,
        value: this.value,
        unit: this.unit, 
        app: this.app
      })
      this.name = ''
      this.value = ''
      this.unit = ''
      this.handleClose()
    },
    close() {
      this.name = ''
      this.value = ''
      this.unit = ''
      this.handleClose()
    }
  }
}
</script>

<style>

</style>