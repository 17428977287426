const wbsDefaultPriorityShowOptions = {
    annotationShow: 0,
    depthAxisShow: 0,
    startTime: 8,
    stopTime: 7,
    OD: 1,
    ID: 1,
    length: 2,
    weight: 2,
    top: 9,
    bottom: 9,
    topMd: 10,
    bottomMd: 10,
    note: 1,
    comment: 1,
    grade: 4,
    connection: 4,
    PPFG: 4,
    MW: 4,
    mudType: 5,
    drillingRotation: 5,
    flowRate: 5,
    ROP: 6,
    holeSelection: 6
}

const bhaDefaultPriorityShowOptions = {
    manufacture: 14,
    serialNumber: 13,
    description: 12,
    OD: 11,
    ID: 10,
    maxOd: 9,
    topSize: 8,
    bottomSize: 7,
    topType: 6,
    bottomType: 5,
    topGender: 4,
    bottomGender: 3,
    length: 2,
    cumWeight: 1,
}

export {wbsDefaultPriorityShowOptions, bhaDefaultPriorityShowOptions}