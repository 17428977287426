<template>
  <div :class="{'outlined': selectedDeviceId === options.id || isSelected}" :style="{
    position: 'absolute',
    top: cOffsetTop + 'px',
    left: _offsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*_offsetLeft+ 'px)',
    display: 'flex',
    justifyContent: 'space-between'
  }" @click="$emit('select', {id: options.id})">
     <svg :height="cHeight" :width="cWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
       :class="{Centralizer: true}">
      <circle :cx="cWidth * 3/2" :cy="cWidth" :r="cWidth" />
    </svg>
    <svg :height="cHeight" :width="cWidth" version="1.1" xmlns="http://www.w3.org/2000/svg"
      :class="{Centralizer: true}">
      <circle :cx="-cWidth/2" :cy="cWidth" :r="cWidth"/>
    </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import top from '../mixins/top'
import offsetLeft from '../mixins/offsetLeft';
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 

export default {
  name: "Centralizer",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return 2 * this.cGapWidth;
    },
    cWidth() {
      return this.cGapWidth;
    },
    _offsetLeft() {
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      if (this.isAboveCrossOver(this.options.id_parent, top)) {
        return this.cOffsetLeft - 2 * this.cGapWidth -2 ;
      }
      return this.cOffsetLeft - this.cGapWidth - 2;
    }
  }
}
</script>

<style scoped lang='scss'>
  .Centralizer {
    z-index: 10;
    fill: transparent;
    stroke: #000;
    stroke-width: 2;
  }
  .selected {
    filter: drop-shadow(0 0 5px #053588);
  }
</style>
