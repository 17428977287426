import Vue from "vue";
Vue.config.productionTip = false;
import VueRouter from "vue-router";
Vue.use(VueRouter);
import store from "./store";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "./styles.scss";
//import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial);

import App from "./App.vue";

import Group from "./components/Group.vue";
import Casing from "./components/Casing.vue";
import Terminator from "./components/Terminator.vue";
import Shoe from "./components/Shoe.vue";
import Parker from "./components/Parker.vue";
import Hanger from "./components/Hanger.vue";
import Perforation from "./components/Perforation.vue";
import Crossover from "./components/Crossover.vue";
import TubingInternal from "./components/TubingInternal.vue";
import CardGroup from "./components/CardGroup.vue";
import BhaDevice from "./components/BhaDevice.vue";
import Nipple from "./components/Nipple.vue";
import Baker from "./components/Baker";
import Valve from "./components/Valve";
import Centralizer from "./components/Centralizer";
import Tieback from "./components/Tieback";
import CementPlug from "./components/CementPlug";
import ControlLine from "./components/ControlLine";
import LandingCollar from "./components/LandingCollar";
import Plug from "./components/Plug";
import WhipstockLeft from "./components/WhipstockLeft";
import WhipstockRight from "./components/WhipstockRight";

import WellPage from "./pages/well";
import WBSPage from "./pages/wbs";
import BHAPage from "./pages/bha";
import BHADevicePage from "./pages/bha-device";
import Angle3dPage from "./pages/angle3d";

Vue.component("Group", Group);
Vue.component("Casing", Casing);
Vue.component("Terminator", Terminator);
Vue.component("Shoe", Shoe);
Vue.component("Parker", Parker);
Vue.component("Hanger", Hanger);
Vue.component("Perforation", Perforation);
Vue.component("Crossover", Crossover);
Vue.component("TubingInternal", TubingInternal);
Vue.component("CardGroup", CardGroup);
Vue.component("BhaDevice", BhaDevice);
Vue.component("Nipple", Nipple);
Vue.component("Baker", Baker);
Vue.component("Valve", Valve);
Vue.component("Centralizer", Centralizer);
Vue.component("Tieback", Tieback);
Vue.component("CementPlug", CementPlug);
Vue.component("ControlLine", ControlLine);
Vue.component("LandingCollar", LandingCollar);
Vue.component("Plug", Plug);
Vue.component("WhipstockLeft", WhipstockLeft);
Vue.component("WhipstockRight", WhipstockRight);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/well",
    },
    {
      path: "/well",
      component: WellPage,
    },
    {
      path: "/wbs",
      component: WBSPage,
    },
    {
      path: "/bha",
      component: BHAPage,
    },
    {
      path: "/bha-device",
      component: BHADevicePage,
    },
    {
      path: "/well-trajectory",
      component: Angle3dPage,
    },
  ],
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
