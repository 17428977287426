export default {
	computed: {
		grades() {
			return [
				{name: ' '},
				{name: 'K-55'},
				{name: 'N-80'}, 
				{name: 'L-80'}, 
				{name: 'C-95'}, 
				{name: 'P110'},
				{name: 'Q125'}]
		},
		connections() {
			return [
				{name: ' '},
				{name: 'BTC'},
				{name: 'LTC'}, 
				{name: 'hydril 563'}, 
				{name: 'hydril 511'}, 
				{name: 'hydril 521'},
				{name: 'New Vam'},
				{name: 'Vam Top'},
				{name: 'Vam ACE'},
				{name: 'Vam Pro'},
				{name: 'Vam FJL'},
				{name: 'TMK PF'},
				{name: 'TSH blue'},
				{name: 'JFE Bear'},
				{name: 'Vam top SC'},
				{name: 'Vam Top HT'}
			];
		},
		mudTypes() {
			return [
				{name: ' '},
				{name: 'WBM'},
				{name: 'OBM'}, 
				{name: 'SBM'}, 
				{name: 'NA'}
			];
		}
	}
}