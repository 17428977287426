<template>
  <md-dialog :md-active.sync="open" :md-click-outside-to-close="false" :md-close-on-esc="false">
      <md-dialog-title>Diagram Options</md-dialog-title>
      <md-dialog-content>
        <md-checkbox v-model="selectAll">
          <label style="font-size: 18px;">Select all</label>
        </md-checkbox>
        <fieldset>
          <legend>Select properties for show</legend>
          <div class="option-list">
            <md-checkbox v-for="key in Object.keys(showOptions)" :key="key"
              v-model="showOptions[key]">
              <label>{{getLabel(key)}}</label>
            </md-checkbox>
          </div>
        </fieldset>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose">Cancel</md-button>
        <md-button class="md-primary" @click="handleSave">Ok</md-button>
      </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { bhaDefaultPriorityShowOptions } from '../utils/defaultPriority'
import getLabel from "../mixins/getLabel";

export default {
  name: 'BhaOptionDialog',
  props: ['open', 'onClose'],
  data() {
    return {
      selectAll: false,
      showOptions: {
        manufacture: false,
        serialNumber: false,
        description: false,
        OD: false,
        ID: false,
        maxOd: false,
        topSize: false,
        bottomSize: false,
        topType: false,
        bottomType: false,
        topGender: false,
        bottomGender: false,
        length: false,
        cumWeight: false,
      }
    }
  },
  computed: {
    ...mapState({
      bhaShowOptions: (state) => JSON.parse((state.well.well || {}).bhaShowOptions || '{}') || bhaDefaultPriorityShowOptions
    }),
    defaultPriority() {
      return bhaDefaultPriorityShowOptions
    }
  },
   methods: {
      handleSave() {
        let obj = {}
        for (const key in this.showOptions) {
          if (this.showOptions[key]) {
            obj[key] = this.defaultPriority[key]
          }
        }
        this.$store.dispatch("editWellOptions", {optionName: 'BHA', bhaShowOptions: JSON.stringify(obj)})
        this.onClose()
      },
      isSelectAll() {
        for (const key in this.showOptions) {
          if (!this.showOptions[key]) return false;
        }
        return true;
      }
  },
  mounted() {
     for (const prop in this.bhaShowOptions) {
      this.showOptions[prop] = this.bhaShowOptions[prop] > -1
    }
    this.selectAll = this.isSelectAll()
  },
  watch: {
    selectAll(val) {
      if (val) {
        for (const key in this.showOptions) {
          this.showOptions[key] = true
        }
      } else {
        for (const prop in this.showOptions) {
          this.showOptions[prop] = false
        }
      }
    }
  },
  mixins: [getLabel]
}
</script>

<style lang="scss" scoped>
  .md-dialog ::v-deep .md-dialog-container {
    width: 600px;
  }
  .md-dialog-title {
    margin-bottom: 0;
  }
  fieldset {
    max-width: 560px;
  }
  legend {
    font-size: 16px;
  }
  .option-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    max-height: 370px;
    > div {
      width: 45%
    }
  }
  label {
    text-transform: capitalize;
  }
</style>
