<template>
  <md-dialog :md-active.sync="show" :md-click-outside-to-close="false">
    <md-dialog-title>
      <span>
        Delete properties of {{properties.name}}
      </span>
      <md-checkbox
            v-model="selectAll">Select all</md-checkbox>
    </md-dialog-title>
    <md-dialog-content>
      <div class="search-properties">
          <md-field>
            <md-input
              class="search"
              v-model="search"
              placeholder="Search properties"
            ></md-input>
          </md-field>
        </div>
      <div class="dialog-layout">
        <div v-for="prop of filterKeys" :key="prop" >
          <md-checkbox
            v-model="deleteProperties" :value="prop">
            <label :class="{'required-field': checkInRequireList(prop)}">{{getLabel(prop)}}
            </label>
            </md-checkbox>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="handleDelete"
        :disabled="!deleteProperties.length">Delete</md-button>
      <md-button class="md-primary" @click="close">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import getLabel from '../mixins/getLabel';

export default {
  name: 'DeletePropDialog',
  props: ['properties', 'handleClose', 'show', 'app', 'equipId', 'requireList'],
  data() {
    return {
      deleteProperties: [],
      search: '',
      selectAll: false
    }
  },
  mixins: [getLabel],
  computed: {
    filterKeys() {
      return Object.keys(this.properties)
        .filter(prop => {
          if (prop === 'id_parent') {
            return false
          }
          if (prop !== 'type' && prop !== 'terminatorType') {
            if (!this.search) {
              return true;
            }
            return prop.toString().toLowerCase().includes(this.search.toLowerCase());
          }
          return false;
        })
        .sort(prop => {
          if (this.checkInRequireList(prop)) {
            return -1;
          }
        })
    }
  },
  methods: {
    handleDelete() {
      console.log(this.deleteProperties)
      this.$store.dispatch("deleteProperties", {
        equipId: this.equipId,
        app: this.app,
        properties: this.deleteProperties
      })
      this.handleClose()
      this.search = ""
      this.deleteProperties = []
    },
    close() {
      this.deleteProperties = []
      this.search = ""
      this.handleClose()
    },
    checkInRequireList(name) {
      if (this.requireList && this.requireList.length) {
        return this.requireList.findIndex(prop => prop === name) >= 0;
      }
      return false;
    }
  },
  watch: {
    selectAll(newVal) {
      if (newVal) {
        this.deleteProperties.push(...Object.keys(this.properties))
      } else {
        this.deleteProperties = []
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .md-dialog ::v-deep.md-dialog-container {
    width: 768px;
    height: 768px;
  }
  .dialog-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 360px;
    overflow: auto;
    > div {
      width: 45%;
      max-height: 50px;
    }
  }
  .md-dialog-content {
    overflow: hidden;
  }
  .search-properties {
    max-width: 500px;
    margin: auto;
    > div {
      margin-bottom: 10px;
    }
  }
  .md-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  label {
    text-transform: capitalize;
  }

  label.required-field::after {
    color: red;
    content: '*';
    font-size: 20px;
    position: relative;
    right: 0;
    top: 5px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #FFF;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>