var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{Valve: true, 'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected},style:({
  top: _vm.cOffsetTop + 'px',
  left: _vm.cOffsetLeft + 'px',
  height: _vm.cHeight + 'px',
  width: 'calc(100% - ' + 2*_vm.cOffsetLeft+ 'px)',
  backgroundColor: _vm.cBackgroundColor
}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id});}}},[_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }