import eventManager from "../eventManager";

export default {
  mounted() {
    eventManager.on('click', ({ id }) => {
      if (id === 'reset') {
        this.isSelected = false;
        return;
      }
      if (this.cId) {
        if (this.cId === id) {
          console.log(this.cId)
          this.isSelected = true;
        } else {
          this.isSelected = false;
        }
      }
    })
  },
  data() {
    return {
      isSelected: false
    }
  },
  computed: {
    cId() {
      return this.id || (this.options || {}).id
    }
  },
  methods: {
    handleClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.isSelected) {
        eventManager.emit('click', { id: this.cId })
      } else {
        eventManager.emit('click', { id: 'reset' })
      }
    }
  }
};