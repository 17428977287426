var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.token)?_c('div',[_c('md-app',{attrs:{"md-waterfall":""}},[_c('md-app-toolbar',{staticClass:"Toolbar md-dense md-primary"},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('md-tabs',{staticClass:"md-primary",attrs:{"md-sync-route":""}},[_c('md-tab',{attrs:{"md-icon":"apps","to":"/well"}}),_c('md-tab',{attrs:{"md-label":"WBS","to":"/wbs","md-disabled":!_vm.well}}),_c('md-tab',{attrs:{"md-label":"BHA","to":"/bha","md-disabled":!_vm.well}})],1),(_vm.well)?_c('span',{staticClass:"selected-well-name"},[_vm._v(_vm._s(_vm.well.name))]):_vm._e()],1),_c('div',{staticClass:"md-toolbar-section-end"},[(!_vm.auth.tmpToken)?_c('span',{staticClass:"username",on:{"click":function($event){_vm.showRightMenu = true}}},[(_vm.cUser)?_c('md-icon',{staticStyle:{"margin-right":"0.3em"}},[_vm._v("account_circle")]):_vm._e(),_vm._v(_vm._s(_vm.cUser.username)+" ")],1):_vm._e(),_c('md-button',{staticClass:"md-icon-button",on:{"click":function($event){_vm.showRightMenu = true}}},[_c('md-icon',[_vm._v("more_vert")])],1)],1)]),_c('md-app-content',[_c('router-view')],1),(_vm.showRightMenu)?_c('md-app-drawer',{staticClass:"md-right",attrs:{"md-active":_vm.showRightMenu},on:{"update:mdActive":function($event){_vm.showRightMenu=$event},"update:md-active":function($event){_vm.showRightMenu=$event}}},[_c('md-list',[_c('md-list-item',{on:{"click":_vm.bhaDeviceSetting}},[_c('span',{staticClass:"md-list-item-text"},[_vm._v("BHA Device")]),_c('md-icon',[_vm._v("settings")])],1),_c('md-list-item',[_c('span',{staticClass:"md-list-item-text"},[_vm._v("Help")]),_c('md-icon',[_vm._v("help")])],1),_c('md-divider'),(!_vm.auth.tmpToken)?_c('md-list-item',{staticClass:"md-icon-bottom",on:{"click":_vm.logout}},[_c('span',{staticClass:"md-list-item-text"},[_vm._v("Logout")]),_c('md-icon',{staticClass:"md-primary"},[_vm._v("logout")])],1):_vm._e()],1)],1):_vm._e()],1),_c('div',{staticClass:"message",class:{
        visible:
          _vm.message.error ||
          _vm.message.success ||
          _vm.messageBha.error ||
          _vm.messageBha.success,
      },style:({
        backgroundColor: _vm.message.error || _vm.messageBha.error ? 'red' : 'green',
      })},[_vm._v(" "+_vm._s(_vm.message.error || _vm.messageBha.error || _vm.message.success || _vm.messageBha.success)+" ")])],1):_c('LoginPage')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }