var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"HistoryView"},[_c('div',{staticClass:"info-header"},[_c('h3',[_vm._v("Activity Stream")]),_c('h3',{staticClass:"legend-button",class:{'bordered': _vm.showLegend },on:{"click":function($event){_vm.showLegend = !_vm.showLegend}}},[_c('md-icon',[_vm._v("expand_more")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLegend),expression:"showLegend"}]},[_c('device-legend',{attrs:{"full":true,"type":_vm.type}})],1)]),_c('div',{staticClass:"info-body"},[_c('div',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.events),function(e,idx){return _c('div',{key:idx,staticClass:"table-row",class:{ 
                          'faded': e.time >= _vm.value
          },on:{"click":function($event){_vm.$emit('input', new Date(e.time.getTime() + 1).getTime())}}},[_c('div',{staticClass:"indicator",style:(_vm.getRowStyle(e))}),_c('div',{staticClass:"flex-2"},[_vm._v(_vm._s(e.time.toDateString()))]),_c('div',{staticClass:"flex-2"},[_c('Legend',{attrs:{"x-style":"display:inline-block;margin-right:0.5em;","type":_vm.type,"signature":_vm.reverseLegendTable[e.activityType]}}),_vm._v(_vm._s(e.name))],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"tag",class:{
            'added': e.eventType === 'Added',
            'hidden': e.eventType === 'Hidden'
          }},[_vm._v(_vm._s(e.eventType))])])])})],2)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%","padding":"10px"}},[(_vm.cMinTime < _vm.cMaxTime)?_c('vue-range-slider',{staticStyle:{"width":"100%"},attrs:{"min":_vm.cMinTime,"max":_vm.cMaxTime,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-row table-header"},[_c('div',{staticClass:"indicator",staticStyle:{"border":"none"}}),_c('div',{staticClass:"flex-2"},[_vm._v("Date")]),_c('div',{staticClass:"flex-2"},[_vm._v("Equipment")]),_c('div',{staticClass:"flex-1"},[_vm._v("Activity")])])
}]

export { render, staticRenderFns }