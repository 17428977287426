<template>
  <div class="legend" :style="xStyle">
    <div class="legend-icon" :style="{color: (cSpecs ||{}).color  }">{{signature}}</div>
    <div v-if="description" class="legend-description">{{description}}</div>
  </div>
</template>
<script>
import { BhaLegendTable, LegendTable } from '../utils/legend.js'
export default {
  name: "Legend",
  props: ["signature", "description", "xStyle", "type"],
  computed: {
    LegendTable() {
      if (this.type === 'BHA') {
        return BhaLegendTable
      }
      return LegendTable
    },
    cSpecs() {
      return this.LegendTable[this.signature];
    }
  }
}
</script>
<style lang="scss" scoped>
.legend {
  display: flex;
  align-items: center;
  .legend-icon {
    width: 2em;
    height: 2em;
    border: 1px solid;
    text-align: center;
    padding-top: 2px;
  }
  .legend-description {
    margin-left: 5px;
  }
}
</style>
