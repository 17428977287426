<template>
  <div class="Group" :style="{ 
    top: offsetTop,
    left: offsetLeft
     }">
    <component v-for="(item,idx) in cItems" :key="idx" 
      @onCasingOffsetTop="setOffsetTop"
      :is="item.type" :options="item"></component>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Group',
  props: ['top', 'items'],
  data() {
    return {
      offsetTop: 0,
      offsetLeft: 0
    }
  },
  computed: {
    cItems() {
      if (this.items) {
        let cItems = [...this.items];
          cItems = cItems.sort((a, b) => {
          if (a.type === 'Terminator') {
            if (a.terminatorType === 'Hanger') {
              return -1;
            }
          }
          if (b.type === 'Terminator') {
            if (b.terminatorType === 'Shoe') {
              return 1;
            }
          }
          if (b.type === 'Casing') {
            return 0;
          }
        })
        return cItems;
      }
      return [];
    },
    cTop() {
      return this.top || 0;
    }
  },
  methods: {
    setOffsetTop({offsetTop, offsetLeft}) {
        this.offsetTop = offsetTop + 'px';
        this.offsetLeft = offsetLeft + 'px';
      }
  },
  mounted() {
    if (!this.offsetTop) {
      this.offsetTop = this.top + 'px'
    }
  },
}
</script>
<style lang="scss" scoped>
  .Group {
    position: absolute;
    width: 100%;
  }
</style>
