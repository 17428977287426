var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize:throttle",value:(_vm.onResize),expression:"onResize",arg:"throttle"}],ref:"bhaDevice",staticClass:"bha-device",on:{"mousedown":_vm.handleRightClick}},[_c('img',{attrs:{"src":this.imageSrc,"width":_vm.cBhaImageSize * _vm.scale},on:{"click":function($event){return _vm.$emit('select', { id: _vm.id })}}}),(_vm.isSelected || _vm.selectedDeviceId === _vm.id)?_c('span',{staticClass:"border outlined",style:({
      left: _vm.posOutlined + 'px',
      right: _vm.posOutlined + 'px',
      cursor: 'pointer',
    }),on:{"click":function($event){return _vm.$emit('select', { id: _vm.id })}}}):_vm._e(),_c('div',{ref:"title",style:(_vm.idx % 2
        ? {
            position: 'absolute',
            top: _vm.hAlign - _vm.hTitle / 2 + 'px',
            right: _vm.posAlign + 'px',
            zIndex: '10',
          }
        : {
            position: 'absolute',
            top: _vm.hAlign - _vm.hTitle / 2 + 'px',
            left: _vm.posAlign + 'px',
            zIndex: '10',
          })},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{style:({
          width: _vm.wTitle + 'px',
          textAlign: 'left',
          fontSize: _vm.calcFontSize + 'px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        })},[_c('span',{style:({
            zIndex: 100,
            backgroundColor: '#FFF',
          })},[_c('strong',[_vm._v(_vm._s(_vm.idx + 1)+".")]),_vm._v(" "+_vm._s(_vm.name)+" ")])])])]),_c('div',{class:{ outlined: _vm.isSelected || _vm.selectedDeviceId === _vm.id },style:(_vm.idx % 2
        ? {
            position: 'absolute',
            borderBottom: '1px solid gray',
            width: _vm.wLine + 'px',
            top: _vm.hAlign + 'px',
            right: _vm.posAlign + 'px',
            zIndex: '-1',
          }
        : {
            position: 'absolute',
            borderBottom: '1px solid gray',
            width: _vm.wLine + 'px',
            top: _vm.hAlign + 'px',
            left: _vm.posAlign + 'px',
            zIndex: '-1',
          })}),_c('md-menu',{attrs:{"md-direction":"top-start","md-size":"small","md-active":_vm.menuActive},on:{"update:mdActive":function($event){_vm.menuActive=$event},"update:md-active":function($event){_vm.menuActive=$event}}},[_c('md-menu-content',[_c('md-menu-item',{on:{"click":function($event){return _vm.$emit('detail', { id: _vm.id })}}},[_c('span',[_vm._v("Detail")])]),_c('md-menu-item',{on:{"click":function($event){return _vm.$emit('copy', { id: _vm.id })}}},[_c('span',[_vm._v("Copy")])]),_c('md-menu-item',{on:{"click":function($event){_vm.deleteActive = true}}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Delete")])])],1)],1),_c('md-dialog-confirm',{attrs:{"md-active":_vm.deleteActive,"md-content":"<strong>Do you want to delete this equipment ?</strong>","md-confirm-text":"Delete","md-cancel-text":"Cancel"},on:{"update:mdActive":function($event){_vm.deleteActive=$event},"update:md-active":function($event){_vm.deleteActive=$event},"md-cancel":_vm.onCancelDelete,"md-confirm":_vm.onConfirmDelete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }