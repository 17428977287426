<template>
  <md-dialog :md-active.sync="isShow" :md-click-outside-to-close="false" :md-close-on-esc="false">
    <md-dialog-title>
      Add New Equipment
      <md-button class="md-primary" @click="resetProperty"
        :style="{
          height: '30px',
          padding: 0,
          margin: 0,
          marginBottom: '5px'
        }">
      Reset</md-button>
    </md-dialog-title>
    <md-dialog-content>
      <div class="dialog-layout">
        <div>
          <md-field>
            <dropdown-input
              name="new-wbsEquip"
              :key="reset"
              :options="listActivityType"
              :label="'Activity Type'"
              :handleSelect="handleActivityType"
              :placeholder="newEquip.properties.activityType"
              :isRequired="true"
            />
          </md-field>
        </div>
        <div v-if="cParentList.length">
          <md-field class="parent-selection">
              <dropdown-input
                name="new-device-parent"
                :key="resetParent"
                :options="cParentList"
                :label="'Parent'"
                :handleSelect="handleParent"
                :placeholder="cParentNameOfNewEquip"
                :isRequired="true"
              />
              <md-button class="clear-parent-btn" @click="() => {newEquip.id_parent = null; resetParent = !reset}">
                <md-icon >close</md-icon>
                <md-tooltip md-direction="top">Set parent to null</md-tooltip>
              </md-button>
          </md-field>
        </div>
        <div>
          <md-field>
            <label for="Name">Name</label>
            <md-input v-model="newEquip.name" name="Name"
              id="Name" required></md-input>
          </md-field>
        </div>
        <div>
          <md-field>
            <label for="Top">Top TVD(m)</label>
            <md-input v-model="newEquip.properties.top" name="Top" type="number"
              id="Top" required></md-input>
          </md-field>
        </div>
        <div>
          <md-field>
            <label for="TopMd">Top MD(m)</label>
            <md-input v-model="newEquip.properties.topMd" name="TopMd" type="number"
              id="TopMd" required></md-input>
          </md-field>
        </div>
        <div v-if="hasBottom(newEquip.id_equipment_type)">
          <md-field required>
            <label for="Bottom">Bottom TVD(m)</label>
            <md-input v-model="newEquip.properties.bottom" name="Bottom" type="number"
              id="Bottom" required></md-input>
          </md-field>
        </div>
        <div v-if="hasBottom(newEquip.id_equipment_type)">
          <md-field required>
            <label for="BottomMd">Bottom MD(m)</label>
            <md-input v-model="newEquip.properties.bottomMd" name="BottomMd" type="number"
              id="BottomMd" required></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'">
          <md-field required>
            <label for="Length">Length(m)</label>
            <md-input v-model="newEquip.properties.length" name="Length" type="number"
              id="Length" required></md-input>
          </md-field>
        </div>
        <div>
          <md-field>
            <dropdown-input
              name="new-OD"
              :key="reset"
              :options="cOdDropList"
              :label="'OD(in)'"
              :handleSelect="handleSelect"
              :placeholder="newEquip.properties.OD"
              :isRequired="false"
            />
          </md-field>
        </div>
        <div>
          <md-field>
            <dropdown-input
              name="new-ID"
              :options="cIdDropList"
              :key="reset"
              :label="'ID(in)'"
              :handleSelect="handleSelect"
              :placeholder="newEquip.properties.ID"
              :isRequired="false"
            />
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'">
          <md-field>
            <label for="crossover">CrossOver Position(m)</label>
            <md-input v-model="newEquip.properties.crossOverPos"
              type="number" name="crossover" id="crossover"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-checkbox
            v-model="newEquip.properties.hasCrossOver">
            <label>Has Crossover</label>
          </md-checkbox>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-checkbox
            v-model="newEquip.properties.stickToTop">
            <label>Stick To Top</label>
          </md-checkbox>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-checkbox
            v-model="newEquip.properties.hasBending">
            <label>Has Bending</label>
          </md-checkbox>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field required>
            <label for="bendingPos">Bending Position(m)</label>
            <md-input v-model="newEquip.properties.bendingPos"
              type="number" name="bendingPos" id="bendingPos"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field required>
            <label for="deviationAngle">Inclination Angle&deg;</label>
            <md-input v-model="newEquip.properties.deviationAngle"
              type="number" name="deviationAngle" id="deviationAngle"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field required>
            <label for="azimuthAngle">Azimuth Angle&deg;</label>
            <md-input v-model="newEquip.properties.azimuthAngle"
              type="number" name="azimuthAngle" id="azimuthAngle"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <dropdown-input
              name="grade"
              :key="resetGrade"
              :options="grades"
              :label="'Grade'"
              :handleSelect="handleGradeChange"
              :placeholder="newEquip.properties.grade"
              :isRequired="false"
            />
          </md-field>
        </div>
         <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <dropdown-input
              name="connection"
              :key="resetConnection"
              :options="connections"
              :label="'Connection'"
              :handleSelect="handleConnectionChange"
              :placeholder="newEquip.properties.connection"
              :isRequired="false"
            />
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="PPFG">PPFG (ppg)</label>
            <md-input v-model="newEquip.properties.PPFG"
              name="PPFG" id="PPFG"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="MW">MW (ppg)</label>
            <md-input v-model="newEquip.properties.MW"
              name="MW" id="MW"></md-input>
          </md-field>
        </div>
         <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <dropdown-input
              name="mudType"
              :key="resetMudType"
              :options="mudTypes"
              :label="'Mud Type'"
              :handleSelect="handleMudTypeChange"
              :placeholder="newEquip.properties.mudType"
              :isRequired="false"
            />
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="drillingRotation">Drilling Rotation (RPM)</label>
            <md-input v-model="newEquip.properties.drillingRotation"
              name="drillingRotation" id="drillingRotation"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="flowRate">Flow Rate (GPM)</label>
            <md-input v-model="newEquip.properties.flowRate"
              name="flowRate" id="flowRate"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="ROP">ROP (m/hr)</label>
            <md-input v-model="newEquip.properties.ROP"
              name="ROP" id="ROP"></md-input>
          </md-field>
        </div>
        <div v-if="newEquip.properties.type === 'Casing'" >
          <md-field>
            <label for="holeSelection">Hole Selection (inch)</label>
            <md-input v-model="newEquip.properties.holeSelection"
              name="holeSelection" id="holeSelection"></md-input>
          </md-field>
        </div>
        <div>
          <md-field required>
            <label for="weight">Weight(Lb)</label>
            <md-input v-model="newEquip.properties.weight"
              type="number" name="weight" id="weight"></md-input>
          </md-field>
        </div>
        <div>
          <md-field>
            <label>Note</label>
            <md-input v-model="newEquip.properties.note"></md-input>
          </md-field>
        </div>
        <div>
          <md-field>
            <label>Comment</label>
            <md-input v-model="newEquip.properties.comment"></md-input>
          </md-field>
        </div>
        <div>
          <md-datepicker v-model="newEquip.properties.startTime" name="startTime" id="startTime" :md-immediately="true">
            <label for="startTime">Start Time</label>
          </md-datepicker>
        </div>
        <div>
          <md-datepicker v-model="newEquip.properties.stopTime" name="stopTime" id="stopTime" :md-immediately="true">
            <label for="stopTime">Stop Time</label>
          </md-datepicker>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" :disabled="isUnableAddNewEquip"
        @click="handleAddEquip">Add</md-button>
      <md-button class="md-primary" @click="handleClose">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import eventManager from '../eventManager';
import wbsDefaultValue from '../utils/wbsDefaultValue.json';
import DropdownInput from './DropdownInput.vue';
import wbsDropList from '../mixins/wbsDroplist';
import casingDroplist from '../mixins/casingDroplist';

export default {
  components: { DropdownInput },
  mixins: [wbsDropList, casingDroplist],
  name: 'NewWbsDeviceDialog',
  data() {
    return {
      newEquip: {
        id_parent: null,
        id_equipment_type: 0,
        name: '',
        properties: {
          type: '',
          activityType: '',
          top: 0,
          bottom: 0,
          topMd: 0,
          bottomMd: 0,
          length: 0,
          startTime: new Date(),
          stopTime: null,
          OD: 0,
          ID: 0,
          weight: 0,
          note: '',
          comment: '',
          hasCrossOver: false,
          crossOverPos: 0,
          stickToTop: false,
          hasBending: false,
          bendingPos: 0,
          azimuthAngle: 0,
          deviationAngle: 0,
          borderSpec: JSON.stringify([]),
          grade: '',
          connection: '',
          PPFG: '',
          MW: '',
          mudType: '',
          drillingRotation: '',
          flowRate: '',
          ROP: '',
          holeSelection: ''
        }
      },
      reset: false,
      resetParent: false,
      resetGrade: false,
      resetConnection: false,
      resetMudType: false
    };
  },
  props: ['isShow', 'handleClose', 'selectedDeviceId'],
  computed: {
    isUnableAddNewEquip() {
      if (!this.newEquip.name || !this.newEquip.properties.activityType) {
        return true;
      }
      if (this.newEquip.properties.type === 'Casing') {
        if (isNaN(this.newEquip.properties.top) ||
          !this.newEquip.properties.bottom || !this.newEquip.properties.length) {
          return true;
        }
      }
      return false;
    },
    ...mapGetters({
      cWbsDiagramWidth: 'cWbsDiagramWidth',
      getDeviceById: 'getDeviceById'
    }),
    ...mapState({
      wbsDevices: state => state.well.wbsDevices,
    }),
    cParentList() {
      let list = this.wbsDevices.filter(child => child.type === 'Casing'
        || (child.type === 'Parker'));
      list = list.map(e => ({ ...e, icon: 'https://icons.veryicon.com/png/o/miscellaneous/8atour/extra-bed-16x16.png' }));
      return list;
    },
    cParentNameOfNewEquip() {
      if (!this.newEquip.id_parent) {
        return ''
      } else {
        let idx = this.cParentList.map(device => device.id).indexOf(this.newEquip.id_parent);
        return this.cParentList[idx].name
      }
    },
    cDefaultValue() {
      return wbsDefaultValue;
    },
    listActivityType() {
      return [
        { name: 'Conductor Casing',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/1.png'
        },
        { name: 'Surface Casing',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/2.png'
        },
        { name: 'Production Casing',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/4.png'
        },
        { name: 'Intermediate Or Protective Casing',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/3.png'
        },
        { name: 'Tubing',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/6.png'
        },
        { name: 'Liner',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/5.png'
        },
        { name: 'Perforation',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/10.png'
        },
        { name: 'Hanger',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/18.png'
        },
        { name: 'Shoe',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/15.png'
        },
        { name: 'Parker',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/17.png'
        },
        { name: 'Nipple',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/20.png'
        },
        { name: 'Baker',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/21.png'
        },
        { name: 'Safty Valve',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/19.png'
        },
        { name: 'Wireline Retrievable Insert Valve',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/19.png'
        },
        { name: 'Lubricator Valve',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/19.png'
        },
        { name: 'Centralizer',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/16.png'
        },
        { name: 'Tieback Seal',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/16.png'
        },
        { name: 'Cement Plug',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/16.png'
        },
        {
          name: 'Control Line',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/21.png'
        },
        {
          name: 'Landing Collar',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/17.png'
        },
        {
          name: 'Plug',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/10.png'
        },
        {
          name: 'Whipstock Left',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/10.png'
        },
        { name: 'Whipstock Right',
          icon_path: 'https://api-wbs.i2g.cloud/bhaDevice/bha_images/iconWBS/16.png'
        },
    ]
    },
  },
  watch: {
    selectedDeviceId(newVal) {
      if (this.cParentList.find(device => device.id === newVal)) {
        this.newEquip.id_parent = newVal
      }
    },
    'newEquip.properties.activityType'(newVal) {
      //set default value
      if (Object.keys(this.cDefaultValue).find(key => key === newVal)) {
        let defaultValue = this.cDefaultValue[newVal]
        Object.keys(defaultValue).forEach(key => {
          if (key === 'name') {
            this.newEquip[key] = defaultValue[key]
          } else {
            this.newEquip.properties[key] = defaultValue[key]
          }
        })
      } else {
        this.newEquip.name = ''
        this.newEquip.properties.OD = 0
        this.newEquip.properties.ID = 0
        this.newEquip.properties.top = 0
        this.newEquip.properties.bottom = 0
        let device = this.getDeviceById("WBS", this.newEquip.id_parent)
        if (device) {
          if (device.type === 'Casing' || device.type === 'Parker') {
            this.newEquip.properties.top = device.Properties.find(prop => prop.name === 'top').value
          }
        }
      }

      //set type & id_equipment_type
      if(newVal === 'Parker') {
        this.newEquip.id_equipment_type = 4;
        this.newEquip.properties.type = 'Parker';
        return;
      }
      if (newVal === 'Nipple') {
        this.newEquip.id_equipment_type = 5;
        this.newEquip.properties.type = 'Nipple';
        return;
      }
      if (newVal === 'Baker') {
        this.newEquip.id_equipment_type = 6;
        this.newEquip.properties.type = 'Baker';
        return;
      }
      if (newVal === 'Lubricator Valve') {
        this.newEquip.id_equipment_type = 7;
        this.newEquip.properties.type = 'Valve';
        return;
      }
      if (newVal === 'Wireline Retrievable Insert Valve') {
        this.newEquip.id_equipment_type = 7;
        this.newEquip.properties.type = 'Valve';
        return;
      }
      if (newVal === 'Safty Valve') {
        this.newEquip.id_equipment_type = 7;
        this.newEquip.properties.type = 'Valve';
        return;
      }
      if (newVal === 'Perforation') {
        this.newEquip.id_equipment_type = 8;
        this.newEquip.properties.type = 'Perforation';
        return;
      }
      if (newVal === 'Centralizer') {
        this.newEquip.id_equipment_type = 9;
        this.newEquip.properties.type = 'Centralizer';
        return;
      }
      if (newVal === 'Tieback Seal') {
        this.newEquip.id_equipment_type = 10;
        this.newEquip.properties.type = 'Tieback';
        return;
      }
      if (newVal === 'Cement Plug') {
        this.newEquip.id_equipment_type = 11;
        this.newEquip.properties.type = 'CementPlug';
        return;
      }
      if (newVal === 'Control Line') {
        this.newEquip.id_equipment_type = 12;
        this.newEquip.properties.type = "ControlLine";
        return;
      }
      if (newVal === 'Landing Collar') {
        this.newEquip.id_equipment_type = 13;
        this.newEquip.properties.type = 'LandingCollar';
        return
      }
      if (newVal === 'Plug') {
        this.newEquip.id_equipment_type = 14;
        this.newEquip.properties.type = 'Plug'
        return
      }
      if (newVal === 'Whipstock Left') {
        this.newEquip.id_equipment_type = 15;
        this.newEquip.properties.type = 'WhipstockLeft';
        return;
      }
      if (newVal === 'Whipstock Right') {
        this.newEquip.id_equipment_type = 16;
        this.newEquip.properties.type = 'WhipstockRight';
        return
      }
      if (newVal === 'Hanger' || newVal === 'Shoe') {
        this.newEquip.properties.type = 'Terminator';
        this.newEquip.properties.terminatorType = newVal;
        this.newEquip.id_equipment_type = 2;
        return;
      }
        this.newEquip.properties.type = 'Casing';
        this.newEquip.id_equipment_type = 1;
      //set type & id_equipment_type
    },
    'newEquip.properties.top'(top) {
      this.newEquip.properties.bendingPos = top
      this.newEquip.properties.length = this.newEquip.properties.bottom - top
    },
    'newEquip.properties.bottom'(bottom) {
      this.newEquip.properties.length = bottom - this.newEquip.properties.top
    },
    'newEquip.id_parent'(id_parent) {
      if (id_parent === 'null') {
        this.newEquip.id_parent = null;
      }
      let activityType = this.newEquip.properties.activityType
      if (!Object.keys(this.cDefaultValue).find(key => key === activityType)) {
        let device = this.getDeviceById("WBS", id_parent)
         if (device) {
        if (device.type === 'Casing' || device.type === 'Parker') {
          this.newEquip.properties.top = device.Properties.find(prop => prop.name === 'top').value
        }
      }

      }
    },
    'wbsDevices.length'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.close()
      }
    }
  },
  methods: {
    handleAddEquip() {
      if (this.newEquip.id_equipment_type !== 1) {
        if (this.newEquip.id_equipment_type !== 8 && this.newEquip.id_equipment_type !== 11
          && this.newEquip.id_equipment_type !== 13 && this.newEquip.id_equipment_type !== 14) {
          delete this.newEquip.properties.bottom;
          delete this.newEquip.properties.bottomMd;
        }
        delete this.newEquip.properties.length;
        delete this.newEquip.properties.stickToTop;
        delete this.newEquip.properties.crossOverPos;
        delete this.newEquip.properties.hasCrossOver;
        delete this.newEquip.properties.hasBending;
        delete this.newEquip.properties.bendingPos;
        delete this.newEquip.properties.azimuthAngle;
        delete this.newEquip.properties.deviationAngle;
        delete this.newEquip.properties.borderSpec;
        delete this.newEquip.properties.grade;
        delete this.newEquip.properties.connection;
        delete this.newEquip.properties.PPFG;
        delete this.newEquip.properties.MW;
        delete this.newEquip.properties.mudType;
        delete this.newEquip.properties.drillingRotation;
        delete this.newEquip.properties.flowRate;
        delete this.newEquip.properties.ROP;
        delete this.newEquip.properties.holeSelection;
      }
      if (this.newEquip.id_equipment_type === 1) {
        this.newEquip.properties.crossOverPos =  100 * (this.newEquip.properties.crossOverPos - this.newEquip.properties.top) /
          (this.newEquip.properties.bottom - this.newEquip.properties.top)
        this.newEquip.properties.bendingPos =  100 * (this.newEquip.properties.bendingPos - this.newEquip.properties.top) /
          (this.newEquip.properties.bottom - this.newEquip.properties.top)
      }
      this.$store.dispatch("addWbsEquip", {newEquip: this.newEquip});
      eventManager.emit('click', {id: 'reset'})
      console.log(this.newEquip);
      // this.close()
    },
    handleActivityType(value) {
      this.newEquip.properties.activityType = value.name
    },
    handleSelect(value, name) {
      name = name.split('-')[1]
      this.newEquip.properties[name] = value.name
    },
    handleParent(value) {
      this.newEquip.id_parent = value.id
    },
    handleGradeChange(value) {
      this.newEquip.properties.grade = value.name
    },
    handleConnectionChange(value) {
      this.newEquip.properties.connection = value.name
    },
    handleMudTypeChange(value) {
      this.newEquip.properties.mudType = value.name
    },
    resetProperty() {
      this.newEquip = {
        id_parent: null,
        id_equipment_type: 0,
        name: '',
        properties: {
          type: '',
          activityType: '',
          top: 0,
          bottom: 0,
          topMd: 0,
          bottomMd: 0,
          length: 0,
          startTime: new Date(),
          stopTime: null,
          OD: 0,
          ID: 0,
          weight: 0,
          note: '',
          comment: '',
          hasCrossOver: false,
          crossOverPos: 0,
          stickToTop: false,
          hasBending: false,
          bendingPos: 0,
          azimuthAngle: 0,
          deviationAngle: 0,
          borderSpec: JSON.stringify([]),
          grade: '',
          connection: '',
          PPFG: '',
          MW: '',
          mudType: '',
          drillingRotation: '',
          flowRate: '',
          ROP: '',
          holeSelection: ''
        }
      },
      this.reset = !this.reset;
      this.resetParent = !this.resetParent;
      this.resetGrade = !this.resetGrade;
      this.resetMudType = !this.resetMudType;
      this.resetConnection = !this.resetConnection;
      if (this.selectedDeviceId && this.cParentList.find(device => device.id === this.selectedDeviceId)) {
        this.newEquip.id_parent = this.selectedDeviceId;
      }
    },
    close() {
      this.handleClose()
      this.resetProperty()
    },
    hasBottom(id_equipment_type) {
      let idList = [1, 8, 11, 13, 14];
      return idList.indexOf(Number(id_equipment_type)) >= 0;
    }
  },
  mounted() {
    if (this.selectedDeviceId) {
      if (this.cParentList.find(device => device.id === this.selectedDeviceId)) {
        this.newEquip.id_parent = this.selectedDeviceId
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.md-dialog ::v-deep.md-dialog-container {
    width: 768px;
  }

.md-dialog-actions {
  padding-right: 24px;
}

.dialog-layout {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  > div {
    // width: 45%;
    margin: 15px 0 !important;
  }
}
.clear-parent-btn {
  height: auto;
  min-width: auto;
  margin: 0;
  > .md-ripple {
    padding: 0;
  }
}

label::after {
  font-size: 20px;
  color: red;
}
</style>