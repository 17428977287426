<template>
  <div :style="{
    position: 'absolute',
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*cOffsetLeft+ 'px)',
  }" ref="myRef"
    @click="$emit('select', {id: options.id});"
    :class="{Tieback: true, 'outlined': selectedDeviceId === options.id || isSelected}">
    <svg v-if="trianglePercent" width="100%" height="40" version="1.1" viewBox="0 0 100 100"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">
      <polygon :points="'0,50 ' + trianglePercent + ',50 ' + trianglePercent + ',100'" fill="black"/>
      <polygon :points="(100 - Number(trianglePercent)) + ',50 100,50 ' + (100 - Number(trianglePercent)) + ',100'" fill="black"/>

      <rect :x="trianglePercent" y="0" :width="trianglePercent" height="100" fill="#cc8a2d" id="rect"/>
      <rect :x="Math.round(100 - 2 * trianglePercent)" y="0" :width="trianglePercent" height="100" fill="#cc8a2d" id="rect"/>

      <line :x1="trianglePercent" :y1="20" :x2="2*trianglePercent" :y2="20" id="line"/>
      <line :x1="trianglePercent" :y1="40" :x2="2*trianglePercent" :y2="40" id="line"/>
      <line :x1="trianglePercent" :y1="60" :x2="2*trianglePercent" :y2="60" id="line"/>
      <line :x1="trianglePercent" :y1="80" :x2="2*trianglePercent" :y2="80" id="line"/>

      <line :x1="Math.round(100 - 2 * trianglePercent)" :y1="20" 
        :x2="Math.round(100 - trianglePercent)" :y2="20" id="line"/>
      <line :x1="Math.round(100 - 2 * trianglePercent )" :y1="40" 
        :x2="Math.round(100 - trianglePercent)" :y2="40" id="line"/>
      <line :x1="Math.round(100 - 2 * trianglePercent )" :y1="60" 
        :x2="Math.round(100 - trianglePercent)" :y2="60" id="line"/>
      <line :x1="Math.round(100 - 2 * trianglePercent )" :y1="80" 
        :x2="Math.round(100 - trianglePercent)" :y2="80" id="line"/>
    </svg>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import top from '../mixins/top'
import selection from '../mixins/selection';
import { mapGetters } from 'vuex'; 
export default {
  name: 'Tieback',
  props: ['options', 'selectedDeviceId'],
   data() {
    return {
      trianglePercent: 0
    }
  },
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
      return 2 * this.cGapWidth;
    },
    cAboverCrossOver() {
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      return this.isAboveCrossOver(this.options.id_parent, top)
    }
  },
  mounted() {
    this.trianglePercent = Math.ceil(((this.cGapWidth/2) / this.$refs.myRef.clientWidth * 100) * 100 / 100);
  },
  watch: {
    cOffsetTop() {
      this.$nextTick(() => {
        this.trianglePercent = Math.ceil(((this.cGapWidth/2) / (this.$refs.myRef.clientWidth) * 100) * 100 / 100);
      })
    },
    'options.id_parent'() {
      this.$nextTick(() => {
        this.trianglePercent = Math.ceil(((this.cGapWidth/2) / (this.$refs.myRef.clientWidth) * 100) * 100 / 100);
      }) 
    },
    cAboverCrossOver() {
      this.$nextTick(() => {
        this.trianglePercent = Math.ceil(((this.cGapWidth/2) / (this.$refs.myRef.clientWidth) * 100) * 100 / 100);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Tieback {
  z-index: 10;
}
#line {
  stroke: #000;
  stroke-width: 2px;
}
#rect {
  stroke: #000;
  stroke-width: 0.5px;
}
</style>
