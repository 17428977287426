<template>
  <div class="login-page">
    <md-content class="md-elevation-3">
      <div class="alert-login" style="color: red;">{{ getError() }}</div>
      <div class="title">
        <h2>Login to WBS</h2>
      </div>
      <div class="form-login">
        <md-field>
          <label>Username</label>
          <md-input v-model="auth.userName" @keyup.enter="login" autofocus></md-input>
        </md-field>
        <md-field md-has-password>
          <label>Password</label>
          <md-input v-model="auth.password" type="password" @keyup.enter="login"></md-input>
        </md-field>
      </div>
      <div class="actions md-layout md-alignment-center-left">
        <md-button
          style="border-radius: 5px;"
          class="md-raised md-primary"
          @click="login"
          >LOG IN</md-button
        >
      </div>
      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-stroke="2"
        ></md-progress-spinner>
      </div>
    </md-content>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      auth: {
        userName: null,
        password: null,
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      error: state => state.auth.error
    })
  },
  methods: {
    getError() {
      if (this.error) {
        this.loading = false;
        return this.error;
      }
      return "";
    },
    login() {
      this.loading = true;
      this.$store.dispatch("login", {
        username: this.auth.userName,
        password: this.auth.password,
      });
      this.auth.userName = null;
      this.auth.password = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;

  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
    border-radius: 15px;
  }

  .loading-overlay {
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
