var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'outlined': _vm.options.id === _vm.selectedDeviceId || _vm.isSelected},style:({
  width: 'calc(100% - ' + 2*_vm._offsetLeft+ 'px)',
  height: _vm.cHeight + 'px',
  position: 'absolute',
  left: _vm._offsetLeft + 'px',
  top: _vm.offsetTop + 'px',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: _vm.cNumOfParent,
}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id})}}},[_c('svg',{class:{Hanger: true},style:({
        stroke: _vm.cColor
      }),attrs:{"height":_vm.cGapWidth/1.5,"width":_vm._gapWidth}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":"white"}}),_c('line',{attrs:{"x1":"0","y1":"0%","x2":"100%","y2":"100%"}}),_c('line',{attrs:{"x1":"100%","y1":"0%","x2":"0%","y2":"100%"}})]),_c('svg',{class:{Hanger: true},style:({
        stroke: _vm.cColor
      }),attrs:{"height":_vm.cGapWidth/1.5,"width":_vm._gapWidth}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":"white"}}),_c('line',{attrs:{"x1":"0","y1":"0%","x2":"100%","y2":"100%"}}),_c('line',{attrs:{"x1":"100%","y1":"0%","x2":"0%","y2":"100%"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }