String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default {
  light: [
    '#fbf5e9', '#f5fbe9', '#fbe9e9', '#e9fbf5', '#e9e9fb', '#f3e2be', '#e2f3be', '#f3d0be', '#bef3e2'
  ],
  dark: [ 
    'hsl(0deg 100% 30%)', 
    'hsl(60deg 100% 30%)',
    'hsl(120deg 100% 30%)',
    'hsl(180deg 100% 30%)',
    'hsl(240deg 100% 30%)',
    'hsl(300deg 100% 30%)',
    '#444'
  ]
}
