<template>
  <div class="Casing" ref="casingRef">
    <tubing-internal v-if="!hasCrossOver" :height="cHeight"
      :options="options" :border-spec="cBorderSpec" @select="$emit('select', $event);"
      :id="cId">
        <slot></slot>
        <div v-if="cHasBending" :style="{
          top: cOffsetBending + 'px',
          zIndex: 1000
        }" class="Bending">
          <div>
            <span>{{cBendingPos}}(m)</span>
            <div>
            </div>
            <span>{{cDeviationAngle}}&deg;,{{cAzimuthAngle}}&deg;</span>
          </div>
        </div>
    </tubing-internal>
    <div v-if="hasCrossOver">
      <tubing-internal v-if="crossOverPos" :height="crossOverPos" :options="options" @select="$emit('select', $event);"
        :id="cId" :border-spec="cUpperBorderSpec">
        <div v-if="cHasBending && cBendingPos <= crossOverPos" :style="{
          top: cOffsetBending  + 'px',
          zIndex: 1000
        }" class="Bending">
          <div>
            <span>{{cBendingPos}}(m)</span>
            <div>
            </div>
            <span>{{cDeviationAngle}}&deg;,{{cAzimuthAngle}}&deg;</span>
          </div>
        </div>
      </tubing-internal>
      <Crossover :fill="cCrossOverFill"
        :id="cId" :casing-type="cActivityType" />
      <tubing-internal :height="cHeight - crossOverPos - 10"
        :width="(crossOverCasingWidth) * 100" :options="options" @select="$emit('select', $event);"
        :is-below-cross-over="true"
        :id="cId" :border-spec="cBelowBorderSpec">
        <slot></slot>
        <div v-if="cHasBending && cBendingPos > crossOverPos" :style="{
          top: cOffsetBending - crossOverPos  + 'px',
          zIndex: 1000
        }" class="Bending">
          <div>
            <span>{{cBendingPos}}(m)</span>
            <div>
            </div>
            <span>{{cDeviationAngle}}&deg;,{{cAzimuthAngle}}&deg;</span>
          </div>
        </div>
      </tubing-internal>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import gapWidth from '../mixins/gapWidth';
import TubingInternal from './TubingInternal.vue';

export default {
  components: { TubingInternal },
  name: 'CasingInternal',
  props: ['options', 'width', 'height', 'gapColor', "hasCrossOver",
    'top', 'bottom' ,'crossOverPos', 'bendingPos'],
  data() {
    return {
      crossOverCasingWidth: 0
    }
  },
  computed: {
    cHeight() {
      return (this.height || (this.options || {}).height || 200);
    },
    cId() {
      return (this.id || (this.options || {}).id);
    },
    cActivityType() {
      let activityType = this.options.Properties.find(prop => prop.name === 'activityType');
      return activityType && activityType.value;
    },
     ...mapGetters({
      numOfWbsDevice: 'numOfWbsDevice',
      zoom: 'cZoom',
    }),
    cHasBending() {
      let hasBending = this.options.Properties.find(prop => prop.name === 'hasBending')
      if (hasBending) {
        return Number(hasBending.value)
      }
      return 0;
    },
    cBendingPos() {
      // let bendingPos = this.options.Properties.find(prop => prop.name === 'bendingPos')
      // return (bendingPos || {}).value || 0; 
      return this.bendingPos
    },
    cDeviationAngle() {
      let deviationAngle = this.options.Properties.find(prop => prop.name === 'deviationAngle')
      return (deviationAngle || {}).value || 0;
    },
    cAzimuthAngle() {
      let azimuthAngle = this.options.Properties.find(prop => prop.name === 'azimuthAngle')
      return (azimuthAngle || {}).value || 0;
    },
    cOffsetBending() {
      if (this.cBendingPos) {
        let ratio = (this.cBendingPos - this.top) / (this.bottom - this.top)
        return Math.round(ratio * this.height)
      }
      return Math.round(this.height / 2);
    },
    cBorderSpec() {
      let borderSpec = this.options.Properties.find(prop => prop.name === 'borderSpec')
      if (borderSpec && borderSpec.value) {
        let tmpArray = JSON.parse(borderSpec.value)
        return tmpArray.map(item => {
          return {
            ...item,
            height: this.cHeight * Number(item.interval)
          }
        })
      }
      return []
    },
    cUpperBorderSpec() {
      if (this.hasCrossOver) {
        let tmpArr = []
        let tmpHeight = 0
        for (const item of this.cBorderSpec) {
          if (tmpHeight + item.height <= this.crossOverPos) {
            tmpArr.push(item)
            tmpHeight += item.height
          } else if ( tmpHeight + item.height > this.crossOverPos) {
            tmpArr.push({
              fill: item.fill,
              height: this.crossOverPos - tmpHeight
            })
            return tmpArr
          }
        }
        return tmpArr;
      }
      return []
    },
    cBelowBorderSpec() {
      if (this.hasCrossOver) {
        if (this.cBorderSpec.length === 1) {
          if (this.cBorderSpec[0].interval >= this.crossOverPos / this.cHeight) {
            return [{
            fill: this.cBorderSpec[0].fill,
            height: this.cBorderSpec[0].height - this.crossOverPos - 10
          }]
          } 
          return []
        }
        let tmpArr = []
        let tmpHeight = 0
        let idx = 0
        while (idx < this.cBorderSpec.length && tmpHeight + this.cBorderSpec[idx].height <= this.crossOverPos) {
          tmpHeight += this.cBorderSpec[idx].height
          idx++;
        }
        if (idx < this.cBorderSpec.length) {
          tmpArr.push({
            fill: this.cBorderSpec[idx].fill,
            height: tmpHeight + this.cBorderSpec[idx].height - this.crossOverPos - 10
          })
          idx++
          for (let i = idx; i < this.cBorderSpec.length; i++) {
              tmpArr.push({
              fill: this.cBorderSpec[i].fill,
              height: this.cBorderSpec[i].height
            })
          }
        }
        return tmpArr
      }
      return []
    },
    cCrossOverFill() {
      let fill = 'transparent'
      let tmpHeight = 0
      for (const item of this.cBorderSpec) {
        tmpHeight += item.interval
        if (this.crossOverPos / this.cHeight < tmpHeight) {
          fill = this.cBorderFill[item.fill]
          break;
        }
      }
      return fill
    },
    cBorderFill() {
      return {
        'good': 'rgb(160,87,10)',
        'bad': 'rgb(170,4,2)',
        'normal': 'rgb(113, 113, 113)',
        'none': 'transparent',
        'lead': 'rgb(207, 207, 207)',
        'tail': 'rgb(240, 214, 129)',
        'squeeze': 'rgb(80,101,57)'
      }
    },
  },
  mixins: [gapWidth],
  mounted() {
    if (this.$refs.casingRef) {
      let width = this.$refs.casingRef.clientWidth;
      this.crossOverCasingWidth = (width - 2 * this.cGapWidth)/width
    }
  },
  watch: {
    hasCrossOver() {
      let width = this.$refs.casingRef.clientWidth;
      this.crossOverCasingWidth = (width - 2 * this.cGapWidth)/width
    }
  }
}
</script>
<style lang="scss" scoped>
  .Casing {
    cursor: pointer;
    p {
      margin: 0;
    }
  }
  .Bending {
    width: 100%;
    position: absolute;
    border-top: 1px dashed black;
    > div:nth-child(1) {
      position: relative;
      top: -12px;
      span {
        background: #f0f0f0;
        font-size: 12px;
      } 
      > div {
        background-image: url('../../public/angle.svg');
        height: 13px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
</style>
