import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth';
import well from './module/well';
import bhaDevice from './module/bhaDevice';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    well,
    bha: bhaDevice
  }
});
export default store;
