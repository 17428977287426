<template>
  <div class="SeaLevel" :style="{top: `${cSeaLevel}px`, height: `${cSeaBed - cSeaLevel}px` }">
    RT-MSL/RT-Seabed (m): ({{seaLevel}}/{{seaBed}})
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "SeaLevel",
  props: ["seaLevel", "seaBed"],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
    }),
    cSeaLevel() {
      return this.convertLength(this.seaLevel || 0) + 15;
    },
    cSeaBed() {
      return this.convertLength(this.seaBed || 0) + 15;
    }
  },
}
</script>
<style lang="scss" scoped>
  .SeaLevel {
    position: absolute;
    width: 100%;
    text-align: right;
    color: brown;
    padding-right: 10px;
    border-bottom: 1px solid navy;
    background-image: linear-gradient(#0095ff44, #0095ff);
  }
</style>
