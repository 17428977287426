import html2canvas from 'html2canvas'
export function saveDataUrl(url, filename){
    let a = document.createElement('a');
    a.download = filename;
    a.href = url;
    a.click();
}
export function print(elem, width, height, filename) {
  html2canvas(elem, {
    allowTaint: true,
    useCORS: true,
    //foreignObjectRendering: true,
    width,
    height
  }).then(canvas => {
    let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    saveDataUrl(image, filename);
  }).catch(e => {
    console.error(e);
  });
  
}
