var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",style:({
		top: _vm.cOffsetTop + 'px',
		left: _vm.offsetLeft + 'px',
		width: 'calc(100% - ' + 2*_vm.offsetLeft +  'px)',
		zIndex: 20,
	}),on:{"click":function($event){return _vm.$emit('select', {id: _vm.options.id});}}},[_c('div',{class:{'outlined': _vm.selectedDeviceId === _vm.options.id || _vm.isSelected}},[_c('div',{staticClass:"shape triangle"}),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape circle"},[_c('span')])
}]

export { render, staticRenderFns }