<template>
  <div class="angle-3d">
    <div class="text-sea">Sea level {{ cSeaLevel }} m</div>
    <div class="bending-panel">
      <div class="bending-list" v-for="(point, idx) in bendingPoints" :key="idx" >
        <div class="bending-point" :style="{ backgroundColor: getColor(idx) }"></div>
        <div class="bending-info">
          <div><span class="label">Deviation</span>:<span>{{ point.deviationAngle }}</span>, <span class="label">Azimuth</span>:<span>{{ point.azimuthAngle }}</span></div>
          <div><span class="label">x</span>:<span>{{referencePoints[idx].x.toFixed(2)}}</span>, <span class="label">y</span>:<span>{{referencePoints[idx].z.toFixed(2)}}</span>, <span class="label">z</span>:<span>{{referencePoints[idx].y.toFixed(2)}}</span></div>
          <div>MD: {{ point.deep }}m</div>
        </div>
      </div>
    </div>
    <div class="arrow-panel">
      <div class="arrow rotate45"><span style="color: red">↑</span> North</div>
      <div class="arrow rotate135"><span style="color: blue">↑</span> East</div>
      <div class="arrow"><span style="color: green">↑</span> Depth</div>
      <div class="tip">
        Hold <strong>clt</strong> + <strong>left mouse</strong> to move the
        frame
      </div>
    </div>
    <div class="btn-camera">
      <md-button @click="changeCamera(1)">View 1</md-button>
      <md-button @click="changeCamera(2)">View 2</md-button>
      <md-button @click="changeCamera(3)">View 3</md-button>
      <md-button class="md-icon-button" @click="handleExport"><md-icon>print</md-icon></md-button>
    </div>

    <div id="container-3d" ref="scene"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { mapGetters, mapState } from "vuex";
import Angle3d from "../controllers/angel3d";
import math from '../utils/math.js'
import {saveDataUrl} from '../utils/print.js'
export default {
  name: "Angle3dPage",
  data() {
    return {
      colors: [
        0xffa822,
        0x134e6f,
        0xff6150,
        0x1ac0c6,
        0xdee0e6,
        0x191970,
      ],
    };
  },
  created() {
    if (!this.well) {
      this.$router.push("/well");
    }
  },
  mounted() {
    //window.scrollTo(0, 0);
    console.log(this.bendingPoints);
    this.angle3d = new Angle3d(
      this.bendingPoints,
      this.cSeaLevel,
      this.colors
    );
  },
  beforeDestroy() {
    this.angle3d.stop();
  },
  computed: {
    ...mapGetters({
      bendingPoints: "getBendingPoints",
      cSeaLevel: "cSeaLevel",
    }),
    ...mapState({
      well: (state) => state.well.well,
    }),
    ...mapState({
      well: (state) => state.well.well
    }),
    referencePoints() {
      return math.getRefPointsFromBendingPoints(this.bendingPoints);
    },
  },
  methods: {
    changeCamera(position) {
      if (position === 1) {
        this.angle3d._camera.position.set(0, 0, -600);
      } else if (position === 2) {
        this.angle3d._camera.position.set(0, 600, 0);
      } else if (position === 3) {
        this.angle3d._camera.position.set(600, 0, 0);
      }
      this.angle3d._orbitControls.target = new THREE.Vector3(0, 0, 0);
      this.angle3d._orbitControls.update();
    },
    intToHex(color) {
      console.log(color);
      return "#" + color.toString(16);
    },
    getColor(idx) {
      if (idx === 0 ) return '#00ff00';
      if (idx === this.bendingPoints.length - 1) return '#0000ff';
      return this.intToHex( this.colors[idx % this.bendingPoints.length]);
    },
    handleExport() {
      let canvas = document.getElementById('threejs');
      let url = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      saveDataUrl(url, 'trajectory.png');
      //print(this.$refs.scene, this.$refs.scene.clientWidth, this.$refs.scene.clientHeight, 'trajectory.png');
    }
  },
};
</script>

<style lang="scss" scoped>
.angle-3d {
  position: relative;
  margin: 10px;
  .text-sea {
    position: absolute;
    color: #1962a7;
    margin-bottom: 20px;
  }
  .btn-camera {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  #container-3d {
    overflow: hidden;
    height: calc(100vh - 68px);
  }

  .bending-panel {
    position: absolute;
    position: absolute;
    bottom: 5px;
    left: 10px;
  }

  .arrow-panel {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .bending-list {
    margin-bottom: 15px;
  }
  .bending-point {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .bending-point, .bending-info, .arrow, .tip {
    vertical-align: text-top;
    display: inline-block;
  }
  .tip {
    margin-left: 20px;
  }
  .arrow {
    margin-right: 20px;
    span {
      font-size: 150%;
      display: inline-block;
    }
  }
  .rotate45 span {
    transform: rotate( 45deg);
  }
  .rotate135 span {
    transform: rotate(135deg); 
  }
  .bending-info span.label {
    color: black;
  }
  .bending-info span {
    font-weight: 100;
    color: blue;
  }
}
</style>
