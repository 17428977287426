import dropList from '../utils/dropList.json'

export default {
  computed: {
    cOdDropList() {
      return dropList['WBS']['OD'].map(value => ({ name: value }));
    },
    cIdDropList() {
      return dropList['WBS']['ID'].map(value => ({ name: value }));
    },
  }
}