<template>
  <div class="bha">
    <div class="toolbar" style="margin-bottom: 15px;text-align: right;">
      <md-button
        class="md-icon-button md-raised"
        @click="showOptionDialog = true"
      >
        <md-tooltip md-direction="bottom">options</md-tooltip>
        <md-icon>more_horiz</md-icon>
      </md-button>
      <md-button @click="resetZoom" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">reset</md-tooltip>
        <md-icon>crop_free</md-icon>
      </md-button>
      <md-button @click="zoomOut" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">zoom out</md-tooltip>
        <md-icon>zoom_out</md-icon>
      </md-button>
      <md-button @click="zoomIn" class="md-icon-button md-raised">
        <md-tooltip md-direction="bottom">zoom in</md-tooltip>
        <md-icon>zoom_in</md-icon>
      </md-button>
      <md-button class="md-icon-button md-raised" @click="openAddEquipModal">
        <md-tooltip md-direction="bottom">Add equipment</md-tooltip>
        <md-icon>add</md-icon>
      </md-button>
      <md-button class="md-icon-button md-raised" @click="handleExport">
        <md-icon>print</md-icon>
        <md-tooltip md-direction="bottom">Export</md-tooltip>
      </md-button>
    </div>

    <div class="bha-container">
      <!-- <div
        :style="selectedDeviceId ? { width: '564px' } : { width: '0px' }"
        class="bha-info-container"
      >
        <bha-device-info
          :equip-id="selectedDeviceId"
          :on-close="closeBhaDeviceInfo"
          :my-width="selectedDeviceId ? '564px' : '0px'"
        />
      </div> -->
      <!-- <div class="card-container">
        <CardGroup :items="bhaDevices" type="BHA"> </CardGroup>
      </div> -->
      <div class="bha-diagram" @click="resetSelection" ref="diagram">
        <well-info :type="'bha'" :cBhaInfo="cBhaInfo" />
        <div style="margin-top: 15px;" ref="bhaDiagram">
          <bha-device
            v-for="(item, idx) in bhaDevices"
            :idx="idx"
            :key="item.id"
            :id="item.id"
            :name="item.name"
            :scale="scaleFactorProp(item.id)"
            :image-src="getImageSrc(item.bha_device.image_path)"
            :selected-device-id="selectedDeviceId"
            @select="selectEvent($event)"
            @copy="handleCopy($event)"
            @detail="handleDetail($event)"
          />
        </div>
      </div>
      <div style="width: 464px;" class="bha-device-info-container">
        <div
          :style="{
            width: expanded ? 'calc(100% - 80px)' : '464px',
            height: 'calc(100vh - 48px)',
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            zIndex: 100,
            transition: 'width 0.25s',
          }"
        >
          <bha-device-info
            v-show="!listAll"
            :equip-id="selectedDeviceId"
            :on-close="false && closeBhaDeviceInfo"
            my-width="100%"
          />
          <div
            v-show="listAll"
            class="device-table-container shadow-border"
            style="flex:1; width:100%; overflow:auto;"
          >
            <editable-device-table
              :selected-equipment-id="selectedDeviceId"
              :devices="bhaDevices"
              :fields="cTableFields"
              type="BHA"
              @select="
                selectedDeviceId = $event.id;
                eventManager.emit('click', $event);
              "
            />
          </div>
          <div style="z-index: 1;text-align: right;">
            <div
              class="my-tab"
              :class="{ 'active-tab shadow-border': !listAll }"
              @click="listAll = false"
            >
              Details
            </div>
            <div
              class="my-tab"
              :class="{ 'active-tab shadow-border': listAll }"
              @click="listAll = true"
            >
              List
            </div>
          </div>
          <div
            style="position:absolute; top: 50%;left:0;transform: translate(5px, -50%);
            cursor: pointer;"
            @click="expanded = !expanded"
          >
            <md-icon>{{ expanded ? "chevron_right" : "chevron_left" }}</md-icon>
          </div>
        </div>
      </div>
    </div>

    <new-bha-equipment-dialog
      :is-show="addEquipModalShow"
      :handle-close="closeNewBhaDialog"
    />

    <device-table-dialog
      :isShow="exportModalShow"
      :handle-close="closeExportModal"
      :deviceData="bhaDevices"
      :type="'bha'"
      :well="well"
      :bhaInfo="cBhaInfo"
      :imageRef="$refs.bhaDiagram"
    />

    <bha-option-dialog :open="showOptionDialog" :on-close="closeOptionDialog" />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import BhaDevice from "../components/BhaDevice.vue";
import BhaDeviceInfo from "../components/BhaDeviceInfo.vue";
import NewBhaEquipmentDialog from "../components/NewBhaEquipmentDialog.vue";
import config from "../utils/config";
import eventManager from "../eventManager";
import DeviceTable from "../components/DeviceTable.vue";
import WellInfo from "../components/WellInfo.vue";
import BhaOptionDialog from "../components/BhaOptionDialog.vue";
import EditableDeviceTable from "../components/EditableDeviceTable.vue";
import getLabel from "../mixins/getLabel";
import { print } from "../utils/print.js";
import {bhaDefaultPriorityShowOptions} from '../utils/defaultPriority'

export default {
  components: {
    BhaDevice,
    WellInfo,
    "bha-device-info": BhaDeviceInfo,
    "new-bha-equipment-dialog": NewBhaEquipmentDialog,
    "device-table-dialog": DeviceTable,
    "bha-option-dialog": BhaOptionDialog,
    BhaOptionDialog,
    EditableDeviceTable,
  },
  name: "BhaPage",
  data() {
    return {
      selectedDeviceId: null,
      addEquipModalShow: false,
      exportModalShow: false,
      editBhaInfoShow: false,
      showOptionDialog: false,
      eventManager: eventManager,
      expanded: false,
      listAll: true,
      copyDevice: {
        id_parent: null,
        id_equipment_type: 3,
        name: "",
        group: {},
        bhaDevice: {},
        id_bha_device: null,
        properties: {
          description: "",
          manufacture: "",
          serialNumber: "",
          OD: null,
          ID: null,
          maxOd: null,
          topSize: null,
          bottomSize: null,
          topType: "",
          bottomType: "",
          topGender: "",
          bottomGender: "",
          length: null,
          weight: null,
          scaleFactor: null,
        },
      },
    };
  },
  created() {
    if (!this.well) {
      this.$router.push("/well");
    } else {
      document.addEventListener("copy", this.handleCopy);
      document.addEventListener("paste", this.handlePaste);
      document.addEventListener("contextmenu", this.handleContextMenu);
    }
  },
  destroyed() {
    document.removeEventListener("copy", this.handleCopy);
    document.removeEventListener("paste", this.handlePaste);
    document.removeEventListener("contextmenu", this.handleContextMenu);
  },
  mounted() {
    eventManager.on("click", ({ id }) => {
      if (this.selectedDeviceId != id && id !== "reset") {
        this.selectedDeviceId = id;
      }
    });
  },
  computed: {
    ...mapState({
      well: (state) => state.well.well,
      bhaDevices: (state) => state.well.bhaDevices,
      bhaShowOptions: (state) => JSON.parse((state.well.well || {}).bhaShowOptions || '{}') || bhaDefaultPriorityShowOptions,
    }),
    ...mapGetters({
      zoom: "cZoomBHA",
      cBhaInfo: "cBhaInfo",
      scaleFactorProp: "scaleFactorProp",
      getDeviceById: "getDeviceById",
    }),
    cTableFields() {
      let res = [];
      for (const key in this.bhaShowOptions) {
        if (this.bhaShowOptions[key] > 0) {
          res.push({
          key: key,
          label: this.getLabel(key),
          type: "md-field",
          order: this.bhaShowOptions[key]
        });
        }
      }
      res.sort((a, b) => {
        if (a.order < b.order) {
          return 1
        } else if (a.order === b.order) {
          return 0
        }
        return -1
      })
      return res;
    },
  },
  methods: {
    selectEvent(e) {
      if (e.id === this.selectedDeviceId) this.selectedDeviceId = null;
      else this.selectedDeviceId = e.id;
    },
    getImageSrc(imgSrc) {
      return config.serviceUrl + "/bhaDevice" + imgSrc;
    },
    zoomOut() {
      this.$store.dispatch("zoomWbs", { flag: "zoomOut", app: "BHA" });
    },
    zoomIn() {
      this.$store.dispatch("zoomWbs", { flag: "zoomIn", app: "BHA" });
    },
    resetZoom() {
      this.$store.dispatch("zoomWbs", { flag: "reset", app: "BHA" });
    },
    resetSelection(e) {
      e.preventDefault();
      e.stopPropagation();
      eventManager.emit("click", { id: "reset" });
    },
    closeBhaDeviceInfo() {
      this.selectedDeviceId = null;
    },
    openAddEquipModal() {
      this.$store.dispatch("getBhaGroups");
      this.addEquipModalShow = true;
    },
    closeNewBhaDialog() {
      this.addEquipModalShow = false;
      eventManager.emit("click", { id: "reset" });
    },
    closeExportModal() {
      this.exportModalShow = false;
    },
    handleExport() {
      /*eventManager.emit("click", { id: "reset" });
      this.exportModalShow = true;*/
      let elem = this.$refs.diagram;
      let width = elem.scrollWidth;
      let height = elem.scrollHeight;
      print(elem, width, height, "bha.png");
    },
    closeOptionDialog() {
      this.showOptionDialog = false;
    },
    handleDetail(event) {
      if (event.id !== this.selectedDeviceId) {
        this.selectEvent(event);
      }
      this.listAll = false;
    },
    handleCopy(event) {
      if (event.id && event.id !== this.selectedDeviceId) {
        this.selectEvent(event);
      }
      let device =
        this.selectedDeviceId &&
        this.getDeviceById("BHA", this.selectedDeviceId);

      if (device) {
        Object.assign(this.copyDevice, device);
        this.copyDevice.name =
          device.name + " " + (Math.random() + 1).toString(36).substring(2, 5);
        delete this.copyDevice.Properties;

        this.$store.commit("setMessage", {
          message: device.name + " copied",
          type: "success",
        });
        setTimeout(() => {
          this.$store.commit("setMessage", {
            message: null,
            type: "success",
          });
        }, 4000);
      }
    },
    handlePaste() {
      this.copyDevice.name &&
        this.$store.dispatch("addBhaEquip", { newEquip: this.copyDevice });
    },
    handleContextMenu(e) {
      e.preventDefault();
    },
  },
  mixins: [getLabel],
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 25%;
}

.bha-container {
  position: relative;
  display: flex;
}

.bha-diagram {
  text-align: center;
  color: #2c3e50;
  position: relative;
  flex: 1;
  z-index: 1;
  border-radius: 10px;
}

.bha-info-container {
  // height: calc(100vh - 48px);
  position: relative;
  box-shadow: 0 0 1px 2px #e7e7e7e7;
  background-color: #fff;
  transition: width 0.3s;
}

.bha .md-icon {
  color: #ccc;
}

.toolbar {
  width: 60px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 220;
  background: none;
  & > button {
    margin: 10px 0;
  }
}

.bha-device-info-container {
  height: calc(100vh - 48px);
  position: relative;
}

.my-tab {
  display: inline-block;
  padding: 4px 12px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}
.my-tab.active-tab {
  background-color: white;
  color: #1962a7;
  text-decoration: underline;
  font-weight: 700;
  border-right: 1px solid #ccc;
}
.device-table-container {
  background-color: #fff;
}
.bha .md-icon {
  color: #ccc;
}
.bha .md-icon:hover {
  color: #1962a7;
}
</style>
