var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Annotation"},[_c('div',{staticClass:"notes"},_vm._l((_vm.map),function(d,idx){return _c('div',{key:idx},[_c('div',{staticClass:"decorator",class:{'outlined': d.id === _vm.selectedDeviceId},style:({
          top: `${d.indicatorPos + d.column * 0 * 5 + 5}px`, 
          left: d.column * 50 + 'px'
        }),on:{"click":function($event){return _vm.doSelect({id:d.id})}}},[_vm._v(" "+_vm._s(idx + 1)+" ")])])}),0),_vm._l((_vm.map),function(d,idx){return _c('div',{key:'s-'+idx,staticClass:"string",class:{'outlined': d.id === _vm.selectedDeviceId},style:({
      top: `${d.indicatorPos + d.column * 0 * 5 + 5}px`, 
      width: `calc(50% - ${250 - d.column * 50 + _vm.cWbsDiagramWidth/2 - _vm.getDeviceOffsetLeft(d.id)}px)`,
      transform: `translateX(${_vm.cWbsDiagramWidth/2 - _vm.getDeviceOffsetLeft(d.id)}px)`
    }),on:{"click":function($event){return _vm.doSelect({id: d.id})}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }