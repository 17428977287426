<template>
  <div :style="{
    top: cOffsetTop + 'px',
    left: cOffsetLeft + 'px',
    height: cHeight + 'px',
    width: 'calc(100% - ' + 2*cOffsetLeft+ 'px)',
    backgroundColor: cBackgroundColor
  }" @click="$emit('select', {id: options.id});" :class="{Valve: true, 'outlined': selectedDeviceId === options.id || isSelected}" >
    <div></div>
  </div>
</template>

<script>
import gapWidth from '../mixins/gapWidth';
import offsetLeft from '../mixins/offsetLeft';
import selection from '../mixins/selection';
import top from '../mixins/top'
import { mapGetters } from 'vuex'; 

export default {
  name: "Valve",
  props: ['options', 'selectedDeviceId'],
  mixins: [gapWidth, offsetLeft, selection, top],
  computed: {
    ...mapGetters({
      convertLength: 'convertLength',
      isAboveCrossOver: 'isAboveCrossOver',
      mdMode: 'showInMd'
    }),
    cHeight() {
      // return Math.round((this.cGapWidth - 5) * 3/2);
      return this.cGapWidth;
    },
    cColor() {
      return '#4058b8';
    },
    cAboveCrossOver() {
      let top
      if (this.mdMode) {
        top = this.options.Properties.find(prop => prop.name === 'topMd').value
      } else {
        top = this.options.Properties.find(prop => prop.name === 'top').value
      }
      return this.isAboveCrossOver(this.options.id_parent, top)
    },
    cBackgroundColor() {
      let activityType = this.options.Properties.find(prop => prop.name === 'activityType').value
      switch(activityType) {
        case "Safty Valve": 
          return "#0b800b"
        case "Wireline Retrievable Insert Valve":
          return "#e8c64a"
        case "Lubricator Valve":
          return "#2d73b5"
        default:
          return "#0b800b"
      }
    }
  }
}
</script>

<style scoped lang='scss'>
  .Valve {
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;
    // background-color: #0b800b;
    display: flex;
    justify-content: center;
    // border: 2px solid #2a3357;
    > div {
      width: 10%;
      background-color: #FFF;
    }
  }
  .selected {
    // box-shadow: 0 0px 6px 0 #7caaf8, 
    // 0 0px 6px 0 #7caaf8, 
    // 0 2px 6px 2px #7caaf8, 
    // 0 2px 6px 0 #7caaf8;
    filter: drop-shadow(0 0 8px #0958e0);
  }
</style>
