<template>
  <div class="Annotation">
    <div class="notes">
      <div v-for="(d, idx) in map" :key="idx">
        <div class="decorator" :class="{'outlined': d.id === selectedDeviceId}" 
          @click="doSelect({id:d.id})"
          :style="{
            top: `${d.indicatorPos + d.column * 0 * 5 + 5}px`, 
            left: d.column * 50 + 'px'
          }">
            {{idx + 1}}
        </div>
      </div>
    </div>
    <div v-for="(d, idx) in map" :key="'s-'+idx" class="string" :class="{'outlined': d.id === selectedDeviceId}"
      @click="doSelect({id: d.id})"
      :style="{
        top: `${d.indicatorPos + d.column * 0 * 5 + 5}px`, 
        width: `calc(50% - ${250 - d.column * 50 + cWbsDiagramWidth/2 - getDeviceOffsetLeft(d.id)}px)`,
        transform: `translateX(${cWbsDiagramWidth/2 - getDeviceOffsetLeft(d.id)}px)`
      }"></div>
  </div>
</template>
<script>
import { mapGetters} from 'vuex'
export default {
  name: "Annotation",
  props: ['devices', 'zoom', 'selectedDeviceId'],
  data() {
    return {map: []};
  },
  mounted() {
    this.updateMap();
  },
  computed: {
    ...mapGetters({
      convertLength: "convertLength",
      getShoeTop: "getShoeTop",
      getHangerTop: "getHangerTop",
      cZoom: 'cZoom',
      cWbsDiagramWidth: "cWbsDiagramWidth",
      getDeviceOffsetLeft: 'getDeviceOffsetLeft',
      mdMode: 'showInMd'
    }),
  },
  methods: {
    doSelect(d) {
     console.log(d);
     this.$emit('select', d);
    },
    isOverlap(d1, d2) {
      let overlapped = Math.abs(d1.indicatorPos - d2.indicatorPos) < 50;
      return overlapped;
    },
    findColumn(d, map) {
      let col = 0;
      for (let m of map) {
        if (this.isOverlap(d, m) && m.column >= col) {
          col = m.column + 1;
        }
      }
      return col;
    },
    updateMap() {
      let map = [];
      this.devices.forEach((d) => {
        let mappedDevice = {
          name: d.name,
          id: d.id
        };
        if (this.mdMode) {
          for (let p of d.Properties) {
            switch(p.name) {
              case "activityType":
                mappedDevice.activityType = p.value;
                break;
              case "topMd":
                mappedDevice.top = this.convertLength(Number(p.value));
                break;
              case "bottomMd":
                mappedDevice.bottom = this.convertLength(Number(p.value));
                break;
            }
          }
        } else {
          for (let p of d.Properties) {
            switch(p.name) {
              case "activityType":
                mappedDevice.activityType = p.value;
                break;
              case "top":
                mappedDevice.top = this.convertLength(Number(p.value));
                break;
              case "bottom":
                mappedDevice.bottom = this.convertLength(Number(p.value));
                break;
            }
          }
        }
        mappedDevice.top = mappedDevice.top || 0;
        mappedDevice.bottom = mappedDevice.bottom || mappedDevice.top;
        mappedDevice.indicatorPos = (mappedDevice.top + mappedDevice.bottom)/2;
        let col = this.findColumn(mappedDevice, map);
        mappedDevice.column = col;
        map.push(mappedDevice);
      });
      console.log(map);
      this.map = map;
    }
  },
  watch: {
    devices() {
      this.updateMap();
    },
    zoom() {
      this.updateMap();
    },
    mdMode() {
      this.updateMap();
    }
  }
}
</script>
<style lang="scss" scoped>
  .Annotation {
    z-index: 200;
    margin-top: 15px;
    position: absolute;
    width: 100%;
    height: 1px;
    & > .notes {
      padding: 20px;
      width: 250px;
      position: absolute;
      left: 100%;
      z-index: 1;
      transform: translateX(-100%);
    }
    .decorator {
      border: 1px solid blue;
      background-color: #f0f0f0;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      position: absolute;
      transform: translateY(-50%);
      line-height: 1.7;
      cursor: pointer;
    }
    .decorator.outlined {
      border-color: red;
      border-width: 2px;
    }
    .string {
      position: absolute;
      left: 50%;
      height: 1px;
      background-color: blue;
      cursor: pointer;
    }
    .string.outlined {
      background-color: red;
      border: none;
    }
  }
</style>
